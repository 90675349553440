var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fire_wealth_rule"},[_c('img',{staticClass:"fire_wealth_rule_bg",attrs:{"src":require("@/assets/firebox2.png"),"alt":""}}),_c('div',{staticStyle:{"z-index":"1"}},[_vm._m(0),_c('div',{staticClass:"fire_weal_r_date"},[_vm._v("本活动"+_vm._s(_vm.date)+"开启,敬请期待")]),_c('div',{staticClass:"fire_w_r_content"},[_c('img',{attrs:{"src":require("../../assets/fireboxsmall1.png"),"alt":""}}),_c('p',[_vm._v(_vm._s(_vm.startDate)+"开始以周为单位, 激活10万台趣宝盒之前有效")])]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('img',{staticClass:"fire_wealth_rule_logo",attrs:{"src":require("@/assets/fireboxLogo.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fire_w_r_content",staticStyle:{"margin-bottom":"5px","align-items":"flex-start"}},[_c('img',{attrs:{"src":require("../../assets/fireboxsmall2.png"),"alt":""}}),_c('p',[_vm._v(" 每轮活动，第一周为推广期，第二三周为带动期，第四周为结算期。每周（推广期）激活趣宝盒的下级用户，根据接下来两周（带动期）所产出的cple总量，在第四周（结算期）给予上级级奖励 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('table',[_c('tr',{staticClass:"fire_w_r_content_table_tr1"},[_c('td',{staticStyle:{"height":"50px"}},[_vm._v("下级两周"),_c('br'),_vm._v("累计产出")]),_c('td',[_vm._v("1500+")]),_c('td',[_vm._v("1000+")]),_c('td',[_vm._v("500+")]),_c('td',[_vm._v("300+")]),_c('td',[_vm._v("100+")]),_c('td',[_vm._v("50+")]),_c('td',{staticStyle:{"border-right":"none"}},[_vm._v("10+")])]),_c('tr',[_c('td',{staticStyle:{"height":"45px"}},[_vm._v("上级奖励")]),_c('td',[_vm._v("1500")]),_c('td',[_vm._v("900")]),_c('td',[_vm._v("400")]),_c('td',[_vm._v("200")]),_c('td',[_vm._v("65")]),_c('td',[_vm._v("30")]),_c('td',{staticStyle:{"border-right":"none"}},[_vm._v("5")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fire_w_r_content",staticStyle:{"margin-bottom":"0"}},[_c('img',{attrs:{"src":require("../../assets/fireboxsmall3.png"),"alt":""}}),_c('p',[_vm._v("每一期的榜单前10名将获得额外奖励")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("第1名：额外奖励888.88转出额度")]),_c('li',[_vm._v("第2名：额外奖励188.88转出额度")]),_c('li',[_vm._v("第3名：额外88.88转出额度")]),_c('li',[_vm._v("4-10名：额外奖励8.88转出额度")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fire_w_r_content",staticStyle:{"margin-bottom":"0"}},[_c('img',{attrs:{"src":require("../../assets/fireboxsmall4.png"),"alt":""}}),_c('p',[_vm._v(" 用户可在第四周领取第一周邀请的用户的奖励， 在第五周领取第二周邀请的用户的奖励，依次往复… ")])])
}]

export { render, staticRenderFns }