import Vue from "vue";
import VueRouter from "vue-router";
import Index from "../views/Index.vue";
import Share from "../views/share1.vue";
import Register from "../views/Register.vue";
import Ditu from "../views/ditu.vue";
import CodeRegister from "../views/codeOpenApp.vue";
import JoinGroup from "../views/banner/JoinGroup.vue";
import PledgePopUp from "../views/banner/PledgePopUp.vue";
import PledgeWorld from "../views/banner/PledgeWorld.vue";
import SignOut from "../views/banner/SignOut.vue";
import Privacy from "../views/banner/Privacy.vue";
import AgentMobile from "../views/mobileView/AgentMobile.vue";
import AgentMobileDetail from "../views/mobileView/AgentMobileDetail.vue";
import FireWealthBox from "../views/banner/FireWealthBox.vue";
import FireWealthBoxRule from "../views/banner/FireWealthBoxRule.vue";

// 预售趣宝盒
import boxWallet from "../views/chat/boxWallet/index.vue";
import boxWalletWhatBox from "../views/chat/boxWallet/whatBox.vue";
import boxWalletOrder from "../views/chat/boxWallet/order/payment.vue";
import paySuccess from "../views/chat/boxWallet/order/paySuccess.vue";
import meOrder from "../views/chat/boxWallet/order/meOrder.vue";
import meOrderDetail from "../views/chat/boxWallet/order/detail.vue";
import vaitPayOrder from "../views/chat/boxWallet/order/vaitPay.vue";

// 路演
import roadShow from "../views/chat/roadShow/index.vue";
import roadShowDetail from "../views/chat/roadShow/detail.vue";
import roadShowReadyDetail from "../views/chat/roadShow/readyDetail.vue";
import roadShowNoStartDetail from "../views/chat/roadShow/noneStartDetail.vue";
import roadShowHistoryDetail from "../views/chat/roadShow/historyDetail.vue";

// 交易
import trading from "../views/chat/trading/index.vue";
import tradingOrder from "../views/chat/trading/order.vue";
import tradingOrderDetail from "../views/chat/trading/orderDetail.vue";

// 我的
import meRoadShow from "../views/chat/me/index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Index",
    component: Index,
  },
  {
    path: "/shareIndex",
    name: "Share",
    component: Share,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/ditu",
    component: Ditu,
  },
  {
    path: "/codeOpenApp",
    component: CodeRegister,
  },

  {
    path: "/JoinGroup",
    component: JoinGroup,
  },
  {
    path: "/PledgePopUp",
    component: PledgePopUp,
  },
  {
    path: "/SignOut",
    component: SignOut,
  },
  {
    path: "/Privacy",
    component: Privacy,
  },
  {
    path: "/PledgeWorld",
    component: PledgeWorld,
  },

  {
    path: "/AgentMobile", // 代理/运营-代理-移动端
    component: AgentMobile,
  },
  {
    path: "/AgentMobileDetail", // 代理/运营-代理-移动端-奖励明细
    component: AgentMobileDetail,
  },
  {
    path: "/FireWealthBox", // 火财盒
    component: FireWealthBox,
  },
  {
    path: "/FireWealthBoxRule", // 火财盒规则
    component: FireWealthBoxRule,
  },

  // ---------------------------------------------------------------
  // 路演+趣宝盒预售
  // 趣宝盒预售
  {
    path: "/boxWallet",
    name: "boxWallet",
    component: boxWallet,
  },
  // 什么是趣宝盒
  {
    path: "/whatBox",
    component: boxWalletWhatBox,
  },
  // 确认订单
  {
    path: "/boxWalletOrder",
    component: boxWalletOrder,
  },
  // 支付成功
  {
    path: "/paySuccess",
    component: paySuccess,
  },
  // 我的订单
  {
    path: "/meOrder",
    component: meOrder,
  },
  // 我的订单
  {
    path: "/meOrder/detail",
    component: meOrderDetail,
  },
  // 待支付
  {
    path: "/vaitPayOrder",
    component: vaitPayOrder,
  },

  // ---------------------------------------------------------------
  // 路演
  // {
  //   path: "/roadShow",
  //   name: "roadShow",
  //   component: roadShow,
  // },
  // // 路演首页详情页
  // {
  //   path: "/roadShowDetail",
  //   name: "roadShowDetail",
  //   component: roadShowDetail,
  // },
  // // 路演流程
  // {
  //   path: "/roadShowReadyDetail",
  //   name: "roadShowReadyDetail",
  //   component: roadShowReadyDetail,
  // },
  // // 未开始 二级页面
  // {
  //   path: "/roadShowNoStartDetail",
  //   name: "roadShowNoStartDetail",
  //   component: roadShowNoStartDetail,
  // },
  // // 历史路演 二级页面
  // {
  //   path: "/roadShowHistoryDetail",
  //   name: "roadShowHistoryDetail",
  //   component: roadShowHistoryDetail,
  // },

  // // 交易
  // {
  //   path: "/trading",
  //   name: "trading",
  //   component: trading,
  // },
  // {
  //   path: "/tradingOrder",
  //   name: "tradingOrder",
  //   component: tradingOrder,
  // },
  // {
  //   path: "/tradingOrderDetail",
  //   name: "tradingOrderDetail",
  //   component: tradingOrderDetail,
  // },

  // // 我的
  // {
  //   path: "/meRoadShow",
  //   name: "meRoadShow",
  //   component: meRoadShow,
  // },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
