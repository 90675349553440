<template>
  <div class="what_box_index">
    <!-- <Header></Header> -->
    <div class="what_box_index_header">
      <van-icon name="arrow-left" @click="handleBack" />
      <p>什么是趣宝盒？</p>
      <div></div>
    </div>
    <div class="what_box_header">
      <img :src="imageUrl" alt="" />
    </div>
    <div class="txt_title">
      <h2>趣宝盒 —— 智能出行的革命，与趣出行共同启航！</h2>
      <p>
        开启您的顺风车旅程，让每一次出行都成为一次特别的体验。趣宝盒，一个专为搭配互联网顺风出行平台趣出行而设计的智能设备，带您进入智能、时尚、高效的新时代出行方式。
      </p>
    </div>

    <div class="txt_title_rule">
      <h2>独特功能与服务</h2>
      <p>
        与趣出行完美搭配：
        专为顺风车服务设计，与趣出行平台无缝对接，让您的每一次出行更加便捷。
      </p>
      <p>
        时尚设计：
        趣宝盒拥有高端时尚的外观设计，不仅是智能设备，更是您车内的一件艺术品。
      </p>
      <p>全程语音陪护： 独特的语音交互系统，让您的驾驶过程更加轻松，安全。</p>
      <p>
        实时车速与奖励追踪：
        实时显示车速和累积的CPLE奖励，让您的驾驶既安全又充满激励。
      </p>
      <p>行驶方向指示： 清晰的导航指示，确保您每次出行都方向明确。</p>
    </div>

    <div class="txt_title_reward">
      <h2>趣宝盒的奖励机制</h2>
      <p>基础质押量： 只需600 CPLE，轻松启动奖励计划。</p>
      <p>个人奖励基数： 推广激活更多趣宝盒，个人奖励基数提升。</p>
      <p>智能算法： 结合质押量和周围订单数据，精准计算您的奖励。</p>
    </div>

    <div class="reward">
      <h2>选择趣宝盒的理由</h2>
      <p>环保驾驶： 通过顺风车服务减少碳排放，保护环境。</p>
      <p>时尚与智能并存： 高端设计与智能功能的完美结合。</p>
      <p>
        全方位驾驶体验： 语音陪护、奖励追踪、导航指引，全面提升您的驾驶乐趣。
      </p>
      <p>透明安全： 奖励直接上链，公平公正。</p>
      <p>🛍️ 现在就加入趣宝盒的智能出行大家庭，让每一次行程都不同凡响！</p>
      <p>
        趣宝盒不仅是一个奖励工具，它是您智能出行的最佳伴侣。立即体验这一全新的出行方式！
      </p>
    </div>

    <div class="bottom_btn" @click="handlePay">
      <img src="@/assets/boxWallet/bottom_btn.png" alt="" />
    </div>

    <Bottom></Bottom>
  </div>
</template>

<script>
// import Header from "./header.vue";
import Bottom from "./bottom.vue";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";
import md5 from "js-md5";

export default {
  components: { Bottom },
  data() {
    return {
      imageUrl: "",
      dateNew: "",
    };
  },

  mounted() {
    var num = "";
    for (var i = 0; i < 32; i++) {
      num += Math.floor(Math.random() * 10);
    }

    let md5Code = `${this.getTimeFn()}${num}cde89da976c98e74f156e5cf40f9dbf3`;

    const opt = {
      url: reqUrl.getPresaleProgress,
      method: "POST",
      headers: {
        nonce: num,
        ts: this.dateNew,
        sign: md5(md5Code),
        wallet: localStorage.getItem("userName"),
      },
      params: {},
      resFunc: (res) => {
        if (res.data.code == 0) {
          this.imageUrl = res.data.data.imageUrl;
        }

        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
        }
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
      },
    };
    request(opt);
  },

  methods: {
    handleBack() {
      this.$router.push("/boxWallet");
    },

    handlePay() {
      this.$router.push("/boxWalletOrder");
    },

    getTimeFn() {
      this.dateNew = new Date().getTime();
      return this.dateNew;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";

.bottom_btn {
  bottom: 36px;
}
</style>
