<template>
  <div class="pledge">
    <img
      src="../../assets/banner/pledgePopUp/down.png"
      class="pledge_down"
      alt=""
    />
    <p class="pledge_title">{{ $t("pledgePopUp.txt1") }}</p>
    <p class="pledge_text">1. {{ $t("pledgePopUp.txt2") }}:TokenPocket</p>
    <div class="pledge_auto_bg">
      <div style="display: flex; align-items: center">
        <img src="../../assets/banner/pledgePopUp/tokenPocket.png" alt="" />
        <p>TokenPocket</p>
      </div>
      <div
        class="pledge_auto_bg_copy"
        v-clipboard:copy="message"
        v-clipboard:success="onCopy"
        v-clipboard:error="onError"
      >
        {{ $t("pledgePopUp.copyLink") }}
      </div>
    </div>

    <p class="pledge_text">2.{{ $t("pledgePopUp.txt3") }}</p>
    <p class="pledge_text">3.{{ $t("pledgePopUp.txt4") }}</p>

    <div class="pledge_auto_bg">
      <div style="display: flex; align-items: center">
        <p style="width: 245px; word-wrap: break-word; margin-left: 16px">
          0x559309f4D323Be912A45358d9e2aC21423EB847d
        </p>
      </div>
      <div
        class="pledge_auto_bg_copy"
        v-clipboard:copy="message2"
        v-clipboard:success="onCopy2"
        v-clipboard:error="onError"
      >
        {{ $t("pledgePopUp.copy") }}
      </div>
    </div>
    <p
      style="
        color: #f56c6c;
        font-weight: bold;
        font-size: 16px;
        margin-top: 10px;
        word-wrap: break-word;
      "
    >
      *切忌：不要再转入已被盗过期质押地址0xc94f3015DB7300CB9c9715C3a2B1E99Ad2000001，否则当无效质押处理，丢失责任自担。
    </p>
    <p class="pledge_precautions">
      {{ $t("pledgePopUp.notice") }}：<br />
      {{ $t("pledgePopUp.notice1") }}<br />
      {{ $t("pledgePopUp.notice2") }}<br />
      {{ $t("pledgePopUp.notice3") }}<br />
      4.{{ $t("pledgePopUp.notice4") }}<br />
    </p>

    <img
      src="../../assets/banner/pledgePopUp/phone.png"
      style="width: 28px; height: 28px"
      alt=""
    />
    <p class="pledge_call">{{ $t("pledgePopUp.call") }}</p>

    <div>
      <div class="pledge_auto_bg">
        <div style="display: flex; align-items: center">
          <p style="width: 286px; word-wrap: break-word; margin-left: 16px">
            {{ $t("pledgePopUp.group") }}：https://t.me/CNCarpoolLifeEconomy
          </p>
        </div>
        <div
          class="pledge_auto_bg_copy"
          v-clipboard:copy="message3"
          v-clipboard:success="onCopy2"
          v-clipboard:error="onError"
        >
          {{ $t("pledgePopUp.copy") }}
        </div>
      </div>
    </div>
    <div>
      <div class="pledge_auto_bg">
        <div style="display: flex; align-items: center">
          <p style="word-wrap: break-word; margin-left: 16px">
            {{ $t("pledgePopUp.phone") }}：400-168-5218
          </p>
        </div>
        <div
          class="pledge_auto_bg_copy"
          v-clipboard:copy="message4"
          v-clipboard:success="onCopy2"
          v-clipboard:error="onError"
        >
          {{ $t("pledgePopUp.copy") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Toast } from "vant";
import "vant/lib/index.css";

Vue.use(Toast);
export default {
  data() {
    return {
      message: "https://download.tokenpocket.pro/index.html?new#/",
      message2: "0x559309f4D323Be912A45358d9e2aC21423EB847d",
      message3: "https://t.me/CNCarpoolLifeEconomy",
      message4: "400-168-5218",
    };
  },

  mounted() {
    if (this.$route.query.language == "zh-CN") {
      this.$store.state.isLang = "zh-CN";
      localStorage.setItem("language", "zh-CN");
      this.$i18n.locale = localStorage.getItem("language");
    } else if (this.$route.query.language == "en") {
      this.$store.state.isLang = "en";
      localStorage.setItem("language", "en");
      this.$i18n.locale = localStorage.getItem("language");
    } else if (this.$route.query.language == "ja") {
      this.$store.state.isLang = "ja";
      localStorage.setItem("language", "ja");
      this.$i18n.locale = localStorage.getItem("language");
    } else if (this.$route.query.language == "ko") {
      this.$store.state.isLang = "ko";
      localStorage.setItem("language", "ko");
      this.$i18n.locale = localStorage.getItem("language");
    }
  },

  methods: {
    onCopy() {
      Toast({
        message: this.$t("pledgePopUp.copyA"),
        className: "pledge_toast1",
      });
    },
    onError() {
      Toast({
        message: this.$t("pledgePopUp.copyFail"),
        className: "pledge_toast",
      });
    },

    onCopy2() {
      Toast({
        message: this.$t("pledgePopUp.copySuccess"),
        className: "pledge_toast",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.pledge {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding: 10px 20px;
  font-family: "PingFang SC";
  font-style: normal;
  color: #202226;
  font-weight: 500;
  background: url(../../assets/banner/pledgePopUp/bg.png) no-repeat;
  background-size: 100%;

  .pledge_down {
    width: 24px;
    height: 3px;
    display: block;
    margin: 5px auto;
  }

  .pledge_title {
    font-size: 22px;
  }

  .pledge_text {
    font-size: 16px;
    margin-top: 25px;
    text-align: justify;
  }

  .pledge_auto_bg {
    width: 100%;
    height: 64px;
    background: url(../../assets/banner/pledgePopUp/auto_bg.png) no-repeat;
    background-size: 100% 64px;
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      width: 48px;
      height: 48px;
      margin: 0 12px 0 8px;
    }

    p {
      color: #4e5259;
      font-size: 16px;
    }

    .pledge_auto_bg_copy {
      margin-right: 9px;
      font-size: 14px;
      color: #6376ff;
    }
  }

  .pledge_precautions {
    font-weight: 400;
    font-size: 16px;
    color: #4e5259;
    margin-top: 20px;
    margin-bottom: 22px;
    text-align: justify;
  }

  .pledge_call {
    font-weight: 400;
    font-size: 16px;
    color: #4e5259;
  }
}
</style>
