<template>
  <div>
    <div class="agent_mobile_detail_header">
      <i class="el-icon-arrow-left" @click="handleBack"></i>
      <p>{{ $t("agent.rewardDetail") }}</p>
    </div>

    <div class="agent_mobile_detail_txt">
      {{ $t("agent.earnings") }}$ {{ gainsTotal }}
    </div>

    <el-table
      :data="tableDataDetail"
      style="width: 100%"
      :header-cell-style="{
        background: 'rgba(250, 250, 252, 1)',
        color: '#4b4b4b',
      }"
    >
      <el-table-column
        type="index"
        :label="$t('agent.number')"
        width="50"
        align="center"
      >
        <template slot-scope="scope">
          <span>{{ scope.$index + 1 }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="amount"
        :label="$t('agent.earnings2')"
        align="center"
      >
        <template slot-scope="scope">$ {{ scope.row.amount }}</template>
      </el-table-column>
      <el-table-column
        prop="afterAmount"
        :label="$t('agent.amount')"
        width="100"
      >
        <template slot-scope="scope">$ {{ scope.row.afterAmount }}</template>
      </el-table-column>
      <el-table-column
        prop="createTime"
        :label="$t('agent.rewardTime')"
        width="160"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="title"
        :label="$t('agent.reason')"
        width="80"
        align="center"
      >
      </el-table-column>
    </el-table>
    <div class="click_more" @click="handleMore" v-if="!loadMore">
      {{ $t("agent.more") }}
    </div>
    <div class="click_more" v-else-if="loadMore">
      {{ $t("agent.loading") }}
      <i class="el-icon-loading"></i>
    </div>
  </div>
</template>

<script>
import { reqAgentURL } from "@/api/apiUrl";
import { request } from "@/api/request";
import { format_time_date } from "@/util/funBusiness";
export default {
  data() {
    return {
      tableDataDetail: [],

      // 分页数据
      currentPage: 1, // 当前页
      pageLength: 0,
      pageSize: 10, //要传过去的数据 每页多少条数据
      pageSizes: [10, 20, 50, 100],

      loadMore: false,
      gainsTotal: "",
    };
  },

  mounted() {
    const optTable = {
      url: reqAgentURL.getBoxAgentBalanceDetail,
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8;",
      },
      params: JSON.stringify({
        appLang: "zh-CN",
        userId: this.$route.query.userId * 1,
        pageSize: 10,
      }),
      resFunc: (res) => {
        if (res.data.code == 0) {
          this.tableDataDetail = res.data.data.list;
          res.data.data.list.map(
            (item) => (item.createTime = format_time_date(item.createTime))
          );
        }

        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
        }
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
      },
    };
    request(optTable);

    const optMoney = {
      url: reqAgentURL.getBoxAgentBalanceSum,
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8;",
      },
      params: JSON.stringify({
        userId: this.$route.query.userId * 1,
      }),
      resFunc: (res) => {
        if (res.data.code == 0) {
          this.gainsTotal = res.data.data;
        }

        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
        }
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
      },
    };
    request(optMoney);
  },

  methods: {
    handleBack() {
      this.$router.push({
        path: "/AgentMobile",
        query: {
          userId: this.$route.query.userId,
          name: this.$route.query.name,
        },
      });
    },

    handleMore() {
      this.loadMore = true;
      const optTable = {
        url: reqAgentURL.getBoxAgentBalanceDetail,
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=utf-8;",
        },
        params: JSON.stringify({
          appLang: "zh-CN",
          userId: this.$route.query.userId * 1,
          pageSize: this.pageSize,
          pageNum: 1 + this.currentPage++,
        }),
        resFunc: (res) => {
          if (res.data.code == 0) {
            this.loadMore = false;
            this.tableDataDetail = this.tableDataDetail.concat(
              res.data.data.list
            );
            res.data.data.list.map(
              (item) => (item.createTime = format_time_date(item.createTime))
            );
          }

          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
          }
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          console.log(err, "err1");
        },
      };
      request(optTable);
    },
  },
};
</script>

<style lang="scss" scoped>
.agent_mobile_detail_header {
  width: 100%;
  height: 80px;
  line-height: 80px;
  display: flex;
  align-items: center;
  position: relative;

  i {
    font-size: 24px;
    position: absolute;
    top: calc(40px - 12px);
    left: 10px;
  }

  p {
    font-weight: 500;
    font-size: 18px;
    font-family: "PingFang SC";
    margin: 0 auto;
  }
}

.agent_mobile_detail_txt {
  font-weight: 500;
  font-size: 18px;
  font-family: "PingFang SC";
  margin-left: 10px;
  margin-bottom: 20px;
}

.lines {
  height: calc(50vh - 100px);
  overflow: auto;
}

.click_more {
  text-align: center;
  margin: 20px 0;
  font-size: 16px;
}
</style>
