<template>
  <div class="chat_me">
    <div class="chat_me_no_login" v-if="userData == {}">
      <div class="no_login-t">
        <img src="@/assets/boxWallet/roadShow/no_login.png" alt="" />
        <h2>登录</h2>
        <p style="color: #fff">请关联钱包</p>
      </div>

      <van-button type="primary" block @click="connectWallet"
        >关联钱包</van-button
      >
    </div>

    <div class="chat_me_login" v-else>
      <div class="chat_me_login_head">
        <img src="../../../assets/boxWallet/roadShow/head.png" alt="" />
        <div>
          <h2>{{ userData.userName }}</h2>
          <p>{{ userData.phone }}</p>
        </div>
      </div>
      <div class="chat_me_wallet">
        <p>区块链地址：123232jr2n2n3njnknji23ni4b2b342</p>
        <img
          src="@/assets/boxWallet/roadShow/copy.png"
          v-clipboard:copy="message"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
        />
      </div>

      <div class="chat_me_info">
        <div class="chat_me_info_head">
          <img src="../../../assets/boxWallet/qcx_logo.png" alt="" />
          <p v-if="isLogin">请在趣出行 App 中绑定当前钱包</p>
          <p v-else>趣出行账号信息</p>
        </div>
        <div class="chat_me_info_msg">
          <div>
            <p>余额</p>
            <p v-if="isLogin">--</p>
            <p v-else>{{ userData.balance }}</p>
          </div>
          <div>
            <p>CPLE 积分</p>
            <p v-if="isLogin">--</p>
            <p v-else>?</p>
          </div>
          <div>
            <p>转出额度</p>
            <p v-if="isLogin">--</p>
            <p v-else>{{ userData.quota }}</p>
          </div>
          <div>
            <p>RAM</p>
            <p v-if="isLogin">--</p>
            <p v-else>?</p>
          </div>
        </div>
      </div>

      <div class="chat_me_content" @click="handleGoClick(1)">
        <div class="content_box">
          <p>趣宝盒订单</p>
          <van-icon name="arrow" />
        </div>
        <div class="content_box" @click="handleGoClick(2)">
          <p>委托订单</p>
          <van-icon name="arrow" />
        </div>

        <div class="content_box" @click="showOut = true">
          <p class="relieve_text">解除连接</p>
          <img src="../../../assets/boxWallet/roadShow/no.png" alt="" />
        </div>

        <van-popup v-model="showOut" round>
          <div class="now_show">
            <h2>确定解除钱包连接吗？</h2>
            <div>
              <el-button type="info" round @click="showOut = false"
                >取消</el-button
              >
              <el-button type="success" round @click="handleOutConcat"
                >解除连接</el-button
              >
            </div>
          </div>
        </van-popup>
      </div>
    </div>

    <BottomTabbar></BottomTabbar>
  </div>
</template>

<script>
import axios from "axios";
import Web3 from "web3";

import { showBalance, signMessage } from "@/service/mall_mask";
import { reqUrl } from "@/api/apiUrl";

import BottomTabbar from "../Bottomtabbar/index.vue";

export default {
  inject: ["reload"],
  components: { BottomTabbar },

  data() {
    return {
      web3: null,
      connected: false,
      message: "",
      signature: "",
      userName: "",
      balance: "",
      userData: {},
      dateNew: "",

      message: "123",

      showOut: false,
      isLogin: false, // 是否登录
    };
  },

  mounted() {
    this.userData = JSON.parse(localStorage.getItem("userData"));

    var phoneNumber = this.userData.phone;
    var regex = /(\d{3})\d{4}(\d{4})/;
    var matches = phoneNumber.match(regex);
    if (matches) {
      // 获取到分组结果
      var prefix = matches[1]; // 前三位
      var suffix = matches[2]; // 后四位

      // 生成新的手机号（包含四位隐私号）
      var newPhoneNumber = prefix + "****" + suffix;
      this.userData.phone = newPhoneNumber;
    } else {
      console.error("无效的手机号格式");
    }
  },

  methods: {
    async connectWallet() {
      if (!window.ethereum) {
        this.$Message.error("请安装MetaMask");
        return;
      }

      try {
        await window.ethereum.request({ method: "eth_requestAccounts" });
        this.web3 = new Web3(window.ethereum);
        this.connected = true;
        console.log("已连接钱包");
        showBalance();
        this.balance = localStorage.getItem("balance");
        // signMessage(this.connected, this.web3);
        this.signMessage();
        this.refresh();
      } catch (error) {
        console.error(error);
      }
    },

    // 签名
    async signMessage() {
      if (!this.connected) {
        this.$Message.error("请先连接到钱包");
        return;
      }

      const address = (await this.web3.eth.getAccounts())[0];
      localStorage.setItem("userName", address);

      var num = "";
      for (var i = 0; i < 32; i++) {
        num += Math.floor(Math.random() * 10);
      }

      this.dateNew = new Date().getTime();
      let nonce = `${this.dateNew}${num}qcxPresale`;
      this.message = nonce;

      const signature = await this.web3.eth.personal.sign(
        this.message,
        address
      );
      this.signature = signature;

      localStorage.setItem("sign", signature);
      localStorage.setItem("concatShow", true);

      if (this.signature != "") {
        this.userInfoAxios({
          ts: this.dateNew,
          sign: this.signature,
          num: num,
        });
      }
      this.refresh();
    },

    userInfoAxios(obj) {
      axios
        .post(
          reqUrl.userBasicGet,
          {},
          {
            headers: {
              nonce: obj.num,
              ts: obj.ts,
              sign: obj.sign,
              wallet: localStorage.getItem("userName"),
            },
          }
        )
        .then((res) => {
          if (res.data.success) {
            localStorage.setItem("userData", JSON.stringify(res.data.data));
            localStorage.setItem("token", JSON.stringify(res.data.data.token));
            this.refresh();
          } else {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
          }
        });
    },

    getTimeFn() {
      this.dateNew = new Date().getTime();
      return this.dateNew;
    },

    onCopy() {
      this.$Message.success("复制成功");
    },
    onError() {
      this.$Message.error("复制失败");
    },

    handleOutConcat() {
      this.showOut = false;
      localStorage.removeItem("userName");
      localStorage.removeItem("sign");
      localStorage.removeItem("balance");
      localStorage.removeItem("concatShow");
      this.refresh();
    },

    handleGoClick(i) {
      if (i == 1) {
        this.$router.push("/meOrder");
      } else if (i == 2) {
        this.$router.push("/tradingOrder");
      }
    },

    // 刷新
    refresh() {
      this.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../all.scss";
@import "./index.scss";
</style>
