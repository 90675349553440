<template>
  <div
    class="card"
    :class="{ 'card--fixed': isFixed, 'card--collapsed': isCollapsed }"
    :style="{ top: fixedTop + 'px' }"
  >
    <div v-if="!isCollapsed" class="card-content">
      <div class="road_show_entrust">
        <div class="road_show_ent_head">
          <img src="@/assets/boxWallet/roadShow/img1.png" alt="" />
          <p>PORTAL</p>
        </div>
        <div class="road_show_ent_txt">
          <p>总奖励</p>
          <span>200000</span>
        </div>
        <div class="road_show_ent_txt">
          <p>挖币周期</p>
          <span>7天</span>
        </div>
        <div class="road_show_ent_txt">
          <p>距离挖币结束还剩</p>
          <span>12天:14时:25分</span>
        </div>
        <div class="road_show_ent_reward">
          <div class="reward_routine">
            <div style="display: flex">
              <img src="@/assets/boxWallet/roadShow/green.png" alt="" />
              <div class="reward_routine_r">
                <p class="reward_routine_r_txt1">常规质押奖池 900000</p>
                <p class="reward_routine_r_txt2">存入总量：700900</p>
              </div>
            </div>
            <div v-if="isReward"></div>
            <van-button v-else type="primary">质押</van-button>
          </div>
          <div class="reward_routine-vait" v-if="isReward">
            <p>待领取奖励：88.935</p>
            <van-button type="primary">领取</van-button>
          </div>
        </div>

        <div class="road_show_ent_reward">
          <div class="reward_routine">
            <div style="display: flex">
              <img src="@/assets/boxWallet/roadShow/yellow.png" alt="" />
              <div class="reward_routine_r">
                <p class="reward_routine_r_txt1">升级行为奖池 11100000</p>
                <p class="reward_routine_r_txt3">有奖参与，需质押1000CPLE 7D</p>
              </div>
            </div>
            <div v-if="isRoutineReward"></div>
            <van-button type="primary" v-else>质押</van-button>
          </div>
          <div v-if="isRoutineReward">
            <div class="reward_one">
              <div class="reward_one_l">
                <img src="@/assets/boxWallet/roadShow/one.png" alt="" />
                <div class="reward_one_show">
                  <p style="font-size: 14px">路演互动</p>
                  <p>
                    <span class="reward_one_show_txt1">+10.00</span>
                    <span class="reward_one_show_txt2">剩1000位</span>
                  </p>
                </div>
              </div>
              <van-button type="primary">去完成</van-button>
            </div>
            <div class="reward_one">
              <div class="reward_one_l">
                <img src="@/assets/boxWallet/roadShow/two.png" alt="" />
                <div class="reward_one_show">
                  <p style="font-size: 14px">加入项目群</p>
                  <p>
                    <span class="reward_one_show_txt1">+10.00</span>
                    <span class="reward_one_show_txt2">剩1000位</span>
                  </p>
                </div>
              </div>
              <van-button type="primary">加入</van-button>
            </div>
          </div>
          <div v-else-if="!isRoutineReward">
            <div class="reward_one">
              <div class="reward_one_l">
                <img src="@/assets/boxWallet/roadShow/one.png" alt="" />
                <div class="reward_one_show">
                  <p style="font-size: 14px">路演互动</p>
                </div>
              </div>
              <p>
                <span class="reward_one_show_txt1">+10.00</span>
                <span class="reward_one_show_txt2">剩1000位</span>
              </p>
            </div>
            <div class="reward_one" style="border: none">
              <div class="reward_one_l">
                <img src="@/assets/boxWallet/roadShow/two.png" alt="" />
                <div class="reward_one_show">
                  <p style="font-size: 14px">加入项目群</p>
                </div>
              </div>
              <p>
                <span class="reward_one_show_txt1">+10.00</span>
                <span class="reward_one_show_txt2">剩1000位</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="card-collapsed">
      <!-- 卡片收缩内容 -->
      <div>123</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isFixed: Boolean,
    isCollapsed: Boolean,
    fixedTop: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      isReward: false, // 常规质押奖池按钮
      isRoutineReward: true, // 升级行为奖池按钮
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../all.scss";
@import "./index.scss";
.card {
  transition: all 0.3s ease;
  position: relative; /* 初始位置 */
  width: 100%; /* 根据需要调整 */
  /* 其他样式 */
}

.card--fixed {
  position: fixed;
  top: 0;
  z-index: 999; /* 确保卡片固定在顶部时不会被其他元素遮挡 */
  width: 100%; /* 完整宽度或根据需要调整 */
  /* 其他固定样式 */
}

.card--collapsed {
  /* 收缩后的样式 */
  div {
    width: 300px;
    height: 100px;
    border: 1px red solid;
  }
}

.card-content {
  /* 卡片完整内容的样式 */
}

.card-collapsed {
  /* 卡片收缩内容的样式 */
}
</style>
