<template>
  <div class="road_show_detail_pledge">
    <div class="trading_head">
      <van-icon name="arrow-left" @click="handleBack" />
      <img
        src="@/assets/boxWallet/roadShow/logo2.png"
        @click="handleJoinGroupChat"
        alt=""
      />
    </div>

    <div class="detail_pledge_head">
      <div class="detail_pledge_head_box">
        <img src="@/assets/boxWallet/roadShow/12.png" alt="" />
        <p>已结束</p>
      </div>
      <p>{{ pageData.closeDate }}结束</p>
    </div>

    <div class="road_show_detail_h">
      <div class="road_show_ent_head">
        <img :src="pageData.prjAvatarUrl" alt="" />
        <p>{{ pageData.prjName }}</p>
      </div>
      <div class="road_show_ent_txt">
        <p>总奖励</p>
        <span>{{ pageData.totalReward }}</span>
      </div>
      <div class="road_show_ent_txt">
        <p>挖币周期</p>
        <span>{{ pageData.poolBlock }}</span>
      </div>
    </div>
    <div class="road_show_detail_h" v-if="isPledgeNoRoutine">
      <div class="road_show_ent_txt" style="margin-top: 0">
        <p>我的质押</p>
        <span>{{ userInfoAmount }}</span>
      </div>
      <div class="road_show_ent_txt">
        <p>质押时长</p>
        <span>{{ userInfoRewardBlock }}</span>
      </div>
      <div class="road_show_ent_txt">
        <p>已领取奖励</p>
        <span>{{ rewardOk }}</span>
      </div>
      <van-button
        type="primary"
        style="margin-top: 10px"
        @click="showRelease = true"
        >申请解押</van-button
      >
    </div>

    <div class="road_show_detail_tab">
      <van-tabs
        animated
        color="unset"
        class="tabsCapsule"
        background="#ffffff1a"
        v-model="active"
      >
        <van-tab title="路演">
          <div>
            <div v-for="(item, i) in qaList" :key="i">
              <div>
                <div class="show_again_dis_prj">
                  <img src="@/assets/boxWallet/roadShow/head.png" alt="" />
                  <div>
                    <h4>主持人：</h4>
                    <div class="dis_prj_box">
                      {{ item.item }}
                    </div>
                  </div>
                </div>

                <div
                  class="show_again_dis_prj"
                  v-for="(answerItem, ansIdx) in item.answerList"
                  :key="ansIdx"
                >
                  <div>
                    <audio
                      style="width: 100%"
                      ref="audioPlayer"
                      :src="answerItem.url"
                      @ended="onEnded(item.answerList, ansIdx)"
                      v-if="answerItem.type == 5"
                      controls
                    ></audio>
                    <img
                      :src="answerItem.url"
                      alt=""
                      v-else-if="answerItem.type == 9"
                      style="width: 100px; height: 100px; border-radius: 0"
                    />
                    <p v-else-if="answerItem.type == 8">
                      {{ answerItem.url }}
                    </p>
                  </div>
                </div>

                <div class="show_again_dis" v-if="qaList.length - 1 !== i">
                  <div>我已清晰，继续路演</div>
                  <img src="@/assets/boxWallet/roadShow/head.png" alt="" />
                </div>
                <div class="show_btn" v-else>
                  <van-button round disabled v-if="isValid"
                    >奖励已获得（+{{ points }}</van-button
                  >
                </div>
              </div>
            </div>
          </div>
        </van-tab>
        <van-tab title="项目信息">
          <div class="detail_tab-project_info">
            <h4>项目简介</h4>
            <p>{{ pageData.prjIntro }}</p>
            <div>
              <van-tag
                plain
                type="primary"
                v-for="(item, i) in tagList"
                @click="handleTagList(item)"
                :key="i"
                >{{ item }}</van-tag
              >
            </div>
          </div>

          <div class="detail_tab-project_info">
            <h4>奖池详情</h4>

            <div class="road_show_ent_reward">
              <div class="reward_routine">
                <div style="display: flex">
                  <img src="@/assets/boxWallet/roadShow/green.png" alt="" />
                  <div class="reward_routine_r">
                    <p class="reward_routine_r_txt1">
                      常规质押奖池 {{ pageData.totalReward }}
                    </p>
                    <p class="reward_routine_r_txt2" style="font-size: 12px">
                      存入总量：{{ pageData.totalDeposit }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="reward_routine-vait">
                <p style="font-size: 14px">待领取奖励：{{ userInfoReward }}</p>
                <van-button type="primary" @click="handleGetReward"
                  >领取</van-button
                >
              </div>
            </div>

            <div class="road_show_ent_reward" style="margin-bottom: 20px">
              <div class="reward_routine">
                <div style="display: flex">
                  <img src="@/assets/boxWallet/roadShow/yellow.png" alt="" />
                  <div class="reward_routine_r">
                    <p class="reward_routine_r_txt1">
                      升级行为奖池 {{ pageData.totalReward }}
                    </p>
                    <p
                      class="reward_routine_r_txt3"
                      style="margin-top: 5px !important; font-size: 12px"
                    >
                      需质押数量：{{ pageData.upgradeDepositAmount }}CPLE
                    </p>
                    <p class="reward_routine_r_txt3" style="font-size: 12px">
                      需质押时长：{{ pageData.upgradeDepositBlock }}
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <div class="reward_one">
                  <div class="reward_one_l">
                    <img src="@/assets/boxWallet/roadShow/one.png" alt="" />
                    <div class="reward_one_show">
                      <p style="font-size: 14px">路演互动</p>
                      <p>
                        <span class="reward_one_show_txt1"
                          >+{{ pageData.lyReward }}</span
                        >
                        <span class="reward_one_show_txt2"
                          >剩{{ pageData.lyTotal - pageData.lyUsed }}位</span
                        >
                      </p>
                    </div>
                  </div>
                  <van-button type="primary" disabled>已完成</van-button>
                  <van-button type="primary" disabled v-if="roadShowPeople <= 0"
                    >名额已满</van-button
                  >
                  <!-- <van-button type="primary" disabled v-if="isAnswerReward == 3"
                    >已获得</van-button
                  > -->
                </div>
                <div class="reward_one">
                  <div class="reward_one_l">
                    <img src="@/assets/boxWallet/roadShow/two.png" alt="" />
                    <div class="reward_one_show">
                      <p style="font-size: 14px">加入项目群</p>
                      <p>
                        <span class="reward_one_show_txt1"
                          >+{{ pageData.groupReward }}</span
                        >
                        <span class="reward_one_show_txt2"
                          >剩{{
                            pageData.groupTotal - pageData.groupUsed
                          }}位</span
                        >
                      </p>
                    </div>
                  </div>
                  <van-button type="primary" disabled>加入</van-button>
                </div>
              </div>
            </div>
          </div>
        </van-tab>
      </van-tabs>

      <!-- 领取奖励按钮 -->
      <van-popup
        v-model="showGetReward"
        round
        closeable
        position="bottom"
        :style="{ height: '48%' }"
      >
        <div class="detail_show_answer—correct">
          <img src="@/assets/boxWallet/roadShow/correct.png" alt="" />
          <p class="detail_show_answer—correct_num">+{{ userInfoReward }}</p>
          <h2>回答正确，已获得奖励</h2>
          <div class="show_btn">
            <van-button round @click="handleGetReward">知道了</van-button>
          </div>
        </div>
      </van-popup>

      <!-- 加入群聊 -->
      <van-popup v-model="showJoinGroupChat" closeable round>
        <div class="join_group_chat">
          <h2>加入群聊</h2>
          <p>请下载趣出行App</p>
          <img src="@/assets/boxWallet/logo4.png" alt="" />
          <div class="pay_call" style="margin: 0 auto" @click="handleDownLoad">
            跳转下载链接
          </div>
        </div>
      </van-popup>

      <!-- 申请解押 -->
      <van-popup
        v-model="showRelease"
        round
        position="bottom"
        :style="{ height: '20%' }"
      >
        <div class="detail_show_answer—error">
          <h2>确定申请解押吗？</h2>
          <div class="show_btn" style="justify-content: space-between">
            <van-button
              round
              @click="showRelease = false"
              style="margin-top: 10px"
              >取消</van-button
            >
            <van-button round type="danger" @click="handleRelease"
              >申请解押</van-button
            >
          </div>
        </div>
      </van-popup>

      <!-- 还有？天可升级行为奖池的申请解押操作 -->
      <van-popup
        v-model="showReleaseTime"
        round
        position="bottom"
        :style="{ height: '20%' }"
      >
        <div class="detail_show_answer—error">
          <h2>
            还有{{ pageData.upgradeDepositBlock }}天即可解锁升级行为奖池，
            解押后将失去进度。
          </h2>
          <div class="show_btn">
            <van-button
              round
              @click="showReleaseTime = false"
              style="margin-top: 10px"
              >取消</van-button
            >
            <van-button round type="danger" @click="handleReleaseTime"
              >申请解押</van-button
            >
          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import { downLoadAppFn } from "@/util/downLoadApp";

import md5 from "js-md5";
import Decimal from "decimal.js";
import {
  userInfoAmount,
  userPendingReward,
  upgradeDeposit,
  depositPledge,
  userWithDraw,
} from "@/service/road_show";

import { reqProject } from "@/api/apiUrl";
import { request } from "@/api/request";
import { showLoading, hideLoading } from "@/util/loading";
import { formatDuration, format_time_date_gang } from "@/util/funBusiness";

Decimal.set({
  precision: 20, // 设置精度
  rounding: Decimal.ROUND_DOWN, // 设置舍入模式
});
export default {
  data() {
    return {
      active: 2,
      isReward: false, // 常规质押奖池按钮
      isPledgeNoRoutine: true, // 有质押，不满足升级行为奖池条件

      isAnswerReward: 1, // 奖励已获得按钮

      tagList: ["官网", "白皮书", "研究报告", "视频介绍", "规则详情"],

      showGetReward: false, // 领取奖励
      showJoinGroupChat: false, // 加入群聊
      showRelease: false, // 申请解押
      showReleaseTime: false, // 还有？天可升级行为奖池的申请解押操作

      roadShowPeople: 0, // 路演互动剩余人数

      dateNew: "",
      pageData: {},

      userInfoAmount: "", // 常规质押-用户存入总量
      rewardOk: "", // 已领取奖励
      userInfoRewardBlock: "", // 质押时长
      userInfoReward: "", // 用户待领取奖励
      qaList: [], // 问答列表
      points: "", // 奖励已获得-积分数量

      isValid: false, // 问题是否回答正确
    };
  },

  mounted() {
    this.pageData = JSON.parse(localStorage.getItem("historyDetail"));
    this.pageData.endCountdown = formatDuration(
      this.pageData.endCountdown * 1000
    );

    this.pageData.closeDate = format_time_date_gang(this.pageData.closeDate);

    this.pageData.poolBlock = formatDuration(
      (this.pageData.poolEndBlock - this.pageData.poolStartBlock) * 2 * 1000
    );

    this.roadShowPeople = this.pageData.lyTotal - this.pageData.lyUsed;

    if (this.pageData.upgradeDepositBlock == "0天") {
      this.isAnswerReward = 3;
    }
    userInfoAmount(this.pageData.poolPid).then((res) => {
      console.log(res, 11231);
      this.userInfoAmount = res.amount;
      this.rewardOk = res.rewaedDebt;
      this.userInfoRewardBlock = formatDuration(
        (res.endBlock - res.startBlock) * 2 * 1000
      );
    });
    userPendingReward(this.pageData.poolPid).then((res) => {
      this.userInfoReward = res;
    });

    if (this.pageData.upgradeDepositAmount != 0) {
      this.isPledge = true;
    } else {
      this.isPledge = false;
    }

    showLoading();
    const optQaList = {
      url: reqProject.prjInfoQaList,
      method: "POST",
      params: JSON.stringify({
        prjId: this.$route.query.prjId,
      }),
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        nonce: num,
        ts: this.dateNew,
        sign: md5(md5Code),
        token: localStorage.getItem("token"),
      },
      resFunc: (res) => {
        if (res.data.code == 0) {
          hideLoading();
          console.log(res.data);

          this.qaList = res.data.data.list;
        }

        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        }
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
        hideLoading();
      },
    };
    request(optQaList);

    const optIsQuestion = {
      url: reqProject.checkQuestionAnswer,
      method: "POST",
      params: JSON.stringify({
        prjId: this.$route.query.prjId,
      }),
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        nonce: num,
        ts: this.dateNew,
        sign: md5(md5Code),
        token: localStorage.getItem("token"),
      },
      resFunc: (res) => {
        if (res.data.code == 0) {
          hideLoading();
          console.log(res.data);
          this.isValid = res.data.data.isValid;
          this.points = res.data.data.points;
        }

        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        }
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
        hideLoading();
      },
    };
    request(optIsQuestion);
  },

  methods: {
    handleBack() {
      this.$router.push("/roadShow");
      localStorage.removeItem("historyDetail");
    },

    handleGoRoadShow() {
      this.active = 2;
    },

    // 加入群聊
    handleJoinGroupChat() {
      this.showJoinGroupChat = true;
    },

    handleDownLoad() {
      downLoadAppFn();
    },

    // 解押
    handleRelease() {
      userWithDraw(this.pageData.poolPid, this.userInfoAmount).then((res) => {
        console.log("解除质押", res);
        this.showRelease = false;
        this.$toast({
          type: "success",
          message: "解押成功",
          overlay: true,
        });
      });
    },

    handleReleaseTime() {
      userWithDraw(this.pageData.poolPid, this.userInfoAmount).then((res) => {
        console.log("解除质押", res);
        this.showReleaseTime = false;
        this.$toast({
          type: "success",
          message: "解押成功",
          overlay: true,
        });
      });
    },

    /// 领取奖励按钮
    handleGetReward() {
      depositPledge(this.poolPid, 0).then((res) => {
        console.log("领取奖励：", res);
        this.showGetReward = true;
      });
    },

    // 点击标签
    handleTagList(value) {
      if (value == "官网") {
        window.open(this.pageData.prjWebsite);
      } else if (value == "白皮书") {
        window.open(this.pageData.prjWhitepaper);
      } else if (value == "研究报告") {
        window.open(this.pageData.prjReport);
      } else if (value == "视频介绍") {
        window.open(this.pageData.prjVideoIntro);
      } else if (value == "规则详情") {
        window.open(this.pageData.prjDetailRule);
      }
    },

    getTimeFn() {
      this.dateNew = new Date().getTime();
      return this.dateNew;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../all.scss";
@import "./index.scss";
@import "../trading/index.scss";

.voice-player {
  width: 100%;
  height: 40px;
  line-height: 40px;
  background-color: #fff;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.voice-bar {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #eee;
}

.progress {
  height: 100%;
  background-color: #007aff;
  position: absolute;
  top: 0;
  left: 0;
}

.voice-status {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 50%;
}

.played-dot {
  background-color: #ccc;
}

.played {
  background-color: #f5f5f5;
}
</style>
