<template>
  <div class="road_show_detail_pledge">
    <div class="trading_head">
      <van-icon name="arrow-left" @click="handleBack" />
      <img
        src="@/assets/boxWallet/roadShow/logo2.png"
        @click="handleJoinGroupChat"
        alt=""
      />
    </div>

    <div class="detail_pledge_head">
      <div class="detail_pledge_head_box">
        <img src="@/assets/boxWallet/roadShow/12.png" alt="" />
        <p>待开始</p>
      </div>
    </div>

    <div class="road_show_detail_h">
      <div class="road_show_ent_head">
        <img :src="pageData.prjAvatarUrl" alt="" />
        <p>{{ pageData.prjName }}</p>
      </div>
      <div class="road_show_ent_txt">
        <p>总奖励</p>
        <span>{{ pageData.totalReward }}</span>
      </div>
      <div class="road_show_ent_txt">
        <p>挖币周期</p>
        <span>{{ pageData.poolBlock }}</span>
      </div>
      <div class="road_show_ent_txt">
        <p>开始时间</p>
        <span>{{ pageData.launchDate }}</span>
      </div>
    </div>

    <div class="road_show_detail_tab">
      <van-tabs
        animated
        color="unset"
        class="tabsCapsule"
        background="#ffffff1a"
        v-model="active"
      >
        <van-tab title="路演">
          <div style="margin-top: 50px">
            <div class="no_pledge_dis-start">
              <img src="@/assets/boxWallet/roadShow/nostart.png" alt="" />
              <h3 style="color: #ffffff73">路演还未开始</h3>
            </div>
          </div>
        </van-tab>
        <van-tab title="项目信息">
          <div class="detail_tab-project_info">
            <h4>项目简介</h4>
            <p>{{ pageData.prjIntro }}</p>
            <div>
              <van-tag
                plain
                type="primary"
                v-for="(item, i) in tagList"
                :key="i"
                @click="handleTagList(item)"
                >{{ item }}</van-tag
              >
            </div>
          </div>

          <div class="detail_tab-project_info">
            <h4>奖池详情</h4>
            <div class="road_show_ent_reward">
              <div class="reward_routine">
                <div style="display: flex">
                  <img src="@/assets/boxWallet/roadShow/green.png" alt="" />
                  <div class="reward_routine_r">
                    <p class="reward_routine_r_txt1">
                      常规质押奖池 {{ pageData.totalReward }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="road_show_ent_reward">
              <div class="reward_routine">
                <div style="display: flex">
                  <img src="@/assets/boxWallet/roadShow/yellow.png" alt="" />
                  <div class="reward_routine_r">
                    <p class="reward_routine_r_txt1">
                      升级行为奖池 {{ pageData.totalReward }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </van-tab>
      </van-tabs>

      <!-- 加入群聊 -->
      <van-popup v-model="showJoinGroupChat" closeable round>
        <div class="join_group_chat">
          <h2>加入群聊</h2>
          <p>请下载趣出行App</p>
          <img src="@/assets/boxWallet/logo4.png" alt="" />
          <div class="pay_call" style="margin: 0 auto" @click="handleDownLoad">
            跳转下载链接
          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import Decimal from "decimal.js";

import { downLoadAppFn } from "@/util/downLoadApp";

import { format_time_date_gang, formatDuration } from "@/util/funBusiness";

Decimal.set({
  precision: 20, // 设置精度
  rounding: Decimal.ROUND_DOWN, // 设置舍入模式
});

export default {
  data() {
    return {
      active: 2,
      tagList: ["官网", "白皮书", "研究报告", "视频介绍", "规则详情"],
      showJoinGroupChat: false, // 加入群聊

      dateNew: "",
      pageData: {},
    };
  },

  mounted() {
    this.pageData = JSON.parse(localStorage.getItem("noStartDetail"));
    this.pageData.startCountdown = formatDuration(
      this.pageData.startCountdown * 1000
    );
    this.pageData.poolBlock = formatDuration(
      (this.pageData.poolEndBlock - this.pageData.poolStartBlock) * 2 * 1000
    );
    this.pageData.launchDate = format_time_date_gang(this.pageData.launchDate);
  },

  methods: {
    handleBack() {
      this.$router.push("/roadShow");
      localStorage.removeItem("noStartDetail");
    },

    // 加入群聊
    handleJoinGroupChat() {
      this.showJoinGroupChat = true;
    },

    handleDownLoad() {
      downLoadAppFn();
    },

    // 点击标签
    handleTagList(value) {
      if (value == "官网") {
        window.open(this.pageData.prjWebsite);
      } else if (value == "白皮书") {
        window.open(this.pageData.prjWhitepaper);
      } else if (value == "研究报告") {
        window.open(this.pageData.prjReport);
      } else if (value == "视频介绍") {
        window.open(this.pageData.prjVideoIntro);
      } else if (value == "规则详情") {
        window.open(this.pageData.prjDetailRule);
      }
    },

    getTimeFn() {
      this.dateNew = new Date().getTime();
      return this.dateNew;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../all.scss";
@import "./index.scss";
@import "../trading/index.scss";
</style>
