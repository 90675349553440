const export_excel_file = (export_data, list_name) => {
  let link = document.createElement("a");
  //    type就是blob的type,是MIME类型的，可以自己查看MIME类型都有哪些
  let blogw = new Blob([export_data], {
    type: "application/vnd.ms-excel;charset=utf-8",
  });
  let objectUrl = window.URL.createObjectURL(blogw); //创建一个新的url对象
  link.href = objectUrl;
  let file_name = `${list_name}列表.xlsx`;
  link.download = file_name; //  下载的时候自定义的文件名
  link.click();
  window.URL.revokeObjectURL(objectUrl); //为了更好地性能和内存使用状况，应该在适当的时候释放url.
};

// 时间转换格式
const format_time_date_time = (d) => {
  if (d !== null) {
    var date = new Date(d);
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? "0" + m : m;
    var d = date.getDate();
    d = d < 10 ? "0" + d : d;
    var currentdate = y + "年" + m + "月" + d + "日";
    return currentdate;
  }
};

// 时间转换格式
const format_time_date = (gmtDate) => {
  if (gmtDate !== null) {
    var d = new Date(gmtDate);

    //获取本地时区，GMT+8指的是东八区，比格林威治时间快8个小时
    new Date().getTimezoneOffset() / 60; //-8  （北京时间）
    var date1 = new Date(
      new Date(gmtDate).getTime() +
        new Date(gmtDate).getTimezoneOffset() * 60 * 1000 +
        8 * 60 * 60 * 1000
    );
    var y = date1.getFullYear();
    var m = date1.getMonth() + 1;
    m = m < 10 ? "0" + m : m;
    var d = date1.getDate();
    d = d < 10 ? "0" + d : d;
    var currentdate = y + "年" + m + "月" + d + "日";
    return currentdate;
  }
};

// 时间转换格式
const format_time_date_gang = (gmtDate) => {
  if (gmtDate !== null) {
    var d = new Date(gmtDate);

    //获取本地时区，GMT+8指的是东八区，比格林威治时间快8个小时
    new Date().getTimezoneOffset() / 60; //-8  （北京时间）
    var date1 = new Date(
      new Date(gmtDate).getTime() +
        new Date(gmtDate).getTimezoneOffset() * 60 * 1000 +
        8 * 60 * 60 * 1000
    );
    var y = date1.getFullYear();
    var m = date1.getMonth() + 1;
    m = m < 10 ? "0" + m : m;
    var d = date1.getDate();
    d = d < 10 ? "0" + d : d;
    var currentdate = y + "-" + m + "-" + d;
    return currentdate;
  }
};

// 时间转换格式
const format_time_date_time2 = (d) => {
  if (d !== null) {
    var date = new Date(d);
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? "0" + m : m;
    var d = date.getDate();
    d = d < 10 ? "0" + d : d;
    var currentdate = y + "-" + m + "-" + d;
    var hh = date.getHours();
    hh = hh < 10 ? "0" + hh : hh;
    var mm = date.getMinutes();
    mm = mm < 10 ? "0" + mm : mm;
    var ss = date.getSeconds();
    ss = ss < 10 ? "0" + ss : ss;
    var time = hh + ":" + mm + ":" + ss;
    return currentdate + " " + time;
  }
};

// 毫秒转yyyymmdd
const millisecondsToDays = (milliseconds) => {
  const MILLISECONDS_PER_DAY = 24 * 60 * 60;
  console.log(Math.floor(milliseconds / MILLISECONDS_PER_DAY), "111");
  return Math.floor(milliseconds / MILLISECONDS_PER_DAY);
};

const formatDuration = (ms) => {
  if (ms < 0) ms = -ms;
  const time = {
    day: Math.floor(ms / 86400000),
    hour: Math.floor(ms / 3600000) % 24,
    minute: Math.floor(ms / 60000) % 60,
    // second: Math.floor(ms / 1000) % 60,
    // millisecond: Math.floor(ms) % 1000,
  };
  const keyToText = {
    day: "天",
    hour: "小时",
    minute: "分钟",
    // second: "秒",
    // millisecond: "毫秒",
  };
  return Object.entries(time)
    .filter((val) => val[1] !== 0)
    .map(([key, val]) => `${val}${keyToText[key]}`)
    .join(" ");
};

// excel下载文件名时间格式
const format_time_date_excel = (date) => {
  // if (typeof date == "string") {
  //   return format_time_date2(date);
  // }
  var y = date.getFullYear();
  var m = date.getMonth() + 1;
  m = m < 10 ? "0" + m : m;
  var d = date.getDate();
  d = d < 10 ? "0" + d : d;
  var h = date.getHours();
  h = h < 10 ? "0" + h : h;
  var mi = date.getMinutes();
  mi = mi < 10 ? "0" + mi : mi;
  var s = date.getSeconds();
  s = s < 10 ? "0" + s : s;
  return y + "-" + m + "-" + d + " " + h + "-" + mi + "-" + s;
};

const convertToUpperToLower = (str) => {
  var result = "";

  for (var i = 0; i < str.length; i++) {
    var char = str.charAt(i);
    if (char >= "A" && char <= "Z") {
      result += char.toLowerCase();
    } else {
      result += char;
    }
  }

  return result;
};

export {
  export_excel_file,
  format_time_date_time,
  format_time_date_gang,
  format_time_date_time2,
  format_time_date,
  format_time_date_excel,
  millisecondsToDays,
  formatDuration,
  convertToUpperToLower,
};
