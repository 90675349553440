<template>
  <div class="header_tabbar">
    <p>{{ title }}</p>
    <van-button type="primary" @click="connectWallet">授权</van-button>
  </div>
</template>

<script>
import Web3 from "web3";

import { reqProject } from "@/api/apiUrl";
import { convertToUpperToLower } from "@/util/funBusiness.js";
// import { showBalance } from "@/service/mall_mask";

import axios from "axios";

export default {
  props: {
    title: String,
  },

  data() {
    return {
      web3: null,
      connected: false,
      message: "",
      signature: "",
      userName: "",

      userData: {},
      dateNew: "",
    };
  },

  methods: {
    async connectWallet() {
      if (!window.ethereum) {
        this.$Message.error("请安装MetaMask");
        return;
      }

      try {
        await window.ethereum.request({ method: "eth_requestAccounts" });
        this.web3 = new Web3(window.ethereum);
        this.connected = true;
        console.log("已连接钱包");
        console.log(this.userData);
        // signMessage(this.connected, this.web3);
        // showBalance();

        this.signMessage();
        // this.refresh();
      } catch (error) {
        console.error(error);
      }
    },

    // 签名
    async signMessage() {
      if (!this.connected) {
        this.$Message.error("请先连接到钱包");
        return;
      }

      var num = "";
      for (var i = 0; i < 32; i++) {
        num += Math.floor(Math.random() * 10);
      }

      const address = (await this.web3.eth.getAccounts())[0];
      const adrLower = address.toLowerCase();
      console.log(adrLower, "adrLoweradrLower");
      localStorage.setItem("adrLower", adrLower);

      this.dateNew = new Date().getTime();
      let nonce = `${adrLower}@${this.dateNew}`;
      this.message = nonce;

      const signature = await this.web3.eth.personal.sign(
        this.message,
        address
      );
      this.signature = signature;

      localStorage.setItem("sign", signature);

      if (this.signature != "") {
        this.userInfoAxios({
          ts: this.dateNew,
          sign: this.signature,
          num: num,
          adrLower: adrLower,
        });
      }
      // this.refresh();
    },

    userInfoAxios(obj) {
      const params = {
        message: this.message,
        password: "123321",
        rePassword: "123321",
        signature: this.signature,
        timestamp: this.dateNew,
        userAddress: obj.adrLower,
      };

      axios
        .post(reqProject.accountImportByMnemonic, params, {
          headers: {
            nonce: obj.num,
            ts: obj.ts,
            sign: obj.sign,
          },
        })
        .then((res) => {
          if (res.data.success) {
            // localStorage.setItem("userData", JSON.stringify(res.data.data));
            localStorage.setItem("token", res.data.data.token);
            // this.refresh();
          } else {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
