<template>
  <div style="padding: 10px; text-align: justify">
    <h1>Quchuxing Privacy Notice</h1>

    <p>
      I. Introduction / II. Overview / III. Data collections and uses / IV.
      Choice and transparency / V. Updates to this notice
    </p>
    <br />
    <h2>I. Introduction</h2>
    <p>
      When you use Quchuxing, you trust us with your personal data. We’re
      committed to keeping that trust. That starts with helping you understand
      our privacy practices.
    </p>
    <br />
    <p>
      This notice describes the personal data (“data”) we collect, how it’s used
      and shared, and your choices regarding this data. We recommend that you
      read this along with our privacy overview, which highlights key points
      about our privacy practices.
    </p>
    <br />
    <h2>II. Overview</h2>
    <h3>A. Scope</h3>
    <p>
      This notice applies to users of Quchuxing’s services anywhere in the
      world, including users of Quchuxing’s apps, websites, features, or
      other services.
    </p>
    <p>
      This notice describes how Quchuxing and its affiliates collect and use
      data. This notice applies to all Quchuxing users globally, This notice
      specifically applies to:
    </p>
    <div>
      <span style="font-weight: bold">Riders:</span>
      <span
        >individuals who request or receive transportation and related services
        via their Quchuxing account.</span
      >
    </div>
    <div>
      <span style="font-weight: bold">Drivers:</span>
      <span
        >individuals who provide transportation to Riders individually.</span
      >
    </div>
    <br />
    <p>
      All those subject to this notice are referred to as “users” in
      this notice.
    </p>
    <br />
    <h4>B. Data controller and transfer</h4>
    <p>
      Quchuxing Technologies Inc. is the data controller for the data collected
      in connection with use of Quchuxing’s services everywhere .
    </p>
    <br />
    <p>
      Quchuxing operates, and processes personal data, globally; wherever and
      whenever you travel, your data travels with you so we can offer you a
      seamless experience. We also respect your data protection rights whether
      you are in your home country or elsewhere.
    </p>
    <br />
    <p>
      This means that we may transfer personal data to countries other than your
      home country in order to provide our services in a different country at
      your request. We may also transfer personal data to another country if
      that is necessary to respond to a customer support request that you
      submitted outside your region. And trip/order histories may be accessed by
      users in another country after they have left the country where services
      were rendered. Because our services involve multiple users at the same
      time, limited personal data may be accessible to the other user in
      another country.
    </p>
    <br />
    <p>
      We do so in order to fulfill our agreements with all of our users, or
      based on users’ instructions or prior consent, adequacy decisions for the
      relevant countries, or other transfer mechanisms as may be available under
      applicable law, such as the Standard Contractual Clauses.
    </p>
    <br />
    <p>
      We may also transfer data to other countries in order to respond to a
      request for personal data from a law enforcement agency. To provide
      additional safeguards to these data transfers, Quchuxing applies a robust
      internal procedure for processing these requests.
    </p>
    <br />
    <p>
      Quchuxing only discloses personal data to law enforcement agencies in a
      manner that is consistent with the regulatory requirements of applicable
      laws, such as the GDPR. You can find more information about requests from
      law enforcement here. To help provide equivalent data protection wherever
      you use our services, Quchuxing has implemented the following measures
      across the globe:
    </p>
    <ul>
      <li>
        policies and procedures to limit access to, and the processing of
        personal data for defined purposes;
      </li>
      <li>
        specific training of personnel responsible for managing personal data;
      </li>
      <li>
        a government data access policy that refuses government access to data,
        except when disclosure is mandated by applicable laws including the EU
        GDPR, there is imminent risk of serious harm to individuals, or consent
        is obtained;
      </li>
      <li>
        robust protection of data while it is in transit between Quchuxing apps
        and our servers, and when the data is processed on our servers. This
        includes encryption and the use of pseudonymized data to help protect
        users' full identity against unauthorized disclosure.
      </li>
      <li>
        For your convenience, the exercise of your data protection rights can be
        directed to Quchuxing via any of the designated channels mentioned
        above, and will be handled centrally on behalf of the relevant
        controller. Questions, comments, and complaints about Quchuxing’s data
        practices can be submitted here. You may also use this form to submit a
        question to Quchuxing’s Data Protection Officer and to ask us for a copy
        of the Standard Contractual Clauses or other transfer mechanisms in
        place for the data processing activity of your inquiry.
      </li>
    </ul>
    <br />
    <h2>III. Data collections and uses</h2>
    <h3>A. The data we collect</h3>
    <p>Quchuxing collects data:</p>
    <ul>
      <li>provided by users to Quchuxing, such as during account creation</li>
      <li>
        created during use of our services, such as location, app usage, and
        device data
      </li>
      <li>
        from other sources, such as other users or account owners, business
        partners, vendors, insurance and financial solution providers, and
        governmental authorities
      </li>
    </ul>
    <br />
    <p>
      For your convenience, we've provided a visual summary of the data we
      collect and how we use it here.
    </p>
    <br />
    <p>Quchuxing collects the following data:</p>
    <br />
    <div>
      <span style="font-weight: bold">1. Data provided by users. </span>
      <span>This includes:</span>
    </div>
    <ul>
      <li>
        <span style="font-weight: bold">User profile information: </span>
        <span
          >We collect data when users create or update their Quchuxing accounts,
          or place orders via guest checkout features. This may include their
          name, email, phone number, login name and password, address, profile
          picture, payment or banking information (including related payment
          verification information), driver’s license and other government
          issued documents (which may include identification numbers as well as
          birth date, gender, and photo). This also includes vehicle or
          insurance information of drivers and delivery persons, emergency
          contact information, user settings, and evidence of health or fitness
          to provide services using Quchuxing apps.</span
        >
      </li>
      <li>
        <span style="font-weight: bold"
          >Background check information (drivers):
        </span>
        <span
          >This includes information submitted during the driver/delivery person
          application process, such as driver history or criminal record (where
          permitted by law), license status, known aliases, prior addresses, and
          right to work. This information may be collected by an authorized
          vendor on Quchuxing’s behalf.</span
        >
      </li>
      <li>
        <span style="font-weight: bold">Identity verification photos: </span>
        <span
          >This includes photos of users (such as, selfies) and/or their
          government issued identifications (such as, driver’s license or
          passports). Such photos may be used to verify a user’s identity, such
          as through facial verification technologies. Please see the section
          titled “How we use personal data” for more information regarding how
          user photos are used for safety and security purposes, including
          identity verification.</span
        >
      </li>
      <li>
        <span style="font-weight: bold">Demographic data: </span>
        <span
          >We may collect demographic data about users, such as birth date/age,
          gender or occupation, when required for certain Quchuxing services or
          programs, such as Quchuxing Cash, alcohol deliveries or safety
          features that allow women users to set a preference to provide or
          receive services to/from other women (“Women Rider Preference”). We
          may also collect demographic data, such as age group and household
          composition, through user surveys, and use this information to offer
          Quchuxing products and services that are likely to be of interest to
          you.</span
        >
        <br /><br />
        <p>
          We may also infer demographic data from other data collected from
          users. For example, we may infer a user’s gender based on their first
          name to enable safety features, such as the Women Rider Preference, or
          to display personalized ads for third party products or services. See
          the section titled “How we use personal data” below for
          more information.
        </p>
      </li>
      <li>
        <span style="font-weight: bold">User content: </span>
        <span
          >We collect the data submitted by users when they contact Quchuxing
          customer support (including at Quchuxing Greenlight Hubs or via phone
          chat or video conferencing tools), provide ratings or feedback for
          other users, restaurants or merchants, or otherwise contact Quchuxing.
          This may include photographs, or audio or video recordings submitted
          by users in connection with a customer support request. This also
          includes metadata relating to the method you use to communicate with
          Quchuxing. The contents of photos and recordings submitted by users
          are subject to Quchuxing’s Community Guidelines (except that such
          guidelines do not apply to users in Switzerland and Belgium).</span
        >
        <br /><br />
        <p>
          In certain jurisdictions, users can record the audio and/or video of
          their trips through an in-app feature or using a dashcam. In app
          recordings are encrypted and stored on users’ devices, and are only
          shared with Quchuxing if submitted by the users in connection with
          safety incidents. Please see here for more information.
        </p>
      </li>
      <li>
        <span style="font-weight: bold">Travel information: </span>
        <span
          >We collect travel itinerary information, including times and dates of
          upcoming flight, lodging or car rental reservations, from users of
          our Quchuxing Travel feature. We collect such information: (i) when
          users manually input their information into their Quchuxing Travel
          itinerary; or (2) if authorized by users to access their email
          account, from travel-related email confirmations. If so authorized,
          Quchuxing will only access users’ email accounts to collect travel
          itinerary information to enable the Quchuxing Travel feature, and will
          adhere to the applicable email provider policies, including Google’s
          API Services User Data Policy, which impose certain limitations on the
          use of data collected from users’ email accounts. Users may remove
          Quchuxing’s access to their email accounts at any time either in-app
          or through their email service provider settings. Detailed information
          on how to remove email account access is available here.</span
        >
      </li>
    </ul>
    <br />
    <div>
      <span style="font-weight: bold"
        >2. Data created during use of our services.
      </span>
      <span>This includes:</span>
    </div>
    <ul>
      <li>
        <span style="font-weight: bold">Location data (driver): </span>
        <span
          >We collect precise or approximate location data from drivers’ and
          delivery persons’ mobile devices when the Quchuxing app is running in
          the foreground (app open and on-screen) or background (app open but
          not on-screen).</span
        >
      </li>
      <li>
        <span style="font-weight: bold">Location data (riders): </span>
        <span
          >We collect precise or approximate location information from riders’
          and order recipients’ mobile devices if they enable us to do so via
          their device settings.</span
        >
        <br /><br />
        <p>
          Quchuxing collects such data from the time a ride or order is
          requested until it is finished, and any time the app is running in the
          foreground (app open and on-screen). See “Choice and transparency”
          below for information on how riders and order recipients can enable
          precise location data collection.
        </p>
        <br />
        <p>
          Riders and order recipients may use the Quchuxing apps without
          enabling collection of location data from their mobile devices.
          However, this may affect certain features in the Quchuxing apps. For
          example, a rider who has not enabled precise location data will have
          to manually enter their pickup address.
        </p>
        <br />
        <p>
          In addition, precise location data collected from a driver’s device
          during a trip is linked to the rider’s account, even if the rider has
          not enabled precise location data to be collected from their device.
          This data is used for purposes such as receipt generation, customer
          support, fraud detection, insurance, and litigation.
        </p>
      </li>
      <li>
        <span style="font-weight: bold">Transaction information: </span>
        <span
          >We collect transaction information related to the use of our
          services, including the type of services requested or provided; trip
          or order details (such as date and time, requested pick-up and drop
          off addresses, distance traveled and items ordered); and payment
          transaction information (such as a restaurant’s or merchant's name and
          location, amount charged, and payment method). We also associate a
          user’s name with that of anyone who uses their promotion code.</span
        >
      </li>
      <li>
        <span style="font-weight: bold">Usage data: </span>
        <span
          >We collect data about how users interact with our services. This
          includes access dates and times, app features or pages viewed, browser
          type, and app crashes and other system activity.</span
        >
      </li>
      <li>
        <span style="font-weight: bold">Device data: </span>
        <span
          >We collect data about the devices used to access our services,
          including the hardware models, device IP address or other unique
          device identifiers, operating systems and versions, software,
          preferred languages, advertising identifiers, device motion data, and
          mobile network data.</span
        >
      </li>
      <li>
        <span style="font-weight: bold">Communications data: </span>
        <span
          >We collect data regarding phone, text or in-app communications
          between users that are enabled through Quchuxing’s apps. This includes
          date and time, as well as the content of text or in-app messages. We
          may also collect the content of phone calls solely where users are
          notified in advance that the call may be recorded.</span
        >
      </li>
    </ul>
    <div>
      <span style="font-weight: bold">3. Data from other sources. </span>
      <span>These include:</span>
    </div>
    <ul>
      <li>
        users participating in our referral programs. For example, when a user
        refers another person, we receive the referred person’s data from
        that user.
      </li>
      <br />
      <li>
        Quchuxing account owners who request services for or on behalf of other
        users (such as friends or family members), or who enable other users to
        request or receive services through their business accounts .
      </li>
      <br />
      <li>
        users or others providing information in connection with claims
        or disputes.
      </li>
      <br />
      <li>
        Quchuxing business partners through which users create or access their
        Quchuxing account, such as payment providers, social media services, or
        apps or websites that use Quchuxing’s APIs or whose APIs Quchuxing uses.
      </li>
      <br />
      <li>
        Quchuxing business partners in connection with debit or credit cards
        issued by a financial institution in partnership with Quchuxing to the
        extent disclosed in the terms and conditions for the card.
      </li>
      <br />
      <li>
        vendors who help us verify users’ identity, background information, and
        eligibility to work, or who screen users in connection with sanctions,
        anti-money laundering, or know-your-customer requirements.
      </li>
      <br />
      <li>
        insurance, vehicle, or financial services providers for drivers and/or
        delivery persons.
      </li>
      <br />
      <li>
        partner transportation companies (for drivers or delivery persons who
        use our services through an account associated with such a company).
      </li>
      <br />
      <li>publicly available sources.</li>
      <br />
      <li>
        marketing service providers or data resellers whose data Quchuxing uses
        for marketing or research.
      </li>
      <br />
      <li>
        law enforcement officials, public health officials, and other
        government authorities.
      </li>
    </ul>
    <br />
    <h3>B. How we use personal data</h3>
    <ul>
      <li>
        Quchuxing uses data to enable reliable and convenient transportation,
        delivery, and other products and services. We also use such data:
      </li>
      <li>to enhance the safety and security of our users and services</li>
      <li>for customer support</li>
      <li>for research and development</li>
      <li>to enable communications between users</li>
      <li>for marketing and advertising</li>
      <li>to send non-marketing communications to users</li>
      <li>in connection with legal proceedings</li>
    </ul>
    <br />
    <p>
      For your convenience, we've provided a visual summary of the data we
      collect and how we use it here. 
    </p>
    <br />
    <p>We use the data we collect:</p>
    <br />
    <div>
      <span style="font-weight: bold">1. To provide our services.</span>
      <span
        >Quchuxing uses data to provide, personalize, maintain, and improve our
        services.</span
      >
    </div>
    <p>This includes using data to:</p>
    <ul>
      <li>create/update accounts.</li>
      <li>
        enable transportation, delivery and other services/features, such as:

        <p>
          ~using location data to navigate rider pick-ups and order drop-offs,
          calculate ETAs, and track the progress of rides or deliveries.
        </p>
        <p>
          ~ofeatures that involve data sharing, such as sharing driver first
          name and vehicle information with riders to facilitate pick-ups, user
          profile and trip details for ETA sharing and fare splitting, and user
          ratings and compliments.
        </p>
        <p>
          ~omatch available drivers and delivery persons to users requesting
          services. Users can be matched based on availability,
          location/proximity,user settings/preferences and other factors such as
          likelihood to accept a trip based on their past behavior or
          preferences.
        </p>
        <p>
          ~ofeatures that facilitate use of our services by those with
          disabilities.
        </p>
        <p>
          ~ofeatures that involve account linking, such as linking of email to
          create Quchuxing Travel itineraries and linking of rewards programs to
          enable partnership benefits (such as with Marriott Bonvoy). 
        </p>
      </li>
      <li>
        enable dynamic pricing, in which ride prices, or delivery fees are
        determined based on factors such as estimated time and distance,
        predicted route, estimated traffic, and the current number of users
        requesting or providing services.
      </li>
      <li>process payments.</li>
      <li>
        personalize users’ accounts. For example, we may present order
        recipients with personalized restaurant or food recommendations based on
        their prior orders.
      </li>
      <li>
        facilitate insurance, vehicle, invoicing, or financing solutions.
        provide users with trip or delivery updates, generate receipts, and
        inform them of changes to our terms, services, or policies.
      </li>
      <li>
        perform necessary operations to maintain our services, including to
        troubleshoot software bugs and operational problems.
        <br />
        <br />
        <p>
          Quchuxing performs the above activities on the grounds that they are
          necessary to fulfill the terms of our agreements with users, are
          compatible with such uses, or on the grounds that they are necessary
          for purposes of Quchuxing’s and its users’ legitimate interests.
        </p>
      </li>
    </ul>
    <div>
      <span style="font-weight: bold">2. Safety and security.</span>
      <span
        >We use data to help maintain the safety, security, and integrity of our
        services and users. This includes:</span
      >
    </div>
    <br />
    <ul>
      <li>
        verifying users' identity and eligibility to provide transportation or
        deliveries, including through reviews of background checks, to help
        prevent use of our services by unsafe drivers and/or riders.
        <br /><br />
        <p>
          In certain regions, this includes Quchuxing’s Real-Time ID
          Check feature, which prompts drivers and delivery persons to share a
          selfie before going online to help ensure that the driver or delivery
          person using the app matches the Quchuxing account we have on file. We
          may also use this feature for other verification purposes such as
          verifying change of bank account information or regaining account
          access.
        </p>
        <br />
        <p>
          In addition, we may use facial recognition technology to process user
          profile photographs, identification photographs, or other
          user-submitted photographs to prevent identity-borrowing or use of our
          services by unauthorized drivers or delivery people.
        </p>
        <br />
        <p>
          We may also use selfies to verify that users are wearing masks,
          helmets or other safety gear through the use of object verification
          technology.
        </p>
        <br />
        <p>
          In some countries, riders may be required to verify their identity to
          use cash and certain other payment methods. In such cases, Quchuxing
          allows riders to provide their ID number and/or a photo of their
          identification card to complete such verifications. Please
          see here for more information about rider identity verification.
        </p>
        <br />
        <p>
          Similarly, order recipients may be required to provide their age and
          an image of their government issued identification to verify their
          eligibility to order and receive alcohol deliveries. Please
          see here for more information about order recipient
          identity verification.
        </p>
      </li>
      <li>
        using data from drivers’ or delivery persons’ devices to detect unsafe
        driving behavior such as speeding or harsh braking and acceleration, and
        to inform them of safer driving practices. We also use data from
        delivery persons’ devices to verify the type of vehicles they used to
        provide deliveries.
      </li>
      <li>
        using driver location information, and communications between riders and
        drivers, to identify cancellation fees earned or induced through fraud.
        For example, if we determine that a driver is delaying a rider pickup in
        order to induce a cancellation, we will not charge the rider a
        cancellation fee and will adjust the amounts paid to the driver to omit
        such a fee. To object to such an adjustment, please contact Quchuxing
        customer support.
      </li>
      <li>
        using device, location, user profile, usage, and other data to prevent,
        detect, and combat other types of fraud. This includes identifying
        fraudulent accounts or uses of our services, preventing use of our
        services by unauthorized drivers or delivery persons, verifying user
        identities in connection with certain payment methods, and preventing
        and combating unauthorized access to users’ accounts. In some cases,
        such as when a user is determined to be abusing Quchuxing’s referral
        program or has submitted fraudulent documents, such behavior may result
        in automatic deactivation, or in the European Union or where otherwise
        required by law, deactivation after human review. To object to such a
        deactivation, please contact Quchuxing customer support.
      </li>
      <li>
        using user ratings, reported incidents, and other feedback to encourage
        compliance with our Community Guidelines and as grounds for deactivating
        users with low ratings or who otherwise violated such guidelines in
        certain countries. (This data use is not performed in Switzerland and
        Belgium). In the European Union or where otherwise required by law, such
        deactivation occurs only after human review and/or the possibility to
        appeal. For more information about how ratings are determined and used,
        please see here for rider ratings, here for driver ratings, and here for
        delivery person ratings. To object to such a deactivation, please
        contact Quchuxing customer support.
      </li>
      <li>
        using driver data (such as past trip information and reported incident
        rates) and rider data (such as past trip information, cancellation and
        reported incident rates, account information, ratings information, and
        current pick-up and drop-off location) to predict and help avoid
        pairings of users that may result in increased risk of conflict (for
        instance, because one user previously gave the other a one-star rating).
      </li>
      <li>
        sharing user name, location, phone number, vehicle details and other
        relevant information with third-party companies that support the
        management and de-escalation of interpersonal conflicts that may occur
        between users while on a trip or in the process of making a delivery.
      </li>
      <br /><br />
      <p>
        Quchuxing performs the above activities on the grounds that they are
        necessary to fulfill the terms of our agreements with users, and/or for
        purposes of the legitimate safety and security interests of Quchuxing,
        our users and members of the general public.
      </p>
    </ul>
    <br />
    <div>
      <span style="font-weight: bold">3. Customer support.</span>
      <span
        >Quchuxing uses the information we collect (which may include call
        recordings) to provide customer support, including to investigate and
        address user concerns and to monitor and improve our customer support
        responses and processes.</span
      >
    </div>
    <br />
    <p>
      Quchuxing performs the above activities on the grounds that they are
      necessary to fulfill the terms of our agreements with users or for
      purposes of Quchuxing’s legitimate interest in monitoring and improving
      its customer support services.
    </p>
    <br />
    <div>
      <span style="font-weight: bold">4. Research and development.</span>
      <span
        >We use data for testing, research, analysis, product development, and
        machine learning to improve the user experience. This helps us make our
        services more convenient and easy-to-use, enhance the safety and
        security of our services, and develop new services and features.</span
      >
    </div>
    <br />
    <p>
      Quchuxing performs the above activities on the grounds that they are
      necessary for purposes of Quchuxing’s legitimate interests in improving
      and developing new services and features.
    </p>
    <br />
    <div>
      <span style="font-weight: bold"
        >5. Enabling communications between users.</span
      >
      <span
        >For example, a driver may message or call a rider to confirm a pick-up
        location, a rider may call a driver to retrieve a lost item, or a
        restaurant or delivery person may contact an order recipient with
        information about their order.</span
      >
    </div>
    <br />
    <p>
      Quchuxing performs the above activities on the grounds that they are
      necessary to fulfill the terms of our agreements with users.
    </p>
    <br />
    <div>
      <span style="font-weight: bold">6. Marketing and Advertising.</span>
      <span
        >Quchuxing uses data (other than Guest Users’ data) to market its
        services, and those of Quchuxing partners. This may include:</span
      >
    </div>
    <br />
    <ul>
      <li>
        Sending emails, text messages, push notification, and in-app messages or
        other communications marketing or advertising Quchuxing products,
        services, features, offers, promotions, sweepstakes, news and events.
        <br /><br />
        <p>
          This may include using location, trip and order history, device data
          and usage data to send marketing communications that are personalized
          based on users’ observed or inferred interests and characteristics.
          For example, we may send push notifications suggesting a user’s
          favorite destinations or merchants, or in-app messages offering
          discounts or promo for products or merchants similar to those a user
          has previously ordered.
        </p>
      </li>
      <li>
        Displaying Quchuxing advertising on third party apps or websites. This
        includes using location, trip and order history, device data and usage
        data, and sharing hashed email addresses and device or user identifiers
        with advertising partners (such as Facebook and TikTok), to personalize
        such ads to users’ interests.
      </li>
      <li>
        Displaying third party advertising in Quchuxing’s apps or in connection
        with our services.
        <br /><br />
        <p>
          This includes ads for restaurants or merchants that are available on
          Quchuxing’s apps. These ads (which are identified as “Sponsored
          Listings” in Quchuxing’s apps) include recommendations that are
          personalized based on users’ location and order histories.
        </p>
        <br />
        <p>
          This also includes ads for other third party products or services that
          are personalized based on users’ observed or inferred interests and
          characteristics, which we determine using data such as approximate
          location, trip information and history, usage data, demographic data
          (which may include inferred gender), and device or user identifiers.
        </p>
        <br />
        <p>
          This also includes ads that are personalized based on data about the
          current trip or delivery request, including time of request and
          services requested. For example, if a user requests a trip to a
          supermarket, we may display in-app ads for third party products that
          may be available at that supermarket.
        </p>
      </li>
      <li>
        Measuring the effectiveness of Quchuxing’s ads, and of third party ads
        displayed in Quchuxing’s apps or in connection with our services.
        <br /><br />
        <p>
          Quchuxing performs the above activities on the grounds that they are
          necessary for purposes of Quchuxing’s legitimate interests in
          informing users about Quchuxing services and features or those offered
          by Quchuxing partners. See the sections titled “Choice and
          transparency” and “Marketing and advertising choices” for information
          on your choices regarding how Quchuxing may use your data for
          marketing and advertising.
        </p>
      </li>
    </ul>
    <br />
    <div>
      <span style="font-weight: bold">7. Non-marketing communications.</span>
      <span
        >Quchuxing may use data to send surveys and other communications that
        are not for the purpose of marketing the services or products of
        Quchuxing or its partners. We may also send users communications
        regarding elections, ballots, referenda, and other political processes
        that relate to our services. For example, Quchuxing has notified some
        users by email of ballot measures or pending legislation relating to
        Quchuxing’s services in those users’ areas.</span
      >
    </div>
    <br />
    <p>
      Quchuxing performs the above activities on the grounds that they are
      necessary to fulfill the terms of our agreements with users, or for
      purposes of Quchuxing’s and its users’ legitimate interests in informing
      users about events that may have an impact on their use of
      Quchuxing services.
    </p>
    <br />
    <div>
      <span style="font-weight: bold"
        >8. Legal proceedings and requirements.</span
      >
      <span
        >We use data to investigate or address claims or disputes relating to
        use of Quchuxing’s services, to satisfy requirements under applicable
        laws, regulations, or operating licenses or agreements, or pursuant to
        legal process or governmental request, including from law
        enforcement.</span
      >
    </div>
    <br />
    <p>
      Quchuxing performs the above activities on the grounds that they are
      necessary for purposes of Quchuxing’s legitimate interests in
      investigating and responding to claims and disputes relating to use of
      Quchuxing’s services and features, and/or necessary for compliance with
      applicable legal requirements.
    </p>
    <h3>C. Cookies and third-party technologies</h3>
    <p>
      Quchuxing and its partners use cookies and other identification
      technologies on our apps, websites, emails, and online ads for purposes
      described in this notice, and Quchuxing’s Cookie Notice.
    </p>
    <br />
    <p>
      Cookies are small text files that are stored on browsers or devices by
      websites, apps, online media, and advertisements. Quchuxing uses cookies
      and similar technologies for purposes such as:
    </p>
    <ul>
      <li>authenticating users</li>
      <br />
      <li>remembering user preferences and settings</li>
      <br />
      <li>determining the popularity of content</li>
      <br />
      <li>
        delivering and measuring the effectiveness of advertising campaigns
      </li>
    </ul>
    <br />
    <p>
      analyzing site traffic and trends, and generally understanding the online
      behaviors and interests of people who interact with our services
    </p>
    <br />
    <p>
      We may also allow others to provide audience measurement and analytics
      services for us, to serve advertisements on our behalf across the
      internet, and to track and report on the performance of those
      advertisements. These entities may use cookies, web beacons, SDKs, and
      other technologies to identify the devices used by visitors to our
      websites, as well as when they visit other online sites and services.
    </p>
    <br />
    <p>
      Please see our Cookie Notice for more information regarding the use of
      cookies and other technologies described in this section.
    </p>
    <br />
    <h3>D. Data sharing and disclosure</h3>
    <p>
      Some of Quchuxing’s services and features require that we share data with
      other users or at a user’s request. We may also share such data with our
      affiliates, subsidiaries, and partners, for legal reasons or in connection
      with claims or disputes.
    </p>
    <br />
    <p>Quchuxing may share data:</p>
    <div>
      <span style="font-weight: bold">1. With other users</span>
      <span>This includes sharing:</span>
    </div>
    <ul>
      <li>
        riders’ first name, rating, and pickup and/or dropoff locations
        with drivers.
      </li>
      <li>
        riders’ first name with other riders in a carpool trip. Riders in
        carpool trips may also see the dropoff location of the other riders.
      </li>
      <li>
        order recipients’ first name, delivery address, and order information
        with the restaurant or merchant and, for order deliveries, with the
        delivery person. We may also share ratings and feedback, or other
        information to the extent required by law, with the restaurant or
        merchant and, for order deliveries, the delivery person.
      </li>
      <li>
        for drivers and delivery persons, we may share data with the rider(s),
        order recipient(s) and restaurants or merchants, including name and
        photo; vehicle make, model, color, license plate, and vehicle photo;
        location (before and during trip); average rating provided by users;
        total number of trips; period of time since they signed up to be a
        driver or delivery person; contact information; and driver or delivery
        person profile, including compliments and other feedback submitted by
        past users.
        <br /><br />
        <p>
          We also provide riders and order recipients with receipts containing
          information such as a breakdown of amounts charged, driver or delivery
          person first name, photo, and route map. We also include other
          information on those receipts if required by law.
        </p>
      </li>
      <li>
        for those who participate in Quchuxing’s referral program, we share
        certain data of referred users, such as trip count, with the user who
        referred them, to the extent relevant to determining the referral bonus.
      </li>
    </ul>
    <br />
    <div>
      <span style="font-weight: bold">2. At the user’s request</span>
      <span>This includes sharing data with:</span>
    </div>
    <ul>
      <li>
        Other people at the user’s request. For example, we share a user’s ETA
        and location with a friend when requested by that user, or a user’s trip
        information when they split a fare with a friend.
      </li>
      <li>
        Quchuxing business partners. For example, if a user requests a service
        through a partnership or promotional offering made by a third party,
        Quchuxing may share certain data with those third parties. This may
        include, for example, other services, platforms, apps, or websites that
        integrate with our APIs; vehicle suppliers or services; those with an
        API or service with which we integrate; or restaurants, merchants or
        other Quchuxing business partners and their users in connection with
        promotions, contests, or specialized services.
      </li>
      <li>
        Emergency services: We offer features that enable users to share their
        data with police, fire, and ambulance services in the event of an
        emergency or after certain incidents. For more information, please see
        the sections below titled “Choice and Transparency” and “Emergency Data
        Sharing”.
      </li>
    </ul>
    <br />
    <h4>3. With the general public</h4>
    <p>
      Questions or comments from users submitted through public forums such as
      Quchuxing blogs and Quchuxing social media pages may be viewable by the
      public, including any data included in the questions or comments submitted
      by a user.
    </p>
    <br />
    <h4>4. With the Quchuxing account owner</h4>
    <p>
      If a user receives services through an account owned by another party, we
      may share their trip or order information, including location data, with
      the owner of that account. This occurs, for example, when:
    </p>
    <ul>
      <li>
        a driver or delivery person uses an account owned by or associated with
        an Quchuxing partner transportation company or restaurant.
      </li>
      <li>
        a rider takes a trip or a delivery recipient receives an order arranged
        by a friend or under a family profile.
      </li>
      <li>
        In addition, if a user creates an account using an email address
        affiliated with an Quchuxing for Business account owner (i.e., their
        employer), we may use, and share their profile data (such as name and
        email address) with such account owner, to help that user expense trips
        or orders to that Quchuxing for Business account. This is applicable in
        all regions except the European Economic Area, the United Kingdom
        and Switzerland.
      </li>
    </ul>
    <br />
    <h4>5. With Quchuxing subsidiaries and affiliates</h4>
    <p>
      We share data with our subsidiaries and affiliates to help us provide our
      services or conduct data processing on our behalf.
    </p>
    <br />
    <div>
      <span style="font-weight: bold"
        >6. With Quchuxing service providers and business partners</span
      >
      <span>These include:</span>
    </div>
    <ul>
      <li>payment processors and facilitators</li>
      <li>background check and identity verification providers</li>
      <li>cloud storage providers</li>
      <li>
        Google, in connection with the use of Google Maps in Quchuxing’s apps
        (see Google’s privacy policy for information on their collection and use
        of data)
      </li>
      <li>
        social media companies, including Facebook and TikTok, in connection
        with Quchuxing’s use of their tools in Quchuxing’s apps and websites
        (see Facebook’s privacy policy and TikTok’s privacy policy for
        information on their collection and use of data) 
      </li>
      <li>
        marketing partners and marketing platform providers, including social
        media advertising services, advertising networks, third-party data
        providers, and other service providers to reach or better understand our
        users and measure advertising effectiveness
        <br /><br />
        <p>
          This includes advertising intermediaries, such
          as Google, Criteo and Rokt, that enable Quchuxing to display and
          measure the effectiveness of personalized ads for third party products
          that are displayed in Quchuxing’s apps. We share data such as
          advertising or device identifier, hashed email address, approximate
          location, and current trip or order information, and the
          intermediaries use this data to provide their services and such other
          purposes are disclosed in their privacy notices. Users may opt out
          from ad personalization in the Quchuxing app here . For more
          information regarding these intermediaries’ privacy practices,
          including how to submit requests to them relating to their handling of
          personal data, please see their privacy notices linked above.
        </p>
      </li>
      <li>
        research partners, including those performing surveys or research
        projects in partnership with Quchuxing or on Quchuxing’s behalf
      </li>
      <li>
        vendors that assist Quchuxing to enhance the safety and security of
        Quchuxing apps and services
      </li>
      <li>
        consultants, lawyers, accountants, and other professional
        service providers
      </li>
      <li>insurance and financing partners</li>
      <li>airports</li>
      <li>
        third-party vehicle suppliers, including fleet and rental partners
      </li>
    </ul>
    <br />
    <h4>7. For legal reasons or in the event of a dispute</h4>
    <p>
      Quchuxing may share users’ data if we believe it’s required by applicable
      law, regulation, operating license or agreement, legal process or
      governmental request, or where the disclosure is otherwise appropriate due
      to safety or similar concerns.
    </p>
    <br />
    <p>
      This includes sharing data with law enforcement officials, public health
      officials, other government authorities, airports (if required by the
      airport authorities as a condition of operating on airport property), or
      other third parties as necessary to enforce our Terms of Service, user
      agreements, or other policies; to protect Quchuxing’s rights or property
      or the rights, safety, or property of others; or in the event of a claim
      or dispute relating to the use of our services. In the event of a dispute
      relating to use of another person’s credit card, we may be required by law
      to share your data, including trip or order information, with the owner of
      that credit card.
    </p>
    <br />
    <p>
      This also includes sharing data with others in connection with, or during
      negotiations of, any merger, sale of company assets, consolidation or
      restructuring, financing, or acquisition of all or a portion of our
      business by or into another company.
    </p>
    <br />
    <h4>8. With consent</h4>
    <p>
      Quchuxing may share a user’s data other than as described in this notice
      if we notify the user and they consent to the sharing.
    </p>
    <br />
    <h3>E. Data retention and deletion</h3>
    <p>
      Quchuxing retains user data for as long as necessary for the purposes
      described above, including to provide its services and comply with legal
      obligations.
    </p>
    <br />
    <p>
      Quchuxing retains user data for as long as necessary for the purposes
      described above. The period for which we retain user data is determined by
      the type of data, the category of user to whom the data relates, and the
      purposes for which we collected the data.
    </p>
    <br />
    <p>
      The length for which Quchuxing retains user data may further be determined
      by legal and regulatory requirements, purposes of safety, security, and
      fraud prevention, or by issues relating to the user's account such as an
      outstanding credit or an unresolved claim or dispute.
    </p>
    <br />
    <p>For example, we retain data:</p>
    <ul>
      <li>
        for the life of users' accounts if such data is necessary to provide our
        services. E.g.user profile information and credentials.for 7 years if
        necessary to comply with tax requirements. E.g., drivers'and delivery
        persons'trip or delivery location information.
      </li>
      <li>
        for defined periods as necessary for purposes of safety or fraud
        prevention. E.g., we retain incomplete driver applications for 1 year,
        and rejected driver applications for 7 years.
      </li>
      <li>
        after requests for account deletion if necessary for purposes of safety,
        security, fraud prevention or compliance with legal requirements, or
        because of issues relating to the user’s account (such as an outstanding
        credit or an unresolved claim or dispute).
      </li>
    </ul>
    <br />
    <h4>Users may request deletion of their accounts at any time.</h4>
    <h4>
      Quchuxing may retain user data after a deletion request due to legal or
      regulatory requirements or for reasons stated in this policy.
    </h4>
    <br />
    <p>
      Users may request deletion of their account at any time through the
      Settings > in the Quchuxing app。
    </p>
    <br />
    <p>
      Following an account deletion request, Quchuxing deletes the user’s
      account and data, unless they must be retained due to legal or regulatory
      requirements, for purposes of safety, security, and fraud prevention, or
      because of an issue relating to the user’s account such as an outstanding
      credit or an unresolved claim or dispute. Because we are subject to legal
      and regulatory requirements relating to drivers and delivery persons, this
      generally means that we retain their account and data for the applicable
      statutory retention period after a deletion request. For riders and order
      recipients, their data is generally deleted within 90 days of a deletion
      request, except where retention is necessary for the above reasons.
    </p>
    <br />
    <h2>IV. Choice and transparency</h2>
    <p>
      Quchuxing enables users to access and/or control data that Quchuxing
      collects, including through: 
    </p>
    <ul>
      <li>privacy settings</li>
      <li>device permissions</li>
      <li>in-app ratings pages</li>
      <li>
        marketing choices <br /><br />
        <p>
          Quchuxing also enables users to request access to or copies of their
          data, make changes or updates to their accounts, request deletion of
          their accounts, or request that Quchuxing restrict its processing of
          user data.
        </p>
      </li>
    </ul>
    <br />
    <h4>A. Privacy settings</h4>
    <br />
    <p>
      The Settings > Privacy menu in the Quchuxing app allows riders and order
      recipients to set or update their preferences regarding location data
      collection and sharing, emergency data sharing, and notifications.
    </p>
    <ul>
      <li>
        Location data collection (riders and order recipients) <br /><br />
        <p>
          Riders and order recipients can enable/disable Quchuxing to collect
          location data from their mobile devices through their device settings,
          which can be accessed via the Settings > Privacy > Location menu. 
        </p>
      </li>
      <li>
        Share Live Location (Riders) <br /><br />
        <p>
          Riders can enable/disable Quchuxing to share their real-time location
          data from their mobile devices with their drivers through their device
          settings, which can be accessed via the Settings > Privacy > Location
          menu. 
        </p>
      </li>
      <li>
        Emergency Data Sharing
        <br /><br />
        <p>
          Riders may enable Quchuxing to share real-time location data from
          their mobile devices with emergency police, fire, and ambulance
          services. Such data may include approximate location at the time the
          emergency call was placed; the car’s make, model, color, and license
          plate information; the rider’s name and phone number; pickup and
          dropoff locations; and the driver’s name.
        </p>
        <p>
          Riders may enable/disable this feature via the Settings > Privacy >
          Location menu, or the Safety Center.
        </p>
        <p>
          Drivers and delivery persons can also enable/disable Emergency Data
          Sharing via the App settings > Emergency Data Sharing menu, or the
          Safety Toolkit.
        </p>
      </li>
      <li>
        Notifications: account and trip updates <br /><br />
        <p>
          Quchuxing provides users with trip status notifications and updates
          related to activity on their account. These notifications are a
          necessary part of using the Quchuxing app and cannot be disabled.
          However, users may choose the method by which they receive these
          notifications through the Settings > Privacy menu.
        </p>
      </li>
      <li>
        Notifications: discounts and news <br /><br />
        <p>
          Users may enable Quchuxing to send push notifications about discounts
          and news from Quchuxing. Push notifications may be enabled or disabled
          through the Settings > Privacy menus in the Quchuxing app.
        </p>
      </li>
    </ul>
    <br />
    <h4>B. Device permissions</h4>
    <p>
      Most mobile device platforms (iOS, Android, etc.) have defined certain
      types of device data that apps cannot access without the device owner’s
      permission, and these platforms have different methods for how that
      permission can be obtained. Please check the available settings on your
      device or check with your provider.
    </p>
    <br />
    <h4>C. In-app ratings pages</h4>
    <p>
      After every trip, drivers and riders are able to rate each other on a
      scale from 1 to 5. An average of those ratings is associated with a user’s
      account and is displayed to other users for whom they provide or receive
      services. For example, rider ratings are available to drivers from whom
      they request transportation, and driver ratings are available to their
      riders.
    </p>
    <br />
    <p>
      This 2-way system holds everyone accountable for their behavior.
      Accountability helps create a respectful, safe environment for drivers and
      riders.
    </p>
    <br />
    <p>
      Riders can see their average rating in the main menu of the Quchuxing app,
      and also access a breakdown of their average rating in Quchuxing’s Privacy
      Center.
    </p>
    <br />
    <p>
      Drivers can see their average rating after tapping their profile photo in
      the Quchuxing Driver app. Click here for more information.
    </p>
    <br />
    <h4>D. Marketing and advertising choices</h4>
    <p>
      Quchuxing provides users with the following choices regarding how their
      data is used for purposes of marketing and advertising:
    </p>
    <ul>
      <li>
        Personalized marketing communications from Quchuxing: Users may
        choose here whether Quchuxing may use their data to send personalized
        communications (such as, emails, push notifications, and in-app
        messages) about Quchuxing products and services. Users may also
        choose here whether to receive any marketing emails or push
        notifications from Quchuxing.
      </li>
      <li>
        Quchuxing ads: Users may choose here whether their data is used and
        shared with advertising partners to deliver personalized ads about
        Quchuxing products and services on third party apps and websites, or to
        measure the effectiveness of such ads.
      </li>
      <li>
        Third party ads: Users may choose here whether Quchuxing may use their
        data to display ads for third party products or services that are
        personalized based on users’ observed or inferred interests
        and characteristics.
      </li>
      <li>
        Cookies and related technologies: For information on how to control
        Quchuxing’s use of cookies and related technologies, including for
        purposes of displaying personalized ads, please see our Cookie Notice.
      </li>
    </ul>
    <br />
    <h4>E. User data requests</h4>
    <p>
      Quchuxing provides users with a variety of ways to learn about, control,
      and submit questions and comments about Quchuxing’s handling of
      their data.
    </p>
    <br />
    <ul>
      <li>
        <span style="font-weight: bold">Accessing data:</span>
        <span
          >Users can access data including their profile data and trip or order
          history through the Quchuxing apps or via Quchuxing’s website. Users
          can also use our Explore Your Data feature to view an online summary
          of information about their account, such as number of trips or orders,
          rating, rewards status, and number of days since they’ve been an
          Quchuxing user. Users can also request access to their data (riders
          and order recipients here; drivers and delivery persons here).</span
        >
      </li>
      <br />
      <li>
        <span style="font-weight: bold">Data portability: </span>
        <span
          >Users can request a copy of their data using our Download Your
          Data tool. For an overview of the data available through that tool,
          please click here. Users may also request a copy of their data (riders
          and order recipients here; drivers and delivery persons here).</span
        >
      </li>
      <br />
      <li>
        <span style="font-weight: bold">Changing or updating data: </span>
        <span
          >Users can edit the name, phone number, email address, payment method,
          and photo associated with their account through the Settings menu in
          Quchuxing’s apps or driver portal. Users may also request to update or
          correct their data (riders and order recipients here; drivers and
          delivery persons here).</span
        >
      </li>
      <br />
      <li>
        <span style="font-weight: bold">Deleting data: </span>
        <span
          >Users may request deletion of their account at any time through
          Quchuxing’s Privacy Center, or in the Settings > in the
          Quchuxing app.</span
        >
      </li>
      <br />
      <li>
        <span style="font-weight: bold"
          >Objections, restrictions, and complaints:
        </span>
        <span
          >Users may request that we stop using all or some of their data, or
          that we limit our use of their data (riders and order recipients here;
          drivers and delivery persons here). This includes objecting to our use
          of data that is based on Quchuxing’s legitimate interests. Quchuxing
          may continue to process data after such objection or request to the
          extent required or permitted by law.</span
        >
        <br /><br />
        <p>
          In addition, depending on their location, Users may have the right to
          file a complaint relating to Quchuxing’s handling of their data with
          the data protection authority in their country. For example, users in
          the European Union and South America may submit such requests to the
          data protection authorities in the country in which they live.
        </p>
      </li>
      <br />
      <li>
        <span style="font-weight: bold">Appeal: </span>
        <span
          >Users that reside in the U.S. may request a review of our decision in
          the event Quchuxing cannot meet or denies a User’s data request. Users
          may request an appeal of our decision here.</span
        >
      </li>
    </ul>
    <br />
    <h2>V. Updates to this notice</h2>
    <p>We may occasionally update this notice. </p>
    <p>
      We may occasionally update this notice. If we make significant changes, we
      will notify users in advance of the changes through the Quchuxing apps or
      through other means, such as email. We encourage users to periodically
      review this notice for the latest information on our privacy practices.
    </p>
    <p>
      Use of our services after an update constitutes consent to the updated
      notice to the extent permitted by law.
    </p>
    <br />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
ul {
  li {
    list-style: square;
    margin-left: 20px;
  }
}
</style>
