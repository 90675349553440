<template>
  <div class="me_order">
    <Header></Header>
    <div class="order_true">
      <p>我的订单</p>
    </div>

    <div v-if="orderData.length <= 0">
      <i
        class="iconfont icon-zanwushuju"
        style="
          text-align: center;
          display: block;
          line-height: 350px;
          font-size: 80px;
        "
      ></i>
    </div>
    <div class="me_mall_info" v-for="(item, i) in orderData" :key="i">
      <div v-if="item.goodsInfo != null || item.payOrderInfo != null">
        <div class="mall_info_box">
          <div style="display: flex; align-items: center">
            <div class="order_img">
              <img :src="item.goodsInfo.primaryImage" alt="" />
            </div>
            <p class="order_img_txt">{{ item.goodsInfo.goodsName }}</p>
          </div>
          <div class="mall_info_count">
            <p>${{ item.goodsInfo.goodsPrice }}</p>
            <p class="count_style">×{{ item.mallOrderInfo.goodsNum }}</p>
          </div>
        </div>
        <div class="me_mall_info_txt">
          实付 ${{ item.payOrderInfo.payType }}
        </div>
        <div class="me_mall_info_status">
          <p>
            {{ item.payOrderInfo.orderStatus }}
          </p>

          <div @click="handleDetail(item)">详情</div>
        </div>
      </div>
    </div>

    <Bottom></Bottom>
  </div>
</template>

<script>
import Header from "../header.vue";
import Bottom from "../bottom.vue";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";
import md5 from "js-md5";

export default {
  components: { Header, Bottom },
  data() {
    return {
      orderData: [],

      orderId: "",
      dateNew: "",
    };
  },

  mounted() {
    var num = "";
    for (var i = 0; i < 32; i++) {
      num += Math.floor(Math.random() * 10);
    }
    let md5Code = `${this.getTimeFn()}${num}cde89da976c98e74f156e5cf40f9dbf3`;
    console.log(md5Code);

    const opt = {
      url: reqUrl.mallOrderListAll,
      method: "POST",
      headers: {
        nonce: num,
        ts: this.dateNew,
        sign: md5(md5Code),
        wallet: localStorage.getItem("userName"),
        token: localStorage.getItem("token"),
      },
      params: {},
      resFunc: (res) => {
        if (res.data.code == 0) {
          this.orderData = res.data.data.list;

          res.data.data.list.map((item) => {
            this.changeItem(item);
          });
        }

        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
        }
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
      },
    };
    request(opt);
  },

  methods: {
    handleDetail(data) {
      if (data.payOrderInfo.orderStatus == "待支付") {
        this.$router.push({
          path: "/vaitPayOrder",
          query: {
            payTimeOut: data.payOrderInfo.payTimeout,
            type: 1,
          },
        });
      } else {
        this.$router.push({
          path: "/meOrder/detail",
          query: {
            orderId: data.mallOrderInfo.orderId,
          },
        });
      }
    },
    onCopy() {
      // this.$Message.success(this.$t("pledgePopup.copySuccess"));
      this.$Message.success("复制成功");
    },
    onError() {
      // this.$Message.error(this.$t("pledgePopup.copyFail"));
      this.$Message.error("复制失败");
    },

    changeItem(item) {
      let pay = item.payOrderInfo;
      if (pay != null) {
        if (pay.payType == 7) {
          pay.payType = pay.amount;
        } else {
          pay.payType = pay.p95Amount;
        }

        if (pay.orderStatus == 0) {
          pay.orderStatus = "待支付";
        } else if (pay.orderStatus == 1) {
          pay.orderStatus = "已支付";
        } else if (pay.orderStatus == 2) {
          pay.orderStatus = "订单超时关闭";
        } else if (pay.orderStatus == 3) {
          pay.orderStatus = "交易结束";
        } else if (pay.orderStatus == 4) {
          pay.orderStatus = "订单取消";
        }
      }
    },

    getTimeFn() {
      this.dateNew = new Date().getTime();
      return this.dateNew;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";

.mall_info_box {
  border: none;
}
</style>

<style>
.el-notification {
  z-index: 11111 !important;
}
</style>
