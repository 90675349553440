<template>
  <div class="road_show_detail_pledge">
    <div class="trading_head">
      <van-icon name="arrow-left" @click="handleBack" />
      <img
        src="@/assets/boxWallet/roadShow/logo2.png"
        @click="handleJoinGroupChat"
        alt=""
      />
    </div>

    <div class="detail_pledge_head">
      <div class="detail_pledge_head_box">
        <img src="@/assets/boxWallet/roadShow/12.png" alt="" />
        <p>正进行中</p>
      </div>
      <p>{{ pageData.closeDate }}结束</p>
    </div>

    <div class="road_show_detail_h">
      <div class="road_show_ent_head">
        <img :src="pageData.prjAvatarUrl" alt="" />
        <p>{{ pageData.prjName }}</p>
      </div>
      <div class="road_show_ent_txt">
        <p>总奖励</p>
        <span>{{ pageData.totalReward }}</span>
      </div>
      <div class="road_show_ent_txt">
        <p>挖币周期</p>
        <span>{{ pageData.poolBlock }}</span>
      </div>
      <div class="road_show_ent_txt">
        <p>距离挖币结束还剩</p>
        <span>{{ pageData.poolCountDown }}</span>
      </div>
    </div>

    <div class="road_show_detail_h" v-if="userInfoAmount > 0">
      <div class="road_show_ent_txt" style="margin-top: 0">
        <p>我的质押</p>
        <span>{{ userInfoAmount }}</span>
      </div>
      <div class="road_show_ent_txt">
        <p>质押时长</p>
        <span>{{ userInfoRewardBlock }}</span>
      </div>
      <div class="road_show_ent_txt">
        <p>已领取奖励</p>
        <span>{{ rewardOk }}</span>
      </div>
      <van-button
        type="primary"
        style="margin-top: 10px"
        @click="showRelease = true"
        >申请解押</van-button
      >
    </div>

    <div class="road_show_detail_tab">
      <van-tabs
        animated
        color="unset"
        class="tabsCapsule"
        background="#ffffff1a"
        v-model="active"
      >
        <van-tab title="路演">
          <div v-if="!isPledge">
            <div v-for="(item, i) in qaList" :key="i">
              <div>
                <div class="show_again_dis_prj">
                  <img src="@/assets/boxWallet/roadShow/head.png" alt="" />
                  <div>
                    <h4>主持人：</h4>
                    <div class="dis_prj_box">
                      {{ item.item }}
                    </div>
                  </div>
                </div>

                <div
                  class="show_again_dis_prj"
                  v-for="(answerItem, ansIdx) in item.answerList"
                  :key="ansIdx"
                >
                  <img :src="pageData.prjAvatarUrl" alt="" />
                  <div style="width: 85%">
                    <h4>{{ pageData.prjName }}</h4>
                    <div>
                      <audio
                        style="width: 100%"
                        ref="audioPlayer"
                        :src="answerItem.url"
                        @ended="onEnded(item.answerList, ansIdx)"
                        v-if="answerItem.type == 5"
                        controls
                      ></audio>
                      <img
                        :src="answerItem.url"
                        alt=""
                        v-else-if="answerItem.type == 9"
                        style="width: 100px; height: 100px; border-radius: 0"
                      />
                      <p v-else-if="answerItem.type == 8">
                        {{ answerItem.url }}
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  class="show_btn"
                  v-if="qaList.length - 1 === i && qaList2.length - 1 !== i"
                >
                  <van-button round disabled v-if="!isAutoPlay"
                    >播放录音，继续路演</van-button
                  >

                  <van-button
                    type="primary"
                    round
                    @click="handleAgain"
                    v-if="isAutoPlay"
                  >
                    我已清晰，继续路演
                  </van-button>
                </div>
                <div class="show_again_dis" v-else-if="qaList.length - 1 !== i">
                  <div>我已清晰，继续路演</div>
                  <img src="@/assets/boxWallet/roadShow/head.png" alt="" />
                </div>

                <div class="show_btn" v-else>
                  <van-button
                    type="primary"
                    v-if="isAnswerReward == 1"
                    round
                    @click="handleGoAnswer"
                    >完成答题 领取奖励</van-button
                  >
                  <van-button round disabled v-if="isAnswerReward == 3"
                    >奖励已获得（+{{ points }}）</van-button
                  >
                </div>
              </div>
            </div>
          </div>

          <div v-if="isPledge" style="margin-top: 50px">
            <div class="no_pledge_dis">
              <img src="@/assets/boxWallet/roadShow/lock.png" alt="" />
              <h3>路演互动</h3>
              <p class="no_pledge_dis_reward">
                奖励{{ pageData.lyReward }}CPLE
              </p>
              <p class="no_pledge_dis_people">
                剩{{ pageData.lyTotal - pageData.lyUsed }}位
              </p>
            </div>
            <div class="no_pledge_dis2">
              <p style="margin: 20px 0 5px 0; font-size: 12px">
                需质押数量：{{ pageData.upgradeDepositAmount }}CPLE
              </p>
              <p style="margin-top: 0; font-size: 12px">
                需质押时长：{{ pageData.upgradeDepositBlock }}
              </p>
              <van-button
                type="primary"
                @click="showPledgeNormal = true"
                v-if="pageData.upgradeDepositAmount <= 0"
                >质押</van-button
              >
              <van-button type="primary" v-if="roadShowPeople <= 0"
                >名额已满</van-button
              >
              <van-button
                type="primary"
                v-else-if="pageData.upgradeDepositAmount > 0"
                >{{ pageData.upgradeDepositBlock }}后解锁</van-button
              >
            </div>
          </div>
        </van-tab>
        <van-tab title="项目信息">
          <div class="detail_tab-project_info">
            <h4>项目简介</h4>
            <p>{{ pageData.prjIntro }}</p>
            <div>
              <van-tag
                plain
                type="primary"
                v-for="(item, i) in tagList"
                @click="handleTagList(item)"
                :key="i"
                >{{ item }}</van-tag
              >
            </div>
          </div>

          <div class="detail_tab-project_info">
            <h4>奖池详情</h4>

            <div class="road_show_ent_reward">
              <div class="reward_routine">
                <div style="display: flex">
                  <img src="@/assets/boxWallet/roadShow/green.png" alt="" />
                  <div class="reward_routine_r">
                    <p class="reward_routine_r_txt1">
                      常规质押奖池 {{ pageData.totalReward }}
                    </p>
                    <p class="reward_routine_r_txt2" style="font-size: 12px">
                      存入总量：{{ pageData.totalDeposit }}
                    </p>
                  </div>
                </div>
                <div v-if="userInfoAmount > 0"></div>
                <van-button type="primary" v-else>质押</van-button>
              </div>
              <div class="reward_routine-vait" v-if="userInfoAmount > 0">
                <p style="font-size: 14px">
                  待领取奖励：{{ pageData.userInfoReward }}
                </p>
                <van-button type="primary" @click="handleGetReward"
                  >领取</van-button
                >
              </div>
            </div>

            <div class="road_show_ent_reward" style="margin-bottom: 20px">
              <div class="reward_routine">
                <div style="display: flex">
                  <img src="@/assets/boxWallet/roadShow/yellow.png" alt="" />
                  <div class="reward_routine_r">
                    <p class="reward_routine_r_txt1">
                      升级行为奖池 {{ pageData.totalReward }}
                    </p>
                    <p
                      class="reward_routine_r_txt3"
                      style="margin-top: 5px !important; font-size: 12px"
                    >
                      需质押数量：{{ pageData.upgradeDepositAmount }}CPLE
                    </p>
                    <p class="reward_routine_r_txt3" style="font-size: 12px">
                      需质押时长：{{ pageData.upgradeDepositBlock }}
                    </p>
                  </div>
                </div>
                <van-button
                  type="primary"
                  v-if="userInfoAmount < pageData.upgradeDepositAmount"
                  @click="showPledgeNormal = true"
                  >质押</van-button
                >
                <van-button
                  type="primary"
                  v-if="pageData.upgradeDepositBlock == '0天'"
                  disabled
                  >{{ pageData.upgradeDepositBlock }}后解锁</van-button
                >
              </div>
              <div>
                <div v-if="userInfoAmount >= pageData.upgradeDepositAmount">
                  <div class="reward_one">
                    <div class="reward_one_l">
                      <img src="@/assets/boxWallet/roadShow/one.png" alt="" />
                      <div class="reward_one_show">
                        <p style="font-size: 14px">路演互动</p>
                        <p>
                          <span class="reward_one_show_txt1"
                            >+{{ pageData.lyReward }}</span
                          >
                          <span class="reward_one_show_txt2"
                            >剩{{ pageData.lyTotal - pageData.lyUsed }}位</span
                          >
                        </p>
                      </div>
                    </div>
                    <van-button
                      type="primary"
                      @click="handleGoRoadShow"
                      v-if="isAnswerReward == 1"
                      >去完成</van-button
                    >
                    <van-button
                      type="primary"
                      disabled
                      v-if="roadShowPeople <= 0"
                      >名额已满</van-button
                    >
                    <van-button
                      type="primary"
                      disabled
                      v-if="isAnswerReward == 3"
                      >已获得</van-button
                    >
                  </div>
                  <div class="reward_one">
                    <div class="reward_one_l">
                      <img src="@/assets/boxWallet/roadShow/two.png" alt="" />
                      <div class="reward_one_show">
                        <p style="font-size: 14px">加入项目群</p>
                        <p>
                          <span class="reward_one_show_txt1"
                            >+{{ pageData.groupReward }}</span
                          >
                          <span class="reward_one_show_txt2"
                            >剩{{
                              pageData.groupTotal - pageData.groupUsed
                            }}位</span
                          >
                        </p>
                      </div>
                    </div>
                    <van-button type="primary" @click="handleJoinGroupChat"
                      >加入</van-button
                    >
                  </div>
                </div>
                <div v-if="userInfoAmount < pageData.upgradeDepositAmount">
                  <div class="reward_one">
                    <div class="reward_one_l">
                      <img src="@/assets/boxWallet/roadShow/one.png" alt="" />
                      <div class="reward_one_show">
                        <p style="font-size: 14px">路演互动</p>
                      </div>
                    </div>
                    <p>
                      <span class="reward_one_show_txt1"
                        >+{{ pageData.lyReward }}</span
                      >
                      <span class="reward_one_show_txt2"
                        >剩{{ pageData.lyTotal - pageData.lyUsed }}位</span
                      >
                    </p>
                  </div>
                  <div class="reward_one" style="border: none">
                    <div class="reward_one_l">
                      <img src="@/assets/boxWallet/roadShow/two.png" alt="" />
                      <div class="reward_one_show">
                        <p style="font-size: 14px">加入项目群</p>
                      </div>
                    </div>
                    <div>
                      <span class="reward_one_show_txt1"
                        >+{{ pageData.groupReward }}</span
                      >
                      <span class="reward_one_show_txt2"
                        >剩{{
                          pageData.groupTotal - pageData.groupUsed
                        }}位</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </van-tab>
      </van-tabs>

      <!-- 答题 -->
      <van-popup
        v-model="showAnswer"
        round
        closeable
        position="bottom"
        :style="{ height: '90%' }"
      >
        <div class="detail_show_answer">完成答题 领取奖励</div>
        <h2>选择题</h2>
        <p>
          {{ questionDesc }}
        </p>
        <p class="detail_show_answer_select">请选择</p>
        <div>
          <van-radio-group v-model="answerRadioValue" @change="handleChange">
            <van-radio
              checked-color="#00BD4B"
              v-for="item in answerRadio"
              :key="item.id"
              :name="item.optionDesc"
              >{{ item.optionDesc }}</van-radio
            >
          </van-radio-group>
          <div class="show_btn">
            <van-button
              type="primary"
              round
              @click="handleAnswer"
              :disabled="!isShowAnswer"
              >确认</van-button
            >
          </div>
        </div>
      </van-popup>

      <!-- 回答正确 -->
      <van-popup
        v-model="showAnswerCorrect"
        round
        closeable
        position="bottom"
        :style="{ height: '48%' }"
      >
        <div class="detail_show_answer—correct">
          <img src="@/assets/boxWallet/roadShow/correct.png" alt="" />
          <p class="detail_show_answer—correct_num">+{{ points }}</p>
          <h2>回答正确，已获得奖励</h2>
          <div class="show_btn">
            <van-button round @click="handleAnswerResult('correct')"
              >知道了</van-button
            >
          </div>
        </div>
      </van-popup>

      <!-- 回答错误 -->
      <van-popup
        v-model="showAnswerError"
        round
        closeable
        position="bottom"
        :style="{ height: '30%' }"
      >
        <div class="detail_show_answer—error">
          <img src="@/assets/boxWallet/roadShow/error.png" alt="" />
          <h2>回答错误，无奖励</h2>
          <div class="show_btn">
            <van-button round @click="handleAnswerResult('error')"
              >知道了</van-button
            >
          </div>
        </div>
      </van-popup>

      <!-- 领取奖励按钮 -->
      <van-popup
        v-model="showGetReward"
        round
        closeable
        position="bottom"
        :style="{ height: '48%' }"
      >
        <div class="detail_show_answer—correct">
          <img src="@/assets/boxWallet/roadShow/correct.png" alt="" />
          <p class="detail_show_answer—correct_num">
            +{{ pageData.userInfoReward }}
          </p>
          <h2>回答正确，已获得奖励</h2>
          <div class="show_btn">
            <van-button round @click="showGetReward = false">知道了</van-button>
          </div>
        </div>
      </van-popup>

      <!-- 加入群聊 -->
      <van-popup v-model="showJoinGroupChat" closeable round>
        <div class="join_group_chat">
          <h2>加入群聊</h2>
          <p>请下载趣出行App</p>
          <img src="@/assets/boxWallet/logo4.png" alt="" />
          <div class="pay_call" style="margin: 0 auto" @click="handleDownLoad">
            跳转下载链接
          </div>
        </div>
      </van-popup>

      <!-- 申请解押 -->
      <van-popup
        v-model="showRelease"
        round
        position="bottom"
        :style="{ height: '20%' }"
      >
        <div class="detail_show_answer—error">
          <h2>确定申请解押吗？</h2>
          <div class="show_btn" style="justify-content: space-between">
            <van-button
              round
              @click="showRelease = false"
              style="margin-top: 10px"
              >取消</van-button
            >
            <van-button round type="danger" @click="handleRelease"
              >申请解押</van-button
            >
          </div>
        </div>
      </van-popup>

      <!-- 还有？天可升级行为奖池的申请解押操作 -->
      <van-popup
        v-model="showReleaseTime"
        round
        position="bottom"
        :style="{ height: '20%' }"
      >
        <div class="detail_show_answer—error">
          <h2>
            还有{{ pageData.upgradeDepositBlock }}天即可解锁升级行为奖池，
            解押后将失去进度。
          </h2>
          <div class="show_btn">
            <van-button
              round
              @click="showReleaseTime = false"
              style="margin-top: 10px"
              >取消</van-button
            >
            <van-button round type="danger" @click="handleReleaseTime"
              >申请解押</van-button
            >
          </div>
        </div>
      </van-popup>

      <!-- 质押 -->
      <van-popup
        v-model="showPledgeNormal"
        round
        closeable
        position="bottom"
        :style="{ height: '65%' }"
      >
        <div class="detail_show_pledge">
          <div>
            <div class="detail_show_pledge_title">存入CPLE</div>
            <p>您的 CPLE 将从现货账户中扣除，并冻结在币安宝账户中。</p>
            <p>满足以下条件可解锁升级行为奖池,获取更多奖励.</p>

            <div class="reward_routine" style="padding: 0 15px">
              <div style="display: flex">
                <img src="@/assets/boxWallet/roadShow/green.png" alt="" />
                <div class="reward_routine_r">
                  <p class="reward_routine_r_txt1" style="margin-top: 0">
                    常规质押奖池 {{ pageData.poolTotalDeposit }}
                  </p>
                  <p class="reward_routine_r_txt2" style="font-size: 12px">
                    存入总量：{{ pageData.totalDeposit }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="detail_show_pledge_user">
            <p>现货账户</p>
            <p><span>未知 </span>CPLE 可用</p>
          </div>

          <div class="detail_show_pledge_num">
            <van-field
              v-model="cpleNum"
              type="digit"
              placeholder="请输入质押数量"
            />CPLE
          </div>
          <div class="show_btn" style="justify-content: space-between">
            <van-button round @click="handleShowPledge">取消</van-button>
            <van-button
              round
              type="primary"
              :disabled="cpleNum == ''"
              @click="handleSubmit"
              >存入</van-button
            >
          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import md5 from "js-md5";
import Decimal from "decimal.js";
import {
  userInfoAmount,
  userPendingReward,
  upgradeDeposit,
  userWithDraw,
  depositPledge,
} from "@/service/road_show";

import { reqProject } from "@/api/apiUrl";
import { request } from "@/api/request";
import { showLoading, hideLoading } from "@/util/loading";
import { formatDuration, format_time_date_gang } from "@/util/funBusiness";

Decimal.set({
  precision: 20, // 设置精度
  rounding: Decimal.ROUND_DOWN, // 设置舍入模式
});

export default {
  data() {
    return {
      active: 2,
      isPledge: false,
      isAutoPlay: false,
      audiosPlayed: 0,

      tagList: ["官网", "白皮书", "研究报告", "视频介绍", "规则详情"],

      showAnswer: false,
      isShowAnswer: false, // 选中后开启
      answerList: [],
      answerRadioValue: "",
      answerRadio: [],
      answerRadioSelected: {}, // 选中项
      questionDesc: "", // 问题

      showAnswerCorrect: false, // 回答正确弹窗
      showAnswerError: false, // 回答错误弹窗
      isAnswerReward: 1, // 奖励已获得按钮
      points: "", // 奖励已获得-积分数量

      showPledgeNormal: false, // 质押操作
      cpleNum: "",
      showGetReward: false, // 领取奖励
      showJoinGroupChat: false, // 加入群聊
      showRelease: false, // 申请解押
      showReleaseTime: false, // 还有？天可升级行为奖池的申请解押操作

      roadShowPeople: 0, // 路演互动剩余人数

      dateNew: "",

      pageData: {},

      userInfoAmount: "", // 常规质押-用户存入总量
      rewardOk: "", // 已领取奖励
      userInfoRewardBlock: "", // 质押时长

      qaList: [], // 问答列表
      qaList2: [], //
      qaNum: 0,
    };
  },

  mounted() {
    this.pageData = JSON.parse(localStorage.getItem("readyDetail"));
    this.pageData.closeDate = format_time_date_gang(this.pageData.closeDate);

    // 判断路演状态
    let total = this.pageData.lyTotal - this.pageData.lyUsed;
    if (
      total > 0 &&
      this.userInfoAmount > this.pageData.upgradeDepositAmount &&
      this.pageData.upgradeDepositAmount <= 0
    ) {
      this.isPledge = false;
    } else {
      this.isPledge = true;
    }

    this.roadShowPeople = this.pageData.lyTotal - this.pageData.lyUsed;

    if (this.pageData.upgradeDepositBlock == "0天") {
      this.isAnswerReward = 3;
    }

    userInfoAmount(this.pageData.poolPid).then((res) => {
      const a = new Decimal(res.amount);
      const numa = new Decimal(1e18);
      const resulta = a.div(numa);
      this.userInfoAmount = resulta.toString();

      const b = new Decimal(res.rewardDebt);
      const numb = new Decimal(1e18);
      const resultb = b.div(numb);
      this.rewardOk = resultb.toString();

      this.userInfoRewardBlock = formatDuration(
        (res.endBlock - res.startBlock) * 2 * 1000
      );
    });

    showLoading();
    var num = "";
    for (var i = 0; i < 32; i++) {
      num += Math.floor(Math.random() * 10);
    }
    let md5Code = `0${this.getTimeFn()}${num}F1B0254526A121F0E56B88434DC3783041665D8A`;
    const optQaList = {
      url: reqProject.prjInfoQaList,
      method: "POST",
      params: JSON.stringify({
        prjId: this.$route.query.prjId,
      }),
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        nonce: num,
        ts: this.dateNew,
        sign: md5(md5Code),
        token: localStorage.getItem("token"),
      },
      resFunc: (res) => {
        if (res.data.code == 0) {
          hideLoading();
          console.log(res.data);

          this.qaList2 = res.data.data.list;
          this.qaList.push(this.qaList2[this.qaNum]);
        }

        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        }
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
        hideLoading();
      },
    };
    request(optQaList);
  },

  watch: {
    answerRadioValue: function (newVal, oldVal) {
      if (newVal !== "") {
        this.isShowAnswer = true;
      }
    },
  },

  methods: {
    handleBack() {
      this.$router.push("/roadShow");
      localStorage.removeItem("readyDetail");
    },

    // 去答题
    handleGoAnswer() {
      this.showAnswer = true;

      var num = "";
      for (var i = 0; i < 32; i++) {
        num += Math.floor(Math.random() * 10);
      }
      let md5Code = `0${this.getTimeFn()}${num}F1B0254526A121F0E56B88434DC3783041665D8A`;
      console.log(md5Code, "md5Code");

      const opt = {
        url: reqProject.getQuestionList,
        method: "POST",
        headers: {
          nonce: num,
          ts: this.dateNew,
          sign: md5(md5Code),
          token: localStorage.getItem("token"),
        },
        params: {
          prjId: this.pageData.id,
          // prjId: 1,
        },
        resFunc: (res) => {
          if (res.data.code == 0) {
            this.answerList = res.data.data.list;
            this.questionDesc = this.answerList[0].questionDesc;
            this.answerRadio = this.answerList[0].optionList;
            console.log(this.answerList[0], 999);
          }

          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
          }
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          console.log(err, "err1");
        },
      };
      request(opt);
    },

    /// 领取奖励按钮
    handleGetReward() {
      depositPledge(this.poolPid, 0).then((res) => {
        console.log("领取奖励：", res);
        this.showGetReward = true;
      });
    },

    handleGoRoadShow() {
      this.active = 2;
      this.isPledge = false;
    },

    handleAgain() {
      this.qaNum = ++this.qaNum;
      this.qaList.push(this.qaList2[this.qaNum]);
      this.isAutoPlay = false;
    },

    handleChange(value) {
      const selected = this.answerRadio.find(
        (item) => item.optionDesc === value
      );
      this.answerRadioSelected = selected;
    },

    // 完成答题
    handleAnswer() {
      var num = "";
      for (var i = 0; i < 32; i++) {
        num += Math.floor(Math.random() * 10);
      }
      let md5Code = `0${this.getTimeFn()}${num}F1B0254526A121F0E56B88434DC3783041665D8A`;

      const questionList = [
        {
          optionList: [this.answerRadioSelected.id],
          questionId: this.answerRadioSelected.questionId,
        },
      ];

      const opt = {
        url: reqProject.answerPrjQuestion,
        method: "POST",
        headers: {
          nonce: num,
          ts: this.dateNew,
          sign: md5(md5Code),
          token: localStorage.getItem("token"),
        },
        params: {
          prjId: this.pageData.id,
          questionList: questionList,
        },
        resFunc: (res) => {
          if (res.data.code == 0) {
            if (res.data.data.isValid) {
              this.showAnswerCorrect = true;
            } else {
              this.showAnswerError = true;
            }
            this.points = res.data.data.points;
          }

          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
          }
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          console.log(err, "err1");
        },
      };
      request(opt);
    },

    // 答题结果
    handleAnswerResult(result) {
      if (result == "correct") {
        this.showAnswerCorrect = false;
        this.isAnswerReward = 3;
        this.showAnswer = false;
      } else {
        this.showAnswerError = false;
        this.showAnswer = false;
      }
    },

    // 加入群聊
    handleJoinGroupChat() {
      if (this.isPledge) {
        this.$toast({
          message: "完成路演活动，即可加入群聊",
          overlay: true,
          position: "top",
        });
      } else {
        this.showJoinGroupChat = true;
      }
    },

    // 点击标签
    handleTagList(value) {
      console.log(value);
      if (value == "官网") {
        window.open(this.pageData.prjWebsite);
      } else if (value == "白皮书") {
        window.open(this.pageData.prjWhitepaper);
      } else if (value == "研究报告") {
        window.open(this.pageData.prjReport);
      } else if (value == "视频介绍") {
        window.open(this.pageData.prjVideoIntro);
      } else if (value == "规则详情") {
        window.open(this.pageData.prjDetailRule);
      }
    },

    onEnded(list, index) {
      this.audiosPlayed++;
      if (this.audiosPlayed === list.length) {
        this.isAutoPlay = true; // 所有音频播放完毕，设置isAuto为true
      }
    },

    handleDownLoad() {
      downLoadAppFn();
    },

    // 存入
    handleSubmit() {
      depositPledge(this.pageData.poolPid, this.cpleNum).then((res) => {
        console.log("领取奖励", res);

        this.$toast({
          type: "success",
          message: "存入成功",
          overlay: true,
        });
      });
    },

    // 取消
    handleShowPledge() {
      this.showPledgeNormal = false;
      this.cpleNum = "";
    },

    // 解押
    handleRelease() {
      userWithDraw(this.pageData.poolPid, this.userInfoAmount).then((res) => {
        console.log("解除质押", res);
        this.showRelease = false;
        this.$toast({
          type: "success",
          message: "解押成功",
          overlay: true,
        });
      });
    },

    handleReleaseTime() {
      userWithDraw(this.pageData.poolPid, this.userInfoAmount).then((res) => {
        console.log("解除质押", res);
        this.showReleaseTime = false;
        this.$toast({
          type: "success",
          message: "解押成功",
          overlay: true,
        });
      });
    },

    getTimeFn() {
      this.dateNew = new Date().getTime();
      return this.dateNew;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../all.scss";
@import "./index.scss";
@import "../trading/index.scss";
</style>
