<template>
  <div>
    <gmap-map
      :center="centers"
      :zoom="11"
      map-type-id="terrain"
      style="width: 100%; height: 340px"
    >
      <gmap-marker
        @dragend="updateMaker"
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        :clickable="true"
        :draggable="true"
        @click="centers = m.position"
      ></gmap-marker>

      <!-- @click="centers=m.position" -->
    </gmap-map>
  </div>
</template>

<script>
export default {
  data() {
    return {
      centers: { lat: 39.90419989999999, lng: 116.4073963 },

      markers: [
        {
          position: { lat: 39.90419989999999, lng: 116.4073963 },
        },
      ],

      place: null,
    };
  },

  description: "Autocomplete Example (#164)",

  mounted() {
    // this.initMap();
  },

  methods: {
    // initMap() {
    //   var map = new google.maps.Map(document.getElementById("map"), {
    //     mapTypeControl: false,
    //     center: { lat: -43.712724, lng: 124.873256 },
    //     zoom: 17,
    //   });
    //   var infowindow = new google.maps.InfoWindow();
    //   var service = new google.maps.places.PlacesService(map);

    //   // service.getDetails(request, function(place, status) {
    //   // 	if (status === google.maps.places.PlacesServiceStatus.OK) {
    //   var marker = new google.maps.Marker({
    //     map: map,
    //     position: new google.maps.LatLng(-43.712724, 124.873256),
    //   });
    //   google.maps.event.addListener(marker, "click", function () {
    //     infowindow.setContent(
    //       "<div>" + "17 Silicon Pl, Tullamarine VIC 3043, Australia" + "</div>"
    //     );
    //     infowindow.open(map, this);
    //   });
    //   // }
    //   // });

    //   new AutocompleteDirectionsHandler(map);
    // },

    setPlace(place) {
      this.place = place;
    },

    setDescription(description) {
      this.description = description;
    },

    usePlace(place) {
      if (this.place) {
        var newPostion = {
          lat: this.place.geometry.location.lat(),

          lng: this.place.geometry.location.lng(),
        };

        this.center = newPostion;

        this.markers[0].position = newPostion;

        var line = new google.maps.Polyline({
          path: [
            new google.maps.LatLng(data.startlat, data.startlong),
            new google.maps.LatLng(data.endlat, data.endlong),
          ],
          strokeColor: "#FF0000",
          strokeOpacity: 1.0,
          strokeWeight: 10,
          map: map,
        });
        line.setMap(map);
      }
    },

    updateMaker: function (event) {
      console.log("updateMaker, ", event.latLng.lat());

      this.markers[0].position = {
        lat: event.latLng.lat(),

        lng: event.latLng.lng(),
      };
    },
  },
};
</script>
