var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pay_success"},[_c('Header'),_vm._m(0),_vm._m(1),_c('div',{staticClass:"mall_info"},[_c('div',{staticClass:"font_txt"},[_c('p',{staticClass:"font_txt1"},[_vm._v("订单编号")]),_c('p',[_vm._v("221114-150266254862672")]),_c('i',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(_vm.message),expression:"message",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.onCopy),expression:"onCopy",arg:"success"},{name:"clipboard",rawName:"v-clipboard:error",value:(_vm.onError),expression:"onError",arg:"error"}],staticClass:"iconfont icon-fuzhi"})]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)]),_c('div',{staticClass:"pay_success_b"},[_vm._v("订单详情")]),_c('Bottom')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"order_true"},[_c('p',[_vm._v("支付完成（5月发货）")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mall_info"},[_c('div',{staticClass:"mall_info_box"},[_c('div',[_c('div',{staticClass:"order_img"},[_c('img',{attrs:{"src":require("@/assets/boxWallet/box.png"),"alt":""}})]),_c('p',{staticClass:"order_img_txt"},[_vm._v("趣宝盒趣宝盒")])]),_c('div',{staticClass:"mall_info_count"},[_c('p',[_vm._v("$319.00")]),_c('p',{staticClass:"count_style"},[_vm._v("×1")])])]),_c('div',[_c('p',{staticClass:"font_txt1"},[_vm._v("商品总价")]),_c('p',[_vm._v("$319.00")])]),_c('div',[_c('p',{staticClass:"font_txt1"},[_vm._v("CPLE积分抵扣 (5%)")]),_c('p',[_vm._v("-$18")])]),_c('div',{staticStyle:{"margin-bottom":"10px"}},[_c('p',{staticClass:"font_txt1"},[_vm._v("运费")]),_c('p',[_vm._v("$0.00")])]),_c('div',{staticClass:"mall_info_all"},[_c('p',[_vm._v("实付")]),_c('p',[_vm._v("$301.00")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"font_txt"},[_c('p',{staticClass:"font_txt1"},[_vm._v("下单时间")]),_c('p',[_vm._v("2022-11-14 19:53:36")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"font_txt"},[_c('p',{staticClass:"font_txt1"},[_vm._v("付款时间")]),_c('p',[_vm._v("2022-11-14 19:53:36")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"font_txt"},[_c('p',{staticClass:"font_txt1"},[_vm._v("支付方式")]),_c('p',[_vm._v("Solflare")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"font_txt"},[_c('p',{staticClass:"font_txt1"},[_vm._v("邀请码")]),_c('p',[_vm._v("123455")])])
}]

export { render, staticRenderFns }