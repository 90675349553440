const areaCodeData = [
  {
    name: "افغانستان",
    code: "AF",
    dial_code: "+93",
    url: require("@/assets/flags/af.png"),
  },
  {
    name: "Åland",
    code: "AX",
    dial_code: "+358",
    url: require("@/assets/flags/ax.png"),
  },
  {
    name: "Shqipëria",
    code: "AL",
    dial_code: "+355",
    url: require("@/assets/flags/al.png"),
  },
  {
    name: "الجزائر",
    code: "DZ",
    dial_code: "+213",
    url: require("@/assets/flags/dz.png"),
  },
  {
    name: "American Samoa",
    code: "AS",
    dial_code: "+1684",
    url: require("@/assets/flags/as.png"),
  },
  {
    name: "Andorra",
    code: "AD",
    dial_code: "+376",
    url: require("@/assets/flags/ad.png"),
  },
  {
    name: "Angola",
    code: "AO",
    dial_code: "+244",
    url: require("@/assets/flags/ao.png"),
  },
  {
    name: "Anguilla",
    code: "AI",
    dial_code: "+1264",
    url: require("@/assets/flags/ai.png"),
  },
  {
    name: "Antarctica",
    code: "AQ",
    dial_code: "+672",
    url: require("@/assets/flags/aq.png"),
  },
  {
    name: "Antigua and Barbuda",
    code: "AG",
    dial_code: "+1268",
    url: require("@/assets/flags/ag.png"),
  },
  {
    name: "Argentina",
    code: "AR",
    dial_code: "+54",

    url: require("@/assets/flags/ar.png"),
  },
  {
    name: "Հայաստան",
    code: "AM",
    dial_code: "+374",
    url: require("@/assets/flags/am.png"),
  },
  {
    name: "Aruba",
    code: "AW",
    dial_code: "+297",
    url: require("@/assets/flags/aw.png"),
  },
  {
    name: "Australia",
    code: "AU",
    dial_code: "+61",

    url: require("@/assets/flags/au.png"),
  },
  {
    name: "Österreich",
    code: "AT",
    dial_code: "+43",

    url: require("@/assets/flags/at.png"),
  },
  {
    name: "Azərbaycan",
    code: "AZ",
    dial_code: "+994",
    url: require("@/assets/flags/az.png"),
  },
  {
    name: "Bahamas",
    code: "BS",
    dial_code: "+1242",
    url: require("@/assets/flags/bs.png"),
  },
  {
    name: "‏البحرين",
    code: "BH",
    dial_code: "+973",
    url: require("@/assets/flags/bh.png"),
  },
  {
    name: "Bangladesh",
    code: "BD",
    dial_code: "+880",
    url: require("@/assets/flags/bd.png"),
  },
  {
    name: "Barbados",
    code: "BB",
    dial_code: "+1246",
    url: require("@/assets/flags/bb.png"),
  },
  {
    name: "Белару́сь",
    code: "BY",
    dial_code: "+375",
    url: require("@/assets/flags/by.png"),
  },
  {
    name: "België",
    code: "BE",
    dial_code: "+32",

    url: require("@/assets/flags/be.png"),
  },
  {
    name: "Belize",
    code: "BZ",
    dial_code: "+501",
    url: require("@/assets/flags/bz.png"),
  },
  {
    name: "Bénin",
    code: "BJ",
    dial_code: "+229",
    url: require("@/assets/flags/bj.png"),
  },
  {
    name: "Bermuda",
    code: "BM",
    dial_code: "+1441",
    url: require("@/assets/flags/bm.png"),
  },
  {
    name: "ʼbrug-yul",
    code: "BT",
    dial_code: "+975",
    url: require("@/assets/flags/bt.png"),
  },
  {
    name: "Bolivia",
    code: "BO",
    dial_code: "+591",
    url: require("@/assets/flags/bo.png"),
  },
  {
    name: "Bosna i Hercegovina",
    code: "BA",
    dial_code: "+387",
    url: require("@/assets/flags/ba.png"),
  },
  {
    name: "Botswana",
    code: "BW",
    dial_code: "+267",
    url: require("@/assets/flags/bw.png"),
  },
  {
    name: "Bouvetøya",
    code: "BV",
    dial_code: "+47",

    url: require("@/assets/flags/bv.png"),
  },
  {
    name: "Brasil",
    code: "BR",
    dial_code: "+55",
    url: require("@/assets/flags/br.png"),
  },
  {
    name: "British Indian Ocean Territory",
    code: "IO",
    dial_code: "+246",
    url: require("@/assets/flags/io.png"),
  },
  {
    name: "Negara Brunei Darussalam",
    code: "BN",
    dial_code: "+673",
    url: require("@/assets/flags/bn.png"),
  },
  {
    name: "България",
    code: "BG",
    dial_code: "+359",
    url: require("@/assets/flags/bg.png"),
  },
  {
    name: "Burkina Faso",
    code: "BF",
    dial_code: "+226",
    url: require("@/assets/flags/bf.png"),
  },
  {
    name: "Burundi",
    code: "BI",
    dial_code: "+257",
    url: require("@/assets/flags/bi.png"),
  },
  {
    name: "Cambodia",
    code: "KH",
    dial_code: "+855",
    url: require("@/assets/flags/kh.png"),
  },
  {
    name: "Cameroon",
    code: "CM",
    dial_code: "+237",
    url: require("@/assets/flags/cm.png"),
  },
  {
    name: "Canada",
    code: "CA",
    dial_code: "+1",

    url: require("@/assets/flags/ca.png"),
  },
  {
    name: "Cabo Verde",
    code: "CV",
    dial_code: "+238",
    url: require("@/assets/flags/cv.png"),
  },
  {
    name: "Cayman Islands",
    code: "KY",
    dial_code: "+1345",
    url: require("@/assets/flags/ky.png"),
  },
  {
    name: "Ködörösêse tî Bêafrîka",
    code: "CF",
    dial_code: "+236",
    url: require("@/assets/flags/cf.png"),
  },
  {
    name: "Tchad",
    code: "TD",
    dial_code: "+235",
    url: require("@/assets/flags/td.png"),
  },
  {
    name: "Chile",
    code: "CL",
    dial_code: "+56",

    url: require("@/assets/flags/cl.png"),
  },
  {
    name: "中国",
    code: "CN",
    dial_code: "+86",

    url: require("@/assets/flags/cn.png"),
  },
  {
    name: "Christmas Island",
    code: "CX",
    dial_code: "+61",

    url: require("@/assets/flags/cx.png"),
  },
  {
    name: "Cocos (Keeling) Islands",
    code: "CC",
    dial_code: "+61",

    url: require("@/assets/flags/cc.png"),
  },
  {
    name: "Colombia",
    code: "CO",
    dial_code: "+57",

    url: require("@/assets/flags/co.png"),
  },
  {
    name: "Komori",
    code: "KM",
    dial_code: "+269",
    url: require("@/assets/flags/km.png"),
  },
  {
    name: "République du Congo",
    code: "CG",
    dial_code: "+242",
    url: require("@/assets/flags/cg.png"),
  },
  {
    name: "République démocratique du Congo",
    code: "CD",
    dial_code: "+243",
    url: require("@/assets/flags/cd.png"),
  },
  {
    name: "Cook Islands",
    code: "CK",
    dial_code: "+682",
    url: require("@/assets/flags/ck.png"),
  },
  {
    name: "Costa Rica",
    code: "CR",
    dial_code: "+506",
    url: require("@/assets/flags/cr.png"),
  },
  {
    name: "Côte d'Ivoire",
    code: "CI",
    dial_code: "+225",
    url: require("@/assets/flags/ci.png"),
  },
  {
    name: "Hrvatska",
    code: "HR",
    dial_code: "+385",
    url: require("@/assets/flags/hr.png"),
  },
  {
    name: "Cuba",
    code: "CU",
    dial_code: "+53",

    url: require("@/assets/flags/cu.png"),
  },
  {
    name: "Κύπρος",
    code: "CY",
    dial_code: "+357",
    url: require("@/assets/flags/cy.png"),
  },
  {
    name: "Česká republika",
    code: "CZ",
    dial_code: "+420",
    url: require("@/assets/flags/cz.png"),
  },
  {
    name: "Danmark",
    code: "DK",
    dial_code: "+45",

    url: require("@/assets/flags/dk.png"),
  },
  {
    name: "Djibouti",
    code: "DJ",
    dial_code: "+253",
    url: require("@/assets/flags/dj.png"),
  },
  {
    name: "Dominica",
    code: "DM",
    dial_code: "+1767",
    url: require("@/assets/flags/dm.png"),
  },
  {
    name: "República Dominicana",
    code: "DO",
    dial_code: "+1",

    url: require("@/assets/flags/do.png"),
  },
  {
    name: "Ecuador",
    code: "EC",
    dial_code: "+593",
    url: require("@/assets/flags/ec.png"),
  },
  {
    name: "مصر‎",
    code: "EG",
    dial_code: "+20",

    url: require("@/assets/flags/eg.png"),
  },
  {
    name: "El Salvador",
    code: "SV",
    dial_code: "+503",
    url: require("@/assets/flags/sv.png"),
  },
  {
    name: "Guinea Ecuatorial",
    code: "GQ",
    dial_code: "+240",
    url: require("@/assets/flags/gq.png"),
  },
  {
    name: "ኤርትራ",
    code: "ER",
    dial_code: "+291",
    url: require("@/assets/flags/er.png"),
  },
  {
    name: "Eesti",
    code: "EE",
    dial_code: "+372",
    url: require("@/assets/flags/ee.png"),
  },
  {
    name: "ኢትዮጵያ",
    code: "ET",
    dial_code: "+251",
    url: require("@/assets/flags/et.png"),
  },
  {
    name: "Falkland Islands",
    code: "FK",
    dial_code: "+500",
    url: require("@/assets/flags/fk.png"),
  },
  {
    name: "Føroyar",
    code: "FO",
    dial_code: "+298",
    url: require("@/assets/flags/fo.png"),
  },
  {
    name: "Fiji",
    code: "FJ",
    dial_code: "+679",
    url: require("@/assets/flags/fj.png"),
  },
  {
    name: "Suomi",
    code: "FI",
    dial_code: "+358",
    url: require("@/assets/flags/fi.png"),
  },
  {
    name: "France",
    code: "FR",
    dial_code: "+33",

    url: require("@/assets/flags/fr.png"),
  },
  {
    name: "Guyane française",
    code: "GF",
    dial_code: "+594",
    url: require("@/assets/flags/gf.png"),
  },
  {
    name: "Polynésie française",
    code: "PF",
    dial_code: "+689",
    url: require("@/assets/flags/pf.png"),
  },
  {
    name: "Territoire des Terres australes et antarctiques fr",
    code: "TF",
    dial_code: "+262",
    url: require("@/assets/flags/tf.png"),
  },
  {
    name: "Gabon",
    code: "GA",
    dial_code: "+241",
    url: require("@/assets/flags/ga.png"),
  },
  {
    name: "Gambia",
    code: "GM",
    dial_code: "+220",
    url: require("@/assets/flags/gm.png"),
  },
  {
    name: "საქართველო",
    code: "GE",
    dial_code: "+995",
    url: require("@/assets/flags/ge.png"),
  },
  {
    name: "Deutschland",
    code: "DE",
    dial_code: "+49",

    url: require("@/assets/flags/de.png"),
  },
  {
    name: "Ghana",
    code: "GH",
    dial_code: "+233",
    url: require("@/assets/flags/gh.png"),
  },
  {
    name: "Gibraltar",
    code: "GI",
    dial_code: "+350",
    url: require("@/assets/flags/gi.png"),
  },
  {
    name: "Ελλάδα",
    code: "GR",
    dial_code: "+30",

    url: require("@/assets/flags/gr.png"),
  },
  {
    name: "Kalaallit Nunaat",
    code: "GL",
    dial_code: "+299",
    url: require("@/assets/flags/gl.png"),
  },
  {
    name: "Grenada",
    code: "GD",
    dial_code: "+1473",
    url: require("@/assets/flags/gd.png"),
  },
  {
    name: "Guadeloupe",
    code: "GP",
    dial_code: "+590",
    url: require("@/assets/flags/gp.png"),
  },
  {
    name: "Guam",
    code: "GU",
    dial_code: "+1671",
    url: require("@/assets/flags/gu.png"),
  },
  {
    name: "Guatemala",
    code: "GT",
    dial_code: "+502",
    url: require("@/assets/flags/gt.png"),
  },
  {
    name: "Guernsey",
    code: "GG",
    dial_code: "+44",

    url: require("@/assets/flags/gg.png"),
  },
  {
    name: "Guinée",
    code: "GN",
    dial_code: "+224",
    url: require("@/assets/flags/gn.png"),
  },
  {
    name: "Guiné-Bissau",
    code: "GW",
    dial_code: "+245",
    url: require("@/assets/flags/gw.png"),
  },
  {
    name: "Guyana",
    code: "GY",
    dial_code: "+592",
    url: require("@/assets/flags/gy.png"),
  },
  {
    name: "Haïti",
    code: "HT",
    dial_code: "+509",
    url: require("@/assets/flags/ht.png"),
  },
  {
    name: "Heard Island and McDonald Islands",
    code: "HM",
    dial_code: "+0",

    url: require("@/assets/flags/hm.png"),
  },
  {
    name: "Vaticano",
    code: "VA",
    dial_code: "+379",
    url: require("@/assets/flags/va.png"),
  },
  {
    name: "Honduras",
    code: "HN",
    dial_code: "+504",
    url: require("@/assets/flags/hn.png"),
  },
  {
    name: "香港",
    code: "HK",
    dial_code: "+852",
    url: require("@/assets/flags/hk.png"),
  },
  {
    name: "Magyarország",
    code: "HU",
    dial_code: "+36",

    url: require("@/assets/flags/hu.png"),
  },
  {
    name: "Ísland",
    code: "IS",
    dial_code: "+354",
    url: require("@/assets/flags/is.png"),
  },
  {
    name: "भारत",
    code: "IN",
    dial_code: "+91",

    url: require("@/assets/flags/in.png"),
  },
  {
    name: "Indonesia",
    code: "ID",
    dial_code: "+62",

    url: require("@/assets/flags/id.png"),
  },
  {
    name: "ایران",
    code: "IR",
    dial_code: "+98",

    url: require("@/assets/flags/ir.png"),
  },
  {
    name: "العراق",
    code: "IQ",
    dial_code: "+964",
    url: require("@/assets/flags/iq.png"),
  },
  {
    name: "Éire",
    code: "IE",
    dial_code: "+353",
    url: require("@/assets/flags/ie.png"),
  },
  {
    name: "Isle of Man",
    code: "IM",
    dial_code: "+44",

    url: require("@/assets/flags/im.png"),
  },
  {
    name: "ישראל",
    code: "IL",
    dial_code: "+972",
    url: require("@/assets/flags/il.png"),
  },
  {
    name: "Italia",
    code: "IT",
    dial_code: "+39",

    url: require("@/assets/flags/it.png"),
  },
  {
    name: "Jamaica",
    code: "JM",
    dial_code: "+1876",
    url: require("@/assets/flags/jm.png"),
  },
  {
    name: "日本",
    code: "JP",
    dial_code: "+81",

    url: require("@/assets/flags/jp.png"),
  },
  {
    name: "Jersey",
    code: "JE",
    dial_code: "+44",

    url: require("@/assets/flags/je.png"),
  },
  {
    name: "الأردن",
    code: "JO",
    dial_code: "+962",
    url: require("@/assets/flags/jo.png"),
  },
  {
    name: "Қазақстан",
    code: "KZ",
    dial_code: "+7",

    url: require("@/assets/flags/kz.png"),
  },
  {
    name: "Kenya",
    code: "KE",
    dial_code: "+254",
    url: require("@/assets/flags/ke.png"),
  },
  {
    name: "Kiribati",
    code: "KI",
    dial_code: "+686",
    url: require("@/assets/flags/ki.png"),
  },
  {
    name: "북한",
    code: "KP",
    dial_code: "+850",
    url: require("@/assets/flags/kp.png"),
  },
  {
    name: "대한민국",
    code: "KR",
    dial_code: "+82",

    url: require("@/assets/flags/kr.png"),
  },
  {
    name: "Republika e Kosovës",
    code: "XK",
    dial_code: "+383",
    url: require("@/assets/flags/xk.png"),
  },
  {
    name: "الكويت",
    code: "KW",
    dial_code: "+965",
    url: require("@/assets/flags/kw.png"),
  },
  {
    name: "Кыргызстан",
    code: "KG",
    dial_code: "+996",
    url: require("@/assets/flags/kg.png"),
  },
  {
    name: "ສປປລາວ",
    code: "LA",
    dial_code: "+856",
    url: require("@/assets/flags/la.png"),
  },
  {
    name: "Latvija",
    code: "LV",
    dial_code: "+371",
    url: require("@/assets/flags/lv.png"),
  },
  {
    name: "لبنان",
    code: "LB",
    dial_code: "+961",
    url: require("@/assets/flags/lb.png"),
  },
  {
    name: "Lesotho",
    code: "LS",
    dial_code: "+266",
    url: require("@/assets/flags/ls.png"),
  },
  {
    name: "Liberia",
    code: "LR",
    dial_code: "+231",
    url: require("@/assets/flags/lr.png"),
  },
  {
    name: "‏ليبيا",
    code: "LY",
    dial_code: "+218",
    url: require("@/assets/flags/ly.png"),
  },
  {
    name: "Liechtenstein",
    code: "LI",
    dial_code: "+423",
    url: require("@/assets/flags/li.png"),
  },
  {
    name: "Lietuva",
    code: "LT",
    dial_code: "+370",
    url: require("@/assets/flags/lt.png"),
  },
  {
    name: "Luxembourg",
    code: "LU",
    dial_code: "+352",
    url: require("@/assets/flags/lu.png"),
  },
  {
    name: "澳門",
    code: "MO",
    dial_code: "+853",
    url: require("@/assets/flags/mo.png"),
  },
  {
    name: "Македонија",
    code: "MK",
    dial_code: "+389",
    url: require("@/assets/flags/mk.png"),
  },
  {
    name: "Madagasikara",
    code: "MG",
    dial_code: "+261",
    url: require("@/assets/flags/mg.png"),
  },
  {
    name: "Malawi",
    code: "MW",
    dial_code: "+265",
    url: require("@/assets/flags/mw.png"),
  },
  {
    name: "Malaysia",
    code: "MY",
    dial_code: "+60",

    url: require("@/assets/flags/my.png"),
  },
  {
    name: "Maldives",
    code: "MV",
    dial_code: "+960",
    url: require("@/assets/flags/mv.png"),
  },
  {
    name: "Mali",
    code: "ML",
    dial_code: "+223",
    url: require("@/assets/flags/ml.png"),
  },
  {
    name: "Malta",
    code: "MT",
    dial_code: "+356",
    url: require("@/assets/flags/mt.png"),
  },
  {
    name: "M̧ajeļ",
    code: "MH",
    dial_code: "+692",
    url: require("@/assets/flags/mh.png"),
  },
  {
    name: "Martinique",
    code: "MQ",
    dial_code: "+596",
    url: require("@/assets/flags/mq.png"),
  },
  {
    name: "موريتانيا",
    code: "MR",
    dial_code: "+222",
    url: require("@/assets/flags/mr.png"),
  },
  {
    name: "Maurice",
    code: "MU",
    dial_code: "+230",
    url: require("@/assets/flags/mu.png"),
  },
  {
    name: "Mayotte",
    code: "YT",
    dial_code: "+262",
    url: require("@/assets/flags/yt.png"),
  },
  {
    name: "México",
    code: "MX",
    dial_code: "+52",

    url: require("@/assets/flags/mx.png"),
  },
  {
    name: "Micronesia",
    code: "FM",
    dial_code: "+691",
    url: require("@/assets/flags/fm.png"),
  },
  {
    name: "Moldova",
    code: "MD",
    dial_code: "+373",
    url: require("@/assets/flags/md.png"),
  },
  {
    name: "Monaco",
    code: "MC",
    dial_code: "+377",
    url: require("@/assets/flags/mc.png"),
  },
  {
    name: "Монгол улс",
    code: "MN",
    dial_code: "+976",
    url: require("@/assets/flags/mn.png"),
  },
  {
    name: "Црна Гора",
    code: "ME",
    dial_code: "+382",
    url: require("@/assets/flags/me.png"),
  },
  {
    name: "Montserrat",
    code: "MS",
    dial_code: "+1664",
    url: require("@/assets/flags/ms.png"),
  },
  {
    name: "المغرب",
    code: "MA",
    dial_code: "+212",
    url: require("@/assets/flags/ma.png"),
  },
  {
    name: "Moçambique",
    code: "MZ",
    dial_code: "+258",
    url: require("@/assets/flags/mz.png"),
  },
  {
    name: "Myanmar",
    code: "MM",
    dial_code: "+95",

    url: require("@/assets/flags/mm.png"),
  },
  {
    name: "Namibia",
    code: "NA",
    dial_code: "+264",
    url: require("@/assets/flags/na.png"),
  },
  {
    name: "Nauru",
    code: "NR",
    dial_code: "+674",
    url: require("@/assets/flags/nr.png"),
  },
  {
    name: "नेपाल",
    code: "NP",
    dial_code: "+977",
    url: require("@/assets/flags/np.png"),
  },
  {
    name: "Nederland",
    code: "NL",
    dial_code: "+31",

    url: require("@/assets/flags/nl.png"),
  },
  {
    name: "Netherlands Antilles",
    code: "AN",
    dial_code: "+599",
    url: require("@/assets/flags/an.png"),
  },
  {
    name: "Nouvelle-Calédonie",
    code: "NC",
    dial_code: "+687",
    url: require("@/assets/flags/nc.png"),
  },
  {
    name: "New Zealand",
    code: "NZ",
    dial_code: "+64",

    url: require("@/assets/flags/nz.png"),
  },
  {
    name: "Nicaragua",
    code: "NI",
    dial_code: "+505",
    url: require("@/assets/flags/ni.png"),
  },
  {
    name: "Niger",
    code: "NE",
    dial_code: "+227",
    url: require("@/assets/flags/ne.png"),
  },
  {
    name: "Nigeria",
    code: "NG",
    dial_code: "+234",
    url: require("@/assets/flags/ng.png"),
  },
  {
    name: "Niuē",
    code: "NU",
    dial_code: "+683",
    url: require("@/assets/flags/nu.png"),
  },
  {
    name: "Norfolk Island",
    code: "NF",
    dial_code: "+672",
    url: require("@/assets/flags/nf.png"),
  },
  {
    name: "Northern Mariana Islands",
    code: "MP",
    dial_code: "+1670",
    url: require("@/assets/flags/mp.png"),
  },
  {
    name: "Norge",
    code: "NO",
    dial_code: "+47",

    url: require("@/assets/flags/no.png"),
  },
  {
    name: "عمان",
    code: "OM",
    dial_code: "+968",
    url: require("@/assets/flags/om.png"),
  },
  {
    name: "Pakistan",
    code: "PK",
    dial_code: "+92",

    url: require("@/assets/flags/pk.png"),
  },
  {
    name: "Palau",
    code: "PW",
    dial_code: "+680",
    url: require("@/assets/flags/pw.png"),
  },
  {
    name: "فلسطين",
    code: "PS",
    dial_code: "+970",
    url: require("@/assets/flags/ps.png"),
  },
  {
    name: "Panamá",
    code: "PA",
    dial_code: "+507",
    url: require("@/assets/flags/pa.png"),
  },
  {
    name: "Papua Niugini",
    code: "PG",
    dial_code: "+675",
    url: require("@/assets/flags/pg.png"),
  },
  {
    name: "Paraguay",
    code: "PY",
    dial_code: "+595",
    url: require("@/assets/flags/py.png"),
  },
  {
    name: "Perú",
    code: "PE",
    dial_code: "+51",

    url: require("@/assets/flags/pe.png"),
  },
  {
    name: "Pilipinas",
    code: "PH",
    dial_code: "+63",

    url: require("@/assets/flags/ph.png"),
  },
  {
    name: "Pitcairn Islands",
    code: "PN",
    dial_code: "+64",

    url: require("@/assets/flags/pn.png"),
  },
  {
    name: "Polska",
    code: "PL",
    dial_code: "+48",

    url: require("@/assets/flags/pl.png"),
  },
  {
    name: "Portugal",
    code: "PT",
    dial_code: "+351",
    url: require("@/assets/flags/pt.png"),
  },
  {
    name: "Puerto Rico",
    code: "PR",
    dial_code: "+1939",
    url: require("@/assets/flags/pr.png"),
  },
  {
    name: "PuertoRico",
    code: "Pr2",
    dial_code: "+1787",
    url: require("@/assets/flags/pr.png"),
  },
  {
    name: "قطر",
    code: "QA",
    dial_code: "+974",
    url: require("@/assets/flags/qa.png"),
  },
  {
    name: "România",
    code: "RO",
    dial_code: "+40",

    url: require("@/assets/flags/ro.png"),
  },
  {
    name: "Россия",
    code: "RU",
    dial_code: "+7",

    url: require("@/assets/flags/ru.png"),
  },
  {
    name: "Rwanda",
    code: "RW",
    dial_code: "+250",
    url: require("@/assets/flags/rw.png"),
  },
  {
    name: "La Réunion",
    code: "RE",
    dial_code: "+262",
    url: require("@/assets/flags/re.png"),
  },
  {
    name: "Saint-Barthélemy",
    code: "BL",
    dial_code: "+590",
    url: require("@/assets/flags/bl.png"),
  },
  {
    name: "Saint Helena",
    code: "SH",
    dial_code: "+290",
    url: require("@/assets/flags/sh.png"),
  },
  {
    name: "Saint Kitts and Nevis",
    code: "KN",
    dial_code: "+1869",
    url: require("@/assets/flags/kn.png"),
  },
  {
    name: "Saint Lucia",
    code: "LC",
    dial_code: "+1758",
    url: require("@/assets/flags/lc.png"),
  },
  {
    name: "Saint-Martin",
    code: "MF",
    dial_code: "+590",
    url: require("@/assets/flags/mf.png"),
  },
  {
    name: "Saint-Pierre-et-Miquelon",
    code: "PM",
    dial_code: "+508",
    url: require("@/assets/flags/pm.png"),
  },
  {
    name: "Saint Vincent and the Grenadines",
    code: "VC",
    dial_code: "+1784",
    url: require("@/assets/flags/vc.png"),
  },
  {
    name: "Samoa",
    code: "WS",
    dial_code: "+685",
    url: require("@/assets/flags/ws.png"),
  },
  {
    name: "San Marino",
    code: "SM",
    dial_code: "+378",
    url: require("@/assets/flags/sm.png"),
  },
  {
    name: "São Tomé e Príncipe",
    code: "ST",
    dial_code: "+239",
    url: require("@/assets/flags/st.png"),
  },
  {
    name: "العربية السعودية",
    code: "SA",
    dial_code: "+966",
    url: require("@/assets/flags/sa.png"),
  },
  {
    name: "Sénégal",
    code: "SN",
    dial_code: "+221",
    url: require("@/assets/flags/sn.png"),
  },
  {
    name: "Србија",
    code: "RS",
    dial_code: "+381",
    url: require("@/assets/flags/rs.png"),
  },
  {
    name: "Seychelles",
    code: "SC",
    dial_code: "+248",
    url: require("@/assets/flags/sc.png"),
  },
  {
    name: "Sierra Leone",
    code: "SL",
    dial_code: "+232",
    url: require("@/assets/flags/sl.png"),
  },
  {
    name: "Singapore",
    code: "SG",
    dial_code: "+65",

    url: require("@/assets/flags/sg.png"),
  },
  {
    name: "Slovensko",
    code: "SK",
    dial_code: "+421",
    url: require("@/assets/flags/sk.png"),
  },
  {
    name: "Slovenija",
    code: "SI",
    dial_code: "+386",
    url: require("@/assets/flags/si.png"),
  },
  {
    name: "Solomon Islands",
    code: "SB",
    dial_code: "+677",
    url: require("@/assets/flags/sb.png"),
  },
  {
    name: "Soomaaliya",
    code: "SO",
    dial_code: "+252",
    url: require("@/assets/flags/so.png"),
  },
  {
    name: "South Africa",
    code: "ZA",
    dial_code: "+27",

    url: require("@/assets/flags/za.png"),
  },
  {
    name: "South Sudan",
    code: "SS",
    dial_code: "+211",
    url: require("@/assets/flags/ss.png"),
  },
  {
    name: "South Georgia",
    code: "GS",
    dial_code: "+500",
    url: require("@/assets/flags/gs.png"),
  },
  {
    name: "España",
    code: "ES",
    dial_code: "+34",

    url: require("@/assets/flags/es.png"),
  },
  {
    name: "Sri Lanka",
    code: "LK",
    dial_code: "+94",

    url: require("@/assets/flags/lk.png"),
  },
  {
    name: "السودان",
    code: "SD",
    dial_code: "+249",
    url: require("@/assets/flags/sd.png"),
  },
  {
    name: "Suriname",
    code: "SR",
    dial_code: "+597",
    url: require("@/assets/flags/sr.png"),
  },
  {
    name: "Svalbard og Jan Mayen",
    code: "SJ",
    dial_code: "+47",

    url: require("@/assets/flags/sj.png"),
  },
  {
    name: "Swaziland",
    code: "SZ",
    dial_code: "+268",
    url: require("@/assets/flags/sz.png"),
  },
  {
    name: "Sverige",
    code: "SE",
    dial_code: "+46",

    url: require("@/assets/flags/se.png"),
  },
  {
    name: "Schweiz",
    code: "CH",
    dial_code: "+41",

    url: require("@/assets/flags/ch.png"),
  },
  {
    name: "سوريا",
    code: "SY",
    dial_code: "+963",
    url: require("@/assets/flags/sy.png"),
  },
  {
    name: "臺灣",
    code: "TW",
    dial_code: "+886",
    url: require("@/assets/flags/tw.png"),
  },
  {
    name: "Тоҷикистон",
    code: "TJ",
    dial_code: "+992",
    url: require("@/assets/flags/tj.png"),
  },
  {
    name: "Tanzania",
    code: "TZ",
    dial_code: "+255",
    url: require("@/assets/flags/tz.png"),
  },
  {
    name: "ประเทศไทย",
    code: "TH",
    dial_code: "+66",

    url: require("@/assets/flags/th.png"),
  },
  {
    name: "Timor-Leste",
    code: "TL",
    dial_code: "+670",
    url: require("@/assets/flags/tl.png"),
  },
  {
    name: "Togo",
    code: "TG",
    dial_code: "+228",
    url: require("@/assets/flags/tg.png"),
  },
  {
    name: "Tokelau",
    code: "TK",
    dial_code: "+690",
    url: require("@/assets/flags/tk.png"),
  },
  {
    name: "Tonga",
    code: "TO",
    dial_code: "+676",
    url: require("@/assets/flags/to.png"),
  },
  {
    name: "Trinidad and Tobago",
    code: "TT",
    dial_code: "+1868",
    url: require("@/assets/flags/tt.png"),
  },
  {
    name: "تونس",
    code: "TN",
    dial_code: "+216",
    url: require("@/assets/flags/tn.png"),
  },
  {
    name: "Türkiye",
    code: "TR",
    dial_code: "+90",

    url: require("@/assets/flags/tr.png"),
  },
  {
    name: "Türkmenistan",
    code: "TM",
    dial_code: "+993",
    url: require("@/assets/flags/tm.png"),
  },
  {
    name: "Turks and Caicos Islands",
    code: "TC",
    dial_code: "+1649",
    url: require("@/assets/flags/tc.png"),
  },
  {
    name: "Tuvalu",
    code: "TV",
    dial_code: "+688",
    url: require("@/assets/flags/tv.png"),
  },
  {
    name: "Uganda",
    code: "UG",
    dial_code: "+256",
    url: require("@/assets/flags/ug.png"),
  },
  {
    name: "Україна",
    code: "UA",
    dial_code: "+380",
    url: require("@/assets/flags/ua.png"),
  },
  {
    name: "دولة الإمارات العربية المتحدة",
    code: "AE",
    dial_code: "+971",
    url: require("@/assets/flags/ae.png"),
  },
  {
    name: "United Kingdom",
    code: "GB",
    dial_code: "+44",

    url: require("@/assets/flags/gb.png"),
  },
  {
    name: "United States",
    code: "US",
    dial_code: "+1",

    url: require("@/assets/flags/us.png"),
  },
  {
    name: "Uruguay",
    code: "UY",
    dial_code: "+598",
    url: require("@/assets/flags/uy.png"),
  },
  {
    name: "O‘zbekiston",
    code: "UZ",
    dial_code: "+998",
    url: require("@/assets/flags/uz.png"),
  },
  {
    name: "Vanuatu",
    code: "VU",
    dial_code: "+678",
    url: require("@/assets/flags/vu.png"),
  },
  {
    name: "Venezuela",
    code: "VE",
    dial_code: "+58",

    url: require("@/assets/flags/ve.png"),
  },
  {
    name: "Việt Nam",
    code: "VN",
    dial_code: "+84",

    url: require("@/assets/flags/vn.png"),
  },
  {
    name: "British Virgin Islands",
    code: "VG",
    dial_code: "+1284",
    url: require("@/assets/flags/vg.png"),
  },
  {
    name: "United States Virgin Islands",
    code: "VI",
    dial_code: "+1340",
    url: require("@/assets/flags/vi.png"),
  },
  {
    name: "Wallis et Futuna",
    code: "WF",
    dial_code: "+681",
    url: require("@/assets/flags/wf.png"),
  },
  {
    name: "اليَمَن",
    code: "YE",
    dial_code: "+967",
    url: require("@/assets/flags/ye.png"),
  },
  {
    name: "Zambia",
    code: "ZM",
    dial_code: "+260",
    url: require("@/assets/flags/zm.png"),
  },
  {
    name: "Zimbabwe",
    code: "ZW",
    dial_code: "+263",
    url: require("@/assets/flags/zw.png"),
  },
];

export { areaCodeData };
