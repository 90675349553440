<template>
  <div class="trading_order_detail">
    <div class="trading_head">
      <van-icon name="arrow-left" @click="handleBack" />
      <p>订单详情</p>
      <div></div>
    </div>

    <div
      class="trading_order_detail_buy"
      v-if="this.$route.query.model == 'buy'"
    >
      <h2>代购委托</h2>
      <div>
        <p>消耗：</p>
        <span>100 USDT</span>
      </div>
      <div>
        <p>代购模式：</p>
        <span>同意市价</span>
      </div>
      <div>
        <p>接收：</p>
        <span>25 RAM</span>
      </div>
      <div>
        <p>实际成交价格：</p>
        <span>4USDT/RAM</span>
      </div>
      <div>
        <p>额外获得：</p>
        <span>30 CPLE</span>
      </div>
      <div>
        <p>Txid：</p>
        <span>#12345678dadscfsf</span>
      </div>
      <div>
        <p>订单编号：</p>
        <span>#0000000000</span>
      </div>
      <div>
        <p>实际成交时间：</p>
        <span>2023-11-18 12:31:21</span>
      </div>
    </div>

    <div
      class="trading_order_detail_sell"
      v-else-if="this.$route.query.model == 'sell'"
    >
      <h2>代售委托</h2>
      <div>
        <p>消耗：</p>
        <span>100 USDT</span>
      </div>
      <div>
        <p>代售模式：</p>
        <span>同意市价</span>
      </div>
      <div>
        <p>接收：</p>
        <span>25 RAM</span>
      </div>
      <div>
        <p>实际成交价格：</p>
        <span>4USDT/RAM</span>
      </div>
      <div>
        <p>额外获得：</p>
        <span>30 CPLE</span>
      </div>
      <div>
        <p>Txid：</p>
        <span>#12345678dadscfsf</span>
      </div>
      <div>
        <p>订单编号：</p>
        <span>#0000000000</span>
      </div>
      <div>
        <p>实际成交时间：</p>
        <span>2023-11-18 12:31:21</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  methods: {
    handleBack() {
      let type = this.$route.query.type;
      let model = this.$route.query.model;

      this.$router.push({
        path: type == 1 ? "/trading" : "/tradingOrder",
        query: { model: model },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../all.scss";
@import "./index.scss";
</style>
