module.exports = {
  pledgePopUp: {
    txt1: "How to pledge CPLE to participate in the itinerary on-chain to ensure travel compliance?",
    txtWorld1: "How to pledge CPLE to unlock the row world?",
    txt2: "Please download digital asset wallet",
    txt3: "Open the TokenPocket wallet, select the Polygon (Matic) chain - select the wallet address bound in the APP - select CPLE - click transfer",
    txt4: "Transfer your CPLE to the address below and wait for automatic synchronization",
    copy: "copy",
    copyLink: "Copy download link",
    copySuccess: "Copy successful",
    copyFail: "Copy failed",
    cancel: "Cancel",
    notice: "Precautions",
    notice1:
      "1. The transfer address must be the same as the wallet address you bind to the APP, otherwise the pledge cannot be successful",
    notice2:
      "2. When increasing the pledge, you need to unstake the pledged one first, unstake it to the account and then pledge",
    notice3:
      "3. A single pledge must be greater than 600 CPLE, otherwise the pledge has no computing power",
    notice4:
      "4. Pledge T + 1 working day to arrive, if more than one working day has not arrived, please contact customer service",
    call: "If you have any questions, please contact customer service:",
    group: "Telegram group",
    vxCode: "Wechat ID",
    phone: "Telephone",
    copyA:
      "The download link has been copied, please go to the browser and paste it to download",
  },

  joinGroup: {
    join: "Join the Fun Travel Official Telegram Group",
    btok: "Download Btok",
    androidUser: "Android users",
    iosUser: "IOS user",
    browser: "Please visit the address in the browser",
    applestore: "Please use the overseas ID to download from the Apple Store",
    btok2:
      "After installing Btok, search for cple in the search bar at the top of the APP or search for the address link below, and join the group chat",
    operation: "Operation Flow Diagram",
    download: "Download, install and open",
    register: "register",
    information: "Perfect personal information",
    findGroup: "Find and join groups",
  },

  codeOpenApp: {
    join: "Join Fun Travel",
    daily: "Cash Rebate Daily",
    code: "Invitation code",
  },

  indexShare: {
    passenger: "Passenger",
    owner: "Owner",
    people: "people",
    yuan: "Yuan",
    newUser: "New User Registration",
    phoneReg: "Mobile phone number has been registered",
    regSuccess: "registration success",
    openapp: "Open the Fun Travel APP to view the itinerary",
    fail: "mistake",
    today: "today",
  },

  register: {
    qcx: "Fun Travel Official Telegram Group",
    title1: "Web3.0 brand new carpool travel ecology",
    title2:
      "Join now, open the NFT avatar, and receive cash red envelopes every day",
    title3:
      "Send you a cash cow, open the NFT avatar, and enjoy the platform's daily income feedback with CPLE, the more you contribute, the more you will earn",
    phonelast: "Last number",
    inputPhone:
      "Please enter your mobile phone number to receive registration rewards",
    inputPass: "Please enter a combination of 8-15 digits and letters password",
    inputSMSCode: "Please enter the SMS verification code",
    inputCode: "get verification code",
    regCPLE: "Register and receive CPLE",
    txt1: "You are already a registered user of Fun Travel, you can directly download or open the App",
    txt2: "You can download or open the App directly",
    txt3: "Mobile phone number cannot be empty",
    txt4: "The mobile phone number is entered incorrectly, please check whether the format is correct!",
    txt5: "The password cannot be less than 8 characters!",
    txt6: "The longest password cannot exceed 15 characters!",
    txt7: "The password was entered incorrectly, please check whether the format is correct!",
    txt8: "Please check whether the information filled in is correct!",
    txt9: "Please pass verification",
    download: "Download",
  },

  agent: {
    normalUser: "Ordinary user",
    area: "Regional agency",
    city: "Municipal agency",
    province: "Provincial agency",
    recommend: "Referral relationship",
    notRecommend: "No matter for the moment",
    standing: "Current identity",
    condition: "Rank promotion process and conditions",
    team1: "Team members ≥60 people ",
    now: "(current",
    people: " people)",
    areaAgent: "The level is regional agent",
    team2: "Team members ≥3 district level agents",
    cityAgent: "The grade is municipal agent",
    team3: "Team members ≥3 municipal agents",
    earnings: "Accumulated income has been obtained:",
    reward: "View reward details",
    number: "Serial number",
    earnings2: "Earnings",
    amount: "Post-trade amount",
    rewardTime: "Earn bonus time",
    reason: "Reason",
    grade: "Grade",
    temporary: "Temporary-",
    rewardDetail: "Reward detail",
    more: "Click to load more",
    loading: "Loading",
  },
};
