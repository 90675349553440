var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card",class:{ 'card--fixed': _vm.isFixed, 'card--collapsed': _vm.isCollapsed },style:({ top: _vm.fixedTop + 'px' })},[(!_vm.isCollapsed)?_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"road_show_entrust"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_c('div',{staticClass:"road_show_ent_reward"},[_c('div',{staticClass:"reward_routine"},[_vm._m(4),(_vm.isReward)?_c('div'):_c('van-button',{attrs:{"type":"primary"}},[_vm._v("质押")])],1),(_vm.isReward)?_c('div',{staticClass:"reward_routine-vait"},[_c('p',[_vm._v("待领取奖励：88.935")]),_c('van-button',{attrs:{"type":"primary"}},[_vm._v("领取")])],1):_vm._e()]),_c('div',{staticClass:"road_show_ent_reward"},[_c('div',{staticClass:"reward_routine"},[_vm._m(5),(_vm.isRoutineReward)?_c('div'):_c('van-button',{attrs:{"type":"primary"}},[_vm._v("质押")])],1),(_vm.isRoutineReward)?_c('div',[_c('div',{staticClass:"reward_one"},[_vm._m(6),_c('van-button',{attrs:{"type":"primary"}},[_vm._v("去完成")])],1),_c('div',{staticClass:"reward_one"},[_vm._m(7),_c('van-button',{attrs:{"type":"primary"}},[_vm._v("加入")])],1)]):(!_vm.isRoutineReward)?_c('div',[_vm._m(8),_vm._m(9)]):_vm._e()])])]):_c('div',{staticClass:"card-collapsed"},[_c('div',[_vm._v("123")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"road_show_ent_head"},[_c('img',{attrs:{"src":require("@/assets/boxWallet/roadShow/img1.png"),"alt":""}}),_c('p',[_vm._v("PORTAL")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"road_show_ent_txt"},[_c('p',[_vm._v("总奖励")]),_c('span',[_vm._v("200000")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"road_show_ent_txt"},[_c('p',[_vm._v("挖币周期")]),_c('span',[_vm._v("7天")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"road_show_ent_txt"},[_c('p',[_vm._v("距离挖币结束还剩")]),_c('span',[_vm._v("12天:14时:25分")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex"}},[_c('img',{attrs:{"src":require("@/assets/boxWallet/roadShow/green.png"),"alt":""}}),_c('div',{staticClass:"reward_routine_r"},[_c('p',{staticClass:"reward_routine_r_txt1"},[_vm._v("常规质押奖池 900000")]),_c('p',{staticClass:"reward_routine_r_txt2"},[_vm._v("存入总量：700900")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex"}},[_c('img',{attrs:{"src":require("@/assets/boxWallet/roadShow/yellow.png"),"alt":""}}),_c('div',{staticClass:"reward_routine_r"},[_c('p',{staticClass:"reward_routine_r_txt1"},[_vm._v("升级行为奖池 11100000")]),_c('p',{staticClass:"reward_routine_r_txt3"},[_vm._v("有奖参与，需质押1000CPLE 7D")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"reward_one_l"},[_c('img',{attrs:{"src":require("@/assets/boxWallet/roadShow/one.png"),"alt":""}}),_c('div',{staticClass:"reward_one_show"},[_c('p',{staticStyle:{"font-size":"14px"}},[_vm._v("路演互动")]),_c('p',[_c('span',{staticClass:"reward_one_show_txt1"},[_vm._v("+10.00")]),_c('span',{staticClass:"reward_one_show_txt2"},[_vm._v("剩1000位")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"reward_one_l"},[_c('img',{attrs:{"src":require("@/assets/boxWallet/roadShow/two.png"),"alt":""}}),_c('div',{staticClass:"reward_one_show"},[_c('p',{staticStyle:{"font-size":"14px"}},[_vm._v("加入项目群")]),_c('p',[_c('span',{staticClass:"reward_one_show_txt1"},[_vm._v("+10.00")]),_c('span',{staticClass:"reward_one_show_txt2"},[_vm._v("剩1000位")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"reward_one"},[_c('div',{staticClass:"reward_one_l"},[_c('img',{attrs:{"src":require("@/assets/boxWallet/roadShow/one.png"),"alt":""}}),_c('div',{staticClass:"reward_one_show"},[_c('p',{staticStyle:{"font-size":"14px"}},[_vm._v("路演互动")])])]),_c('p',[_c('span',{staticClass:"reward_one_show_txt1"},[_vm._v("+10.00")]),_c('span',{staticClass:"reward_one_show_txt2"},[_vm._v("剩1000位")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"reward_one",staticStyle:{"border":"none"}},[_c('div',{staticClass:"reward_one_l"},[_c('img',{attrs:{"src":require("@/assets/boxWallet/roadShow/two.png"),"alt":""}}),_c('div',{staticClass:"reward_one_show"},[_c('p',{staticStyle:{"font-size":"14px"}},[_vm._v("加入项目群")])])]),_c('p',[_c('span',{staticClass:"reward_one_show_txt1"},[_vm._v("+10.00")]),_c('span',{staticClass:"reward_one_show_txt2"},[_vm._v("剩1000位")])])])
}]

export { render, staticRenderFns }