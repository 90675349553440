<template>
  <div
    class="agent_mobile"
    v-loading="loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="agent_mobile_header">
      <div class="agent_mobile_header_a">
        <div v-if="!agentLevel" style="font-size: 16px; font-weight: 500">
          暂无等级
        </div>
        <div v-else-if="agentLevel">
          <img
            src="@/assets/Normal.png"
            v-if="agentLevel == $t('agent.normalUser')"
            alt=""
          />
          <img
            src="@/assets/Area.png"
            v-else-if="agentLevel == $t('agent.area')"
            alt=""
          />
          <img
            src="@/assets/City.png"
            v-else-if="agentLevel == $t('agent.city')"
            alt=""
          />
          <img
            src="@/assets/Province.png"
            v-else-if="agentLevel == $t('agent.province')"
            alt=""
          />
          <p>{{ isTemp }}{{ agentLevel }}</p>
        </div>
      </div>
      <div class="agent_mobile_header_b">
        <p class="agent_mobile_title">{{ $t("agent.recommend") }}</p>
        <RelationGraph
          style="height: calc(100vh - 200px)"
          ref="seeksRelationGraph"
          :options="graphOptions"
          v-if="!displayNoneTxt"
        />
        <div
          style="height: 150px; line-height: 150px; text-align: center"
          v-else-if="displayNoneTxt"
        >
          {{ $t("agent.notRecommend") }}
        </div>
      </div>
    </div>

    <div>
      <div class="agent_mobile_title">{{ $t("agent.condition") }}</div>

      <div class="agent_detail_relation_btn">
        <div
          class="agent_detail_relation_btn_d"
          :style="
            agentLevelType == 0
              ? 'background:rgba(0, 180, 90, 0.1);border:1px #00b45a solid;'
              : ''
          "
          style="font-size: 14px"
        >
          <el-tag
            type="success"
            style="width: 98px; margin: 0 auto"
            v-if="agentLevelType == 0"
            >{{ $t("agent.standing") }}</el-tag
          >
          <p
            style="font-weight: 500"
            :style="agentLevelType == 0 ? 'color: #00b45a' : ''"
          >
            {{ $t("agent.normalUser") }}
          </p>
        </div>
        <i class="iconfont icon-xiajiantou"></i>
        <div
          class="agent_detail_relation_btn_d"
          :style="
            agentLevelType == 1
              ? 'background:rgba(0, 180, 90, 0.1);border:1px #00b45a solid;'
              : ''
          "
        >
          <div>
            <el-tag
              type="success"
              style="margin-left: 0"
              v-if="agentLevelType == 1"
              >{{ $t("agent.standing") }}</el-tag
            >
            <p
              class="agent_d_info_p"
              :style="agentLevelType == 1 ? 'color: #00b45a' : ''"
            >
              {{
                agentLevelType == 1
                  ? isTemp
                    ? $t("agent.temporary")
                    : ""
                  : ""
              }}{{ $t("agent.area") }}
            </p>
            <p class="agent_detail_relation_btn_d_dis">
              <img src="@/assets/yes.png" v-if="!isOverFive" alt="" />
              <img
                src="@/assets/gray_yes.png"
                alt=""
                v-if="isOverFive && agentLevelType != 1"
              />
              <img
                src="@/assets/green_yes.png"
                v-else-if="isOverFive && agentLevelType == 1"
                alt=""
              />
              <span
                :style="
                  isOverFive && agentLevelType == 1 ? 'color: #00b45a' : ''
                "
                >5*5</span
              >
            </p>
            <p class="agent_detail_relation_btn_d_dis">
              <img src="@/assets/yes.png" v-if="teamCount < 60" alt="" />
              <img
                src="@/assets/gray_yes.png"
                alt=""
                v-if="teamCount >= 60 && agentLevelType != 1"
              />
              <img
                src="@/assets/green_yes.png"
                v-else-if="teamCount >= 60 && agentLevelType == 1"
                alt=""
              />
              <span
                :style="
                  teamCount >= 60 && agentLevelType == 1 ? 'color: #00b45a' : ''
                "
                >{{ $t("agent.team1") }}{{ $t("agent.now") }}{{ teamCount
                }}{{ $t("agent.people") }}</span
              >
            </p>
          </div>
        </div>
        <i class="iconfont icon-xiajiantou"></i>
        <div
          class="agent_detail_relation_btn_d"
          :style="
            agentLevelType == 2
              ? 'background:rgba(0, 180, 90, 0.1);border:1px #00b45a solid;'
              : ''
          "
        >
          <div>
            <el-tag
              type="success"
              style="margin-left: 0"
              v-if="agentLevelType == 2"
              >{{ $t("agent.standing") }}</el-tag
            >
            <p
              class="agent_d_info_p"
              :style="agentLevelType == 2 ? 'color: #00b45a' : ''"
            >
              {{
                agentLevelType == 2
                  ? isTemp
                    ? $t("agent.temporary")
                    : ""
                  : ""
              }}{{ $t("agent.city") }}
            </p>
            <p class="agent_detail_relation_btn_d_dis">
              <img src="@/assets/yes.png" v-if="originalLevel !== 1" alt="" />
              <img
                src="@/assets/gray_yes.png"
                alt=""
                v-if="originalLevel == 1 && agentLevelType != 2"
              />
              <img
                src="@/assets/green_yes.png"
                v-else-if="originalLevel == 1 && agentLevelType == 2"
                alt=""
              />
              <span
                :style="
                  originalLevel == 1 && agentLevelType == 2
                    ? 'color: #00b45a'
                    : ''
                "
                >{{ $t("agent.areaAgent") }}</span
              >
            </p>
            <p class="agent_detail_relation_btn_d_dis">
              <img src="@/assets/yes.png" v-if="cityAgentCount < 3" alt="" />
              <img
                src="@/assets/gray_yes.png"
                alt=""
                v-if="cityAgentCount >= 3 && agentLevelType != 2"
              />
              <img
                src="@/assets/green_yes.png"
                v-else-if="cityAgentCount >= 3 && agentLevelType == 2"
                alt=""
              />
              <span
                :style="
                  cityAgentCount >= 3 && agentLevelType == 2
                    ? 'color: #00b45a'
                    : ''
                "
                >{{ $t("agent.team2") }}</span
              >
            </p>
          </div>
        </div>
        <i class="iconfont icon-xiajiantou"></i>
        <div
          class="agent_detail_relation_btn_d"
          :style="
            agentLevelType == 3
              ? 'background:rgba(0, 180, 90, 0.1);border:1px #00b45a solid;'
              : ''
          "
        >
          <div>
            <el-tag
              type="success"
              style="margin-left: 0"
              v-if="agentLevelType == 3"
              >{{ $t("agent.standing") }}</el-tag
            >
            <p
              class="agent_d_info_p"
              :style="agentLevelType == 3 ? 'color: #00b45a' : ''"
            >
              {{
                agentLevelType == 3
                  ? isTemp
                    ? $t("agent.temporary")
                    : ""
                  : ""
              }}{{ $t("agent.province") }}
            </p>
            <p class="agent_detail_relation_btn_d_dis">
              <img src="@/assets/yes.png" v-if="originalLevel != 2" alt="" />
              <img
                src="@/assets/gray_yes.png"
                alt=""
                v-if="originalLevel == 2 && agentLevelType != 3"
              />
              <img
                src="@/assets/green_yes.png"
                v-else-if="originalLevel == 2 && agentLevelType == 3"
                alt=""
              />
              <span
                :style="
                  originalLevel == 2 && agentLevelType == 3
                    ? 'color: #00b45a'
                    : ''
                "
                >{{ $t("agent.cityAgent") }}</span
              >
            </p>
            <p class="agent_detail_relation_btn_d_dis">
              <img
                src="@/assets/yes.png"
                v-if="provinceAgentCount < 3"
                alt=""
              />
              <img
                src="@/assets/gray_yes.png"
                alt=""
                v-if="provinceAgentCount >= 3 && agentLevelType != 3"
              />
              <img
                src="@/assets/green_yes.png"
                v-else-if="provinceAgentCount >= 3 && agentLevelType == 3"
                alt=""
              />
              <span
                :style="
                  provinceAgentCount >= 3 && agentLevelType == 3
                    ? 'color: #00b45a'
                    : ''
                "
                >{{ $t("agent.team3") }}</span
              >
            </p>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div class="agent_detail_table_header">
        <p>{{ $t("agent.earnings") }}$ {{ gainsTotal }}</p>
        <p style="color: #5267ff; cursor: pointer" @click="handleDetail">
          {{ $t("agent.reward") }}<i class="el-icon-arrow-right"></i>
        </p>
      </div>

      <div class="agent_detail_table_d">
        <el-table
          :data="tableDataDetail"
          style="width: 100%"
          :header-cell-style="{
            background: 'rgba(250, 250, 252, 1)',
            color: '#4b4b4b',
          }"
        >
          <el-table-column
            type="index"
            :label="$t('agent.number')"
            width="50"
            align="center"
          >
            <template slot-scope="scope">
              <span>{{ scope.$index + (currentPage - 1) * pageSize + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="amount"
            :label="$t('agent.earnings2')"
            align="center"
          >
            <template slot-scope="scope">$ {{ scope.row.amount }}</template>
          </el-table-column>
          <el-table-column
            prop="afterAmount"
            :label="$t('agent.amount')"
            width="100"
          >
            <template slot-scope="scope"
              >$ {{ scope.row.afterAmount }}</template
            >
          </el-table-column>
          <el-table-column
            prop="createTime"
            :label="$t('agent.rewardTime')"
            width="160"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="title"
            :label="$t('agent.reason')"
            width="80"
            align="center"
          >
          </el-table-column>

          <el-table-column
            prop="agentLevel"
            :label="$t('agent.grade')"
            align="center"
            width="130"
          >
            <div>{{ isTemp }}{{ agentLevel }}</div>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { reqAgentURL } from "@/api/apiUrl";
import { request } from "@/api/request";
import { format_time_date } from "@/util/funBusiness";

export default {
  data() {
    return {
      loading: true,
      displayNoneTxt: false,
      isTemp: false, // 是否为临时
      agentLevel: 0, // 身份

      agentLevelType: -1,
      originalLevel: false, // 判断自己是否为真区代/真市代
      cityAgentCount: 0, // 下面直推，三个真区代
      isOverFive: false, // 5*5
      provinceAgentCount: 0, // 下面直推，三个真市代
      teamCount: 0, // 团队成员

      graphOptions: {
        backgrounImageNoRepeat: true,
        layouts: [
          {
            label: "中心",
            layoutName: "tree",
            layoutClassName: "seeks-layout-center",
            defaultJunctionPoint: "border",
            min_per_width: "300",
            max_per_width: "300",
            min_per_height: "40",
            from: "left",
          },
        ],
        defaultLineMarker: {
          markerWidth: 18,
          markerHeight: 18,
          refX: 6,
          refY: 6,
          data: "M2,2 L10,6 L2,10 L6,6 L2,2",
        },
        moveToCenterWhenRefresh: true,
        allowShowMiniToolBar: false,
        defaultExpandHolderPosition: "left",
        defaultNodeShape: 1,
        defaultNodeWidth: "220",
        defaultLineShape: 3,
        defaultJunctionPoint: "lr",
        defaultNodeColor: "rgba(244, 244, 244, 1)",
        defaultNodeFontColor: "rgba(0, 0, 0, 1)",
        defaultNodeBorderColor: "#A0A0A0",
        defaultLineColor: "rgba(154, 154, 154, 1)",
        defaultNodeBorderWidth: 1,
        disableZoom: true,
        disableDragNode: true,
        disableDragCanvas: true,
        zoomToFitWhenRefresh: true,
      },

      // 分页数据
      currentPage: 1, // 当前页
      pageLength: 0,
      pageSize: 10, //要传过去的数据 每页多少条数据
      pageSizes: [10, 20, 50, 100],

      gainsTotal: "", // 累计总收益
      tableDataDetail: [],
    };
  },

  mounted() {
    if (this.$route.query.language == "zh-CN") {
      this.$store.state.isLang = "zh-CN";
      localStorage.setItem("language", "zh-CN");
      this.$i18n.locale = localStorage.getItem("language");
    } else if (this.$route.query.language == "en") {
      this.$store.state.isLang = "en";
      localStorage.setItem("language", "en");
      this.$i18n.locale = localStorage.getItem("language");
    } else if (this.$route.query.language == "ja") {
      this.$store.state.isLang = "ja";
      localStorage.setItem("language", "ja");
      this.$i18n.locale = localStorage.getItem("language");
    } else if (this.$route.query.language == "ko") {
      this.$store.state.isLang = "ko";
      localStorage.setItem("language", "ko");
      this.$i18n.locale = localStorage.getItem("language");
    }

    const opt = {
      url: reqAgentURL.getAgentLevelDetail,
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8;",
      },
      params: JSON.stringify({
        userId: this.$route.query.userId * 1,
        userName: this.$route.query.name,
      }),
      resFunc: (res) => {
        if (res.data.code == 0) {
          this.loading = false;
          if (res.data.data) {
            this.agentLevel = res.data.data.agentLevel;
            this.agentLevelType = res.data.data.agentLevel;
            this.cityAgentCount = res.data.data.cityAgentCount;
            this.isOverFive = res.data.data.isOverFive;
            this.provinceAgentCount = res.data.data.provinceAgentCount;
            this.teamCount = res.data.data.teamCount;
            this.isTemp = res.data.data.isTemp;
            this.originalLevel = res.data.data.originalLevel;
            res.data.data.isTemp == true
              ? (this.isTemp = this.$t("agent.temporary"))
              : (this.isTemp = "");

            res.data.data.agentLevel == 0
              ? (this.agentLevel = this.$t("agent.normalUser"))
              : res.data.data.agentLevel == 1
              ? (this.agentLevel = this.$t("agent.area"))
              : res.data.data.agentLevel == 2
              ? (this.agentLevel = this.$t("agent.city"))
              : res.data.data.agentLevel == 3
              ? (this.agentLevel = this.$t("agent.province"))
              : "";

            const __graph_json_data = {
              rootId: res.data.data.rootId,
              nodes: res.data.data.nodes,
              lines: res.data.data.lines || [],
            };

            this.$refs.seeksRelationGraph.setJsonData(
              __graph_json_data,
              (graphInstance) => {
                // 这些写上当图谱初始化完成后需要执行的代码
              }
            );
          } else {
            this.displayNoneTxt = true;
          }
        }

        if (res.data.code != 0) {
          this.loading = false;
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
        }

        if (res.data.code == 999) {
          this.displayNoneTxt = true;
        }
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
      },
    };
    request(opt);

    setTimeout(() => {
      const optTable = {
        url: reqAgentURL.getBoxAgentBalanceDetail,
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=utf-8;",
        },
        params: JSON.stringify({
          appLang: "zh-CN", // TW("zh-TW", new Locale("zh-TW")),CN("zh-CN", new Locale("zh-CN")),KR("ko-KR", new Locale("ko-KR")),JP("ja-JP", new Locale("ja-JP")),US("en-US", new Locale("en-US")),
          userId: this.$route.query.userId * 1,
          pageSize: 5,
        }),
        resFunc: (res) => {
          if (res.data.code == 0) {
            this.tableDataDetail = res.data.data.list;
            res.data.data.list.map(
              (item) => (item.createTime = format_time_date(item.createTime))
            );
          }

          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
          }
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          console.log(err, "err1");
        },
      };
      request(optTable);

      const optMoney = {
        url: reqAgentURL.getBoxAgentBalanceSum,
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=utf-8;",
        },
        params: JSON.stringify({
          userId: this.$route.query.userId,
        }),
        resFunc: (res) => {
          if (res.data.code == 0) {
            this.gainsTotal = res.data.data;
          }

          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
          }
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          console.log(err, "err1");
        },
      };
      request(optMoney);
    }, 500);
  },

  methods: {
    handleDetail() {
      this.$router.push({
        path: "/AgentMobileDetail",
        query: {
          userId: this.$route.query.userId,
          name: this.$route.query.name,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-loading-spinner {
  top: calc(100vh - 23%);
}
.agent_mobile_header {
  border-bottom: 10px rgba(250, 250, 252, 1) solid;

  .agent_mobile_header_a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 10px rgba(250, 250, 252, 1) solid;

    img {
      width: 56px;
      height: 56px;
      margin-bottom: 8px;
    }

    p {
      font-size: 16px;
      font-weight: 500;
    }
  }

  .agent_mobile_header_c {
    height: 150px;
  }
}

.agent_mobile_title {
  padding-left: 10px;
  margin-top: 10px;
  font-weight: 500;
  font-size: 16px;
}

.agent_detail_relation_btn {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 10px 0;
  border-bottom: 10px rgba(250, 250, 252, 1) solid;
  padding-bottom: 20px;

  i {
    margin: 0 auto;
  }

  .agent_detail_relation_btn_d {
    width: 80%;
    margin: 0 auto;
    padding: 10px 20px;
    background: #fafafc;
    border-radius: 10px;
    border: 1px #ebedf2 solid;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 400;

    .agent_d_info_p {
      margin-bottom: 5px;
      font-weight: 500;
      font-size: 14px;
    }

    .agent_detail_relation_btn_d_dis {
      display: flex;
      align-items: center;
      color: #7d828c;
      font-weight: 400;
      font-size: 13px;

      .agent_detail_relation_btn_d_circle {
        width: 12px;
        height: 12px;
        border: 1px rgba(235, 237, 242, 1) solid;
        display: block;
        border-radius: 30px;
        background: #fff;
        margin-right: 4px;
      }

      img {
        width: 12px;
        height: 12px;
        margin-right: 5px;
      }
    }

    ::v-deep .el-button.is-round {
      padding: 8px 13px;
      margin-top: 5px;
      font-size: 11px;
    }
  }
}

.agent_detail_table_header {
  display: flex;
  justify-content: space-between;
  margin: 0 10px;
  padding-bottom: 10px;

  p {
    font-weight: 400;
  }
}

.agent_detail_table_d {
  border: 1px #ebedf2 solid;
  border-radius: 10px;
  margin: 0 10px 10px 10px;

  .el-table {
    border-radius: 10px;

    ::v-deep .el-table__empty-text {
      text-align: left;
    }
  }
}
</style>

<style>
.c-node-text {
  font-weight: 400;
  font-size: 20px;
}
</style>
