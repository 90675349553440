import Vue from "vue";
import VueRouter from "vue-router";
import i18n from "./lang/i18n";
import BaiduMap from "vue-baidu-map";

import App from "./App.vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import router from "./router";
import store from "./store";
import * as VueGoogleMaps from "vue2-google-maps";

import TencentCaptcha from "./util/TencentCaptcha";
Vue.use(TencentCaptcha);

import relationGraph from "relation-graph";
Vue.use(relationGraph);

import Vant from "vant";
import "vant/lib/index.css";
Vue.use(Vant);

// 使用
Vue.prototype.$bus = new Vue();

import iView from "iview";
import "iview/dist/styles/iview.css"; // 使用 CSS

import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);

import { preventReClick } from "./util/repeatClick";

Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(VueRouter);
Vue.use(iView);
Vue.use(BaiduMap, {
  ak: "WncTOouugkSiSX4ZyUkjkhOrfBBYIkLj", //百度地图密钥
});

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyD1mKAle9v7QdUbBXm34Hs0uJlCBaqaePM",
  },
});
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
