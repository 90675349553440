<template>
  <div class="fire_wealth_rule">
    <img src="@/assets/firebox2.png" alt="" class="fire_wealth_rule_bg" />
    <div style="z-index: 1">
      <div style="display: flex; justify-content: center">
        <img
          src="@/assets/fireboxLogo.png"
          class="fire_wealth_rule_logo"
          alt=""
        />
      </div>
      <div class="fire_weal_r_date">本活动{{ date }}开启,敬请期待</div>
      <div class="fire_w_r_content">
        <img src="../../assets/fireboxsmall1.png" alt="" />
        <p>{{ startDate }}开始以周为单位, 激活10万台趣宝盒之前有效</p>
      </div>
      <div
        class="fire_w_r_content"
        style="margin-bottom: 5px; align-items: flex-start"
      >
        <img src="../../assets/fireboxsmall2.png" alt="" />
        <p>
          每轮活动，第一周为推广期，第二三周为带动期，第四周为结算期。每周（推广期）激活趣宝盒的下级用户，根据接下来两周（带动期）所产出的cple总量，在第四周（结算期）给予上级级奖励
        </p>
      </div>
      <table>
        <tr class="fire_w_r_content_table_tr1">
          <td style="height: 50px">下级两周<br />累计产出</td>
          <td>1500+</td>
          <td>1000+</td>
          <td>500+</td>
          <td>300+</td>
          <td>100+</td>
          <td>50+</td>
          <td style="border-right: none">10+</td>
        </tr>
        <tr>
          <td style="height: 45px">上级奖励</td>
          <td>1500</td>
          <td>900</td>
          <td>400</td>
          <td>200</td>
          <td>65</td>
          <td>30</td>
          <td style="border-right: none">5</td>
        </tr>
      </table>

      <div class="fire_w_r_content" style="margin-bottom: 0">
        <img src="../../assets/fireboxsmall3.png" alt="" />
        <p>每一期的榜单前10名将获得额外奖励</p>
      </div>
      <ul>
        <li>第1名：额外奖励888.88转出额度</li>
        <li>第2名：额外奖励188.88转出额度</li>
        <li>第3名：额外88.88转出额度</li>
        <li>4-10名：额外奖励8.88转出额度</li>
      </ul>

      <div class="fire_w_r_content" style="margin-bottom: 0">
        <img src="../../assets/fireboxsmall4.png" alt="" />
        <p>
          用户可在第四周领取第一周邀请的用户的奖励，
          在第五周领取第二周邀请的用户的奖励，依次往复…
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { format_time_date } from "@/util/funBusiness";
export default {
  data() {
    return {
      date: "",
      startDate: "",
    };
  },
  mounted() {
    this.date = format_time_date(this.$route.query.date * 1);
    this.startDate = format_time_date(this.$route.query.startDate * 1);
  },
};
</script>

<style lang="scss" scoped>
.fire_wealth_rule {
  width: 100%;
  height: 100vh;
  // background: url(../../assets/firebox.png) no-repeat;
  background-image: linear-gradient(#6e57ff, #5a57ff);
  overflow-y: scroll;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  font-family: "PingFang SC";
  font-size: 14px;
  font-weight: 500;

  .fire_wealth_rule_bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .fire_wealth_rule_logo {
    width: 188px;
    height: 108px;
  }

  .fire_weal_r_date {
    width: 80%;
    height: 40px;
    margin: 0 auto;
    border-radius: 16px;
    background: rgba(0, 0, 0, 0.1);
    line-height: 40px;
    text-align: center;
  }

  .fire_w_r_content {
    width: 90%;
    display: flex;
    align-items: center;
    margin: 20px auto;

    img {
      width: 40px;
      height: 40px;
    }

    p {
      text-align: justify;
    }
  }

  table {
    width: 85%;
    border: 1px rgba(255, 255, 255, 0.2) solid;
    margin: 0 auto;
    font-size: 10px;

    .fire_w_r_content_table_tr1 {
      td {
        border-bottom: 1px rgba(255, 255, 255, 0.2) solid;
      }
    }

    td {
      font-weight: 400;
      border-right: 1px rgba(255, 255, 255, 0.2) solid;
      padding-left: 4px;
    }
  }

  ul {
    width: 65%;
    margin: 0 auto;
    font-weight: 400;

    li {
      list-style: disc;
    }
  }
}
</style>
