import { render, staticRenderFns } from "./noneStartDetail.vue?vue&type=template&id=315953a1&scoped=true"
import script from "./noneStartDetail.vue?vue&type=script&lang=js"
export * from "./noneStartDetail.vue?vue&type=script&lang=js"
import style0 from "./noneStartDetail.vue?vue&type=style&index=0&id=315953a1&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "315953a1",
  null
  
)

export default component.exports