var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"trading_order_detail"},[_c('div',{staticClass:"trading_head"},[_c('van-icon',{attrs:{"name":"arrow-left"},on:{"click":_vm.handleBack}}),_c('p',[_vm._v("订单详情")]),_c('div')],1),(this.$route.query.model == 'buy')?_c('div',{staticClass:"trading_order_detail_buy"},[_c('h2',[_vm._v("代购委托")]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7)]):(this.$route.query.model == 'sell')?_c('div',{staticClass:"trading_order_detail_sell"},[_c('h2',[_vm._v("代售委托")]),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_vm._m(14),_vm._m(15)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',[_vm._v("消耗：")]),_c('span',[_vm._v("100 USDT")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',[_vm._v("代购模式：")]),_c('span',[_vm._v("同意市价")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',[_vm._v("接收：")]),_c('span',[_vm._v("25 RAM")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',[_vm._v("实际成交价格：")]),_c('span',[_vm._v("4USDT/RAM")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',[_vm._v("额外获得：")]),_c('span',[_vm._v("30 CPLE")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',[_vm._v("Txid：")]),_c('span',[_vm._v("#12345678dadscfsf")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',[_vm._v("订单编号：")]),_c('span',[_vm._v("#0000000000")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',[_vm._v("实际成交时间：")]),_c('span',[_vm._v("2023-11-18 12:31:21")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',[_vm._v("消耗：")]),_c('span',[_vm._v("100 USDT")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',[_vm._v("代售模式：")]),_c('span',[_vm._v("同意市价")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',[_vm._v("接收：")]),_c('span',[_vm._v("25 RAM")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',[_vm._v("实际成交价格：")]),_c('span',[_vm._v("4USDT/RAM")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',[_vm._v("额外获得：")]),_c('span',[_vm._v("30 CPLE")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',[_vm._v("Txid：")]),_c('span',[_vm._v("#12345678dadscfsf")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',[_vm._v("订单编号：")]),_c('span',[_vm._v("#0000000000")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',[_vm._v("实际成交时间：")]),_c('span',[_vm._v("2023-11-18 12:31:21")])])
}]

export { render, staticRenderFns }