<template>
  <div class="road_show_detail">
    <div class="trading_head">
      <van-icon name="arrow-left" @click="handleBack" />
      <p v-if="this.$route.query.status == 'underway'">正进行中</p>
      <p v-else-if="this.$route.query.status == 'start'">即将开启</p>
      <p v-else-if="this.$route.query.status == 'history'">历史路演</p>
      <div></div>
    </div>

    <div v-if="this.$route.query.status == 'underway'">
      <div
        class="road_show_entrust"
        v-for="item in underwayList"
        :key="item.id"
      >
        <div class="road_show_ent_head">
          <img :src="item.prjAvatarUrl" alt="" />
          <p>{{ item.prjName }}</p>
        </div>
        <div class="road_show_ent_txt">
          <p>总奖励</p>
          <span>{{ item.totalReward }}</span>
        </div>
        <div class="road_show_ent_txt">
          <p>挖币周期</p>
          <span>{{ item.poolBlock }}</span>
        </div>
        <div class="road_show_ent_txt">
          <p>距离挖币结束还剩</p>
          <span>{{ item.endCountdown }}</span>
        </div>
        <div class="road_show_ent_reward">
          <div class="reward_routine">
            <div style="display: flex">
              <img src="@/assets/boxWallet/roadShow/green.png" alt="" />
              <div class="reward_routine_r">
                <p class="reward_routine_r_txt1">
                  常规质押奖池 {{ item.totalReward }}
                </p>
                <p class="reward_routine_r_txt2">
                  存入总量：{{ item.totalDeposit }}
                </p>
              </div>
            </div>
            <div v-if="item.totalDeposit < item.upgradeDepositAmount"></div>
            <van-button
              v-else
              type="primary"
              @click.stop="showPledgeNormal = true"
              >质押</van-button
            >
          </div>
          <div class="reward_routine-vait" v-if="item.userInfoAmount > 0">
            <p>待领取奖励：{{ item.userInfoReward }}</p>
            <van-button
              type="primary"
              v-if="item.userInfoReward != 0"
              @click.stop="handleGetReward"
              >领取</van-button
            >
          </div>

          <!-- 领取奖励按钮 -->
          <van-popup
            v-model="showGetReward"
            round
            closeable
            position="bottom"
            :style="{ height: '48%' }"
          >
            <div class="detail_show_answer—correct">
              <img src="@/assets/boxWallet/roadShow/correct.png" alt="" />
              <p class="detail_show_answer—correct_num">
                +{{ item.userInfoReward }}
              </p>
              <h2>奖励领取成功</h2>
              <div class="show_btn">
                <van-button round @click.stop="showGetReward = false"
                  >知道了</van-button
                >
              </div>
            </div>
          </van-popup>
        </div>

        <div class="road_show_ent_reward">
          <div class="reward_routine">
            <div style="display: flex">
              <img src="@/assets/boxWallet/roadShow/yellow.png" alt="" />
              <div class="reward_routine_r">
                <p class="reward_routine_r_txt1">
                  升级行为奖池 {{ item.totalReward }}
                </p>
                <p
                  class="reward_routine_r_txt3"
                  style="margin-top: 5px !important; font-size: 12px"
                >
                  需质押数量：{{ item.upgradeDepositAmount }}CPLE
                </p>
                <p class="reward_routine_r_txt3" style="font-size: 12px">
                  需质押时长：{{ item.upgradeDepositBlock }}
                </p>
              </div>
            </div>
            <div v-if="isRoutineReward"></div>
            <van-button type="primary" v-else @click.stop="handlePledge"
              >质押</van-button
            >
          </div>
          <div v-if="isRoutineReward">
            <div class="reward_one">
              <div class="reward_one_l">
                <img src="@/assets/boxWallet/roadShow/one.png" alt="" />
                <div class="reward_one_show">
                  <p style="font-size: 14px">路演互动</p>
                  <p>
                    <span class="reward_one_show_txt1"
                      >+{{ item.lyReward }}</span
                    >
                    <span class="reward_one_show_txt2"
                      >剩{{ item.lyTotal - item.lyUsed }}位</span
                    >
                  </p>
                </div>
              </div>
              <van-button type="primary" @click.stop="handleShowDetail"
                >去完成</van-button
              >
            </div>
            <div class="reward_one">
              <div class="reward_one_l">
                <img src="@/assets/boxWallet/roadShow/two.png" alt="" />
                <div class="reward_one_show">
                  <p style="font-size: 14px">加入项目群</p>
                  <p>
                    <span class="reward_one_show_txt1"
                      >+{{ item.groupReward }}</span
                    >
                    <span class="reward_one_show_txt2"
                      >剩{{ item.groupTotal - item.groupUsed }}位</span
                    >
                  </p>
                </div>
              </div>
              <van-button type="primary" @click.stop="showJoinGroupChat = true"
                >加入</van-button
              >
            </div>
          </div>
          <div v-else-if="!isRoutineReward">
            <div class="reward_one">
              <div class="reward_one_l">
                <img src="@/assets/boxWallet/roadShow/one.png" alt="" />
                <div class="reward_one_show">
                  <p style="font-size: 14px">路演互动</p>
                </div>
              </div>
              <p>
                <span class="reward_one_show_txt1">+{{ item.lyReward }}</span>
                <span class="reward_one_show_txt2"
                  >剩{{ item.lyTotal - item.lyUsed }}位</span
                >
              </p>
            </div>
            <div class="reward_one" style="border: none">
              <div class="reward_one_l">
                <img src="@/assets/boxWallet/roadShow/two.png" alt="" />
                <div class="reward_one_show">
                  <p style="font-size: 14px">加入项目群</p>
                </div>
              </div>
              <p>
                <span class="reward_one_show_txt1"
                  >+{{ item.groupReward }}</span
                >
                <span class="reward_one_show_txt2"
                  >剩{{ item.groupTotal - item.groupUsed }}位</span
                >
              </p>
            </div>
          </div>
        </div>

        <!-- 质押 -->
        <van-popup
          v-model="showPledgeNormal"
          round
          position="bottom"
          :style="{ height: '65%' }"
        >
          <div class="detail_show_pledge">
            <div
              style="border-bottom: 1px #ffffff1a solid; padding-bottom: 20px"
            >
              <div class="detail_show_pledge_title">存入CPLE</div>
              <p>您的 CPLE 将从现货账户中扣除，并冻结在币安宝账户中。</p>
              <p>满足以下条件可解锁升级行为奖池,获取更多奖励.</p>

              <div class="reward_routine">
                <div style="display: flex">
                  <img src="@/assets/boxWallet/roadShow/green.png" alt="" />
                  <div class="reward_routine_r">
                    <p class="reward_routine_r_txt1">
                      常规质押奖池 {{ item.totalReward }}
                    </p>
                    <p class="reward_routine_r_txt2">
                      存入总量：{{ totalDeposit }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="detail_show_pledge_user">
              <p>现货账户</p>
              <p>
                <span>{{ balance }} </span>CPLE 可用
              </p>
            </div>

            <div class="detail_show_pledge_num">
              <van-field
                v-model="cpleNum"
                type="digit"
                placeholder="请输入质押数量"
              />CPLE
            </div>
            <div class="show_btn" style="justify-content: space-between">
              <van-button round @click.stop="handleShowPledge">取消</van-button>
              <van-button
                round
                type="primary"
                :disabled="cpleNum == ''"
                @click.stop="handleSubmit(item.poolPid)"
                >存入</van-button
              >
            </div>
          </div>
        </van-popup>
      </div>
    </div>

    <div v-else-if="this.$route.query.status == 'start'">
      <div
        class="road_show_entrust"
        v-for="item in startList"
        :key="item.id"
        @click="handleDetail('start')"
      >
        <div class="road_show_ent_head">
          <img :src="item.prjAvatarUrl" alt="" />
          <p>{{ item.prjName }}</p>
        </div>
        <div class="road_show_ent_txt">
          <p>总奖励</p>
          <span>{{ item.totalReward }}</span>
        </div>
        <div class="road_show_ent_txt">
          <p>挖币周期</p>
          <span>{{ item.poolBlock }}</span>
        </div>
        <div class="road_show_ent_txt">
          <p>开始时间</p>
          <span>{{ item.startCountdown }}</span>
        </div>
        <div class="road_show_ent_reward">
          <div class="reward_routine">
            <div style="display: flex">
              <img src="@/assets/boxWallet/roadShow/green.png" alt="" />
              <div class="reward_routine_r">
                <p class="reward_routine_r_txt1">
                  常规质押奖池 {{ item.totalReward }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="road_show_ent_reward">
          <div class="reward_routine">
            <div style="display: flex">
              <img src="@/assets/boxWallet/roadShow/yellow.png" alt="" />
              <div class="reward_routine_r">
                <p class="reward_routine_r_txt1">
                  升级行为奖池 {{ item.totalReward }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="this.$route.query.status == 'history'">
      <div
        class="road_show_entrust"
        v-for="item in historyList"
        :key="item.id"
        @click="handleDetail('history')"
      >
        <div class="road_show_ent_head">
          <img :src="item.prjAvatarUrl" alt="" />
          <p>{{ item.prjName }}</p>
        </div>
        <div class="road_show_ent_txt">
          <p>总奖励</p>
          <span>{{ item.totalReward }}</span>
        </div>
        <div class="road_show_ent_txt">
          <p>挖币周期</p>
          <span>{{ item.poolBlock }}</span>
        </div>

        <div class="road_show_ent_reward">
          <div class="reward_routine">
            <div style="display: flex">
              <img src="@/assets/boxWallet/roadShow/green.png" alt="" />
              <div class="reward_routine_r">
                <p class="reward_routine_r_txt1">
                  常规质押奖池 {{ item.totalReward }}
                </p>
                <p class="reward_routine_r_txt2">
                  存入总量：{{ item.totalDeposit }}
                </p>
              </div>
            </div>
          </div>
          <div class="reward_routine-vait">
            <p>待领取奖励：{{ item.userInfoReward }}</p>
            <van-button
              type="primary"
              @click.stop="showGetReward = true"
              v-if="item.userInfoReward != 0"
              >领取</van-button
            >
          </div>
        </div>

        <div class="road_show_ent_reward">
          <div class="reward_routine">
            <div style="display: flex">
              <img src="@/assets/boxWallet/roadShow/yellow.png" alt="" />
              <div class="reward_routine_r">
                <p class="reward_routine_r_txt1">
                  升级行为奖池 {{ item.totalReward }}
                </p>
                <p
                  class="reward_routine_r_txt3"
                  style="margin-top: 5px !important; font-size: 12px"
                >
                  需质押数量：{{ item.upgradeDepositAmount }}CPLE
                </p>
                <p class="reward_routine_r_txt3" style="font-size: 12px">
                  需质押时长：{{ item.upgradeDepositBlock }}
                </p>
              </div>
            </div>
            <div v-if="isRoutineReward"></div>
            <van-button
              type="primary"
              v-else
              @click.stop="showPledgeNormal = true"
              >质押</van-button
            >
          </div>
          <div v-if="isRoutineReward">
            <div class="reward_one">
              <div class="reward_one_l">
                <img src="@/assets/boxWallet/roadShow/one.png" alt="" />
                <div class="reward_one_show">
                  <p style="font-size: 14px">路演互动</p>
                  <p>
                    <span class="reward_one_show_txt1"
                      >+{{ item.lyReward }}</span
                    >
                    <span class="reward_one_show_txt2"
                      >剩{{ item.lyTotal - item.lyUsed }}位</span
                    >
                  </p>
                </div>
              </div>
              <van-button type="primary">去完成</van-button>
            </div>
            <div class="reward_one">
              <div class="reward_one_l">
                <img src="@/assets/boxWallet/roadShow/two.png" alt="" />
                <div class="reward_one_show">
                  <p style="font-size: 14px">加入项目群</p>
                  <p>
                    <span class="reward_one_show_txt1"
                      >+{{ item.groupReward }}</span
                    >
                    <span class="reward_one_show_txt2"
                      >剩{{ item.groupTotal - item.groupUsed }}位</span
                    >
                  </p>
                </div>
              </div>
              <van-button type="primary">加入</van-button>
            </div>
          </div>
          <div v-else-if="!isRoutineReward">
            <div class="reward_one">
              <div class="reward_one_l">
                <img src="@/assets/boxWallet/roadShow/one.png" alt="" />
                <div class="reward_one_show">
                  <p style="font-size: 14px">路演互动</p>
                </div>
              </div>
              <p>
                <span class="reward_one_show_txt1">+{{ item.lyReward }}</span>
                <span class="reward_one_show_txt2"
                  >剩{{ item.lyTotal - item.lyUsed }}位</span
                >
              </p>
            </div>
            <div class="reward_one" style="border: none">
              <div class="reward_one_l">
                <img src="@/assets/boxWallet/roadShow/two.png" alt="" />
                <div class="reward_one_show">
                  <p style="font-size: 14px">加入项目群</p>
                </div>
              </div>
              <p>
                <span class="reward_one_show_txt1"
                  >+{{ item.groupReward }}</span
                >
                <span class="reward_one_show_txt2"
                  >剩{{ item.groupTotal - item.groupUsed }}位</span
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Decimal from "decimal.js";
import {
  poolLength,
  userInfoAmount,
  userPendingReward,
  upgradeDeposit,
  totalDeposit,
  poolInfo,
  showBalance,
  rewardDeposit,
} from "@/service/road_show";

import { reqProject } from "@/api/apiUrl";
import { request2 } from "@/api/request";
import { showLoading, hideLoading } from "@/util/loading";
import { millisecondsToDays, formatDuration } from "@/util/funBusiness";

Decimal.set({
  precision: 20, // 设置精度
  rounding: Decimal.ROUND_DOWN, // 设置舍入模式
});

export default {
  inject: ["reload"],
  data() {
    return {
      underwayList: [],
      startList: [],
      historyList: [],

      poolBlock: "", // 挖币周期
      poolTotalReward: "", // 总奖励
      poolTotalDeposit: "", // 常规数量
      poolUpgradeDeposit: "", // 升级行为奖池

      userInfoAmount: "", // 常规质押-用户存入总量
      userInfoReward: "", // 用户待领取奖励
      upgradeDepositAmount: "", // 升级行为奖池-质押数量
      upgradeDepositBlock: "", // 升级行为奖池-质押时长

      history: {
        poolBlock: "", // 挖币周期
        poolTotalReward: "", // 总奖励
        poolTotalDeposit: "", // 常规数量
        poolUpgradeDeposit: "", // 升级行为奖池
        userInfoAmount: "", // 常规质押-用户存入总量
        userInfoReward: "", // 用户待领取奖励
        upgradeDepositAmount: "", // 升级行为奖池-质押数量
        upgradeDepositBlock: "", // 升级行为奖池-质押时长
      },

      poolLength: "", // 路演项目数量（池子数量）
      totalCPLE: "", // 路演项目中质押cp数量

      isReward: false, // 常规质押奖池按钮
      isRoutineReward: false, // 升级行为奖池按钮

      showGetReward: false, // 领取奖励
      showPledgeNormal: false,
      cpleNum: "",
      balance: "", // 用户余额
    };
  },

  mounted() {
    poolLength().then((res) => {
      this.poolLength = res;
    });

    totalDeposit().then((res) => {
      const amountCPLE = new Decimal(res);
      const numCPLE = new Decimal(1e18);
      const amountResultCPLE = amountCPLE.div(numCPLE);
      this.totalCPLE = amountResultCPLE.toString();
    });

    showLoading();
    const optUnderway = {
      url: reqProject.getInfoList,
      method: "POST",
      params: JSON.stringify({
        status: 1,
      }),
      resFunc: (res) => {
        if (res.data.code == 0) {
          let arr = [];
          res.data.data.list.map((item) => {
            let obj = item || {};

            item.endCountdown = formatDuration(item.endCountdown * 1000);
            item.poolBlock = formatDuration(
              (item.poolEndBlock - item.poolStartBlock) * 2 * 1000
            );

            userInfoAmount(item.poolPid).then((res) => {
              const a = new Decimal(res.amount);
              const numa = new Decimal(1e18);
              const amountResulta = a.div(numa);
              obj.userInfoAmount = amountResulta.toString();
            });

            userPendingReward(item.poolPid).then((res) => {
              const useramount = new Decimal(res);
              const userNum = new Decimal(1e18);
              const useramountResult = useramount.div(userNum);

              obj.userInfoReward = useramountResult.toString();
            });

            upgradeDeposit(item.poolPid).then((res) => {
              const amount = new Decimal(res[0]);
              const num = new Decimal(1e18);
              const amountResult = amount.div(num);

              obj.upgradeDepositAmount = amountResult.toString();
              obj.upgradeDepositBlock = formatDuration(
                (res[2] - res[1]) * 2 * 1000
              );
            });

            poolInfo(item.poolPid).then((res) => {
              console.log(res, "池子信息");

              const y = new Decimal(1e18);
              // 常规奖池总奖励
              const totalReward = new Decimal(res.totalReward);
              const totalRewardResult = totalReward.div(y);
              obj.totalReward = totalRewardResult.toString();

              // 存入总量
              const totalDeposit = new Decimal(res.totalDeposit);
              const totalDepositResult = totalDeposit.div(y);
              obj.totalDeposit = totalDepositResult.toString();

              obj.poolCountDown = formatDuration(
                (res.endBlock - res.startBlock) * 2 * 1000
              );
            });
            setTimeout(() => {
              arr.push(obj);
            }, 500);
          });

          this.underwayList = arr;

          hideLoading();
        }

        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        }
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
        hideLoading();
      },
    };
    request2(optUnderway);

    const optStart = {
      url: reqProject.getInfoList,
      method: "POST",
      params: JSON.stringify({
        status: 0,
      }),
      resFunc: (res) => {
        if (res.data.code == 0) {
          let arr = [];
          res.data.data.list.map((item) => {
            let obj = item || {};
            item.startCountdown = formatDuration(item.startCountdown * 1000);
            item.poolBlock = formatDuration(
              (item.poolEndBlock - item.poolStartBlock) * 2 * 1000
            );

            poolInfo(item.poolPid).then((res) => {
              console.log(res, "池子信息");

              const y = new Decimal(1e18);
              // 常规奖池总奖励
              const totalReward = new Decimal(res.totalReward);
              const totalRewardResult = totalReward.div(y);
              obj.totalReward = totalRewardResult.toString();
            });

            upgradeDeposit(item.poolPid).then((res) => {
              const amount = new Decimal(res[0]);
              const num = new Decimal(1e18);
              const amountResult = amount.div(num);
              obj.upgradeDepositAmount = amountResult.toString();
            });

            setTimeout(() => {
              arr.push(obj);
            }, 500);
          });

          this.startList = arr;
          hideLoading();
        }

        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        }
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
        hideLoading();
      },
    };
    request2(optStart);

    const optHistory = {
      url: reqProject.getInfoList,
      method: "POST",
      params: JSON.stringify({
        status: 2,
      }),
      resFunc: (res) => {
        if (res.data.code == 0) {
          let arr = [];
          res.data.data.list.map((item) => {
            let obj = item || {};

            item.startCountdown = formatDuration(item.startCountdown);
            item.endCountdown = formatDuration(item.endCountdown);

            item.poolBlock = formatDuration(
              (item.poolEndBlock - item.poolStartBlock) * 2 * 1000
            );

            userInfoAmount(item.poolPid).then((res) => {
              const amount = new Decimal(res.amount);
              const num = new Decimal(1e18);
              const amountResult = amount.div(num);
              obj.userInfoAmount = amountResult.toString();
            });

            userPendingReward(item.poolPid).then((res) => {
              obj.userInfoReward = res;
            });

            upgradeDeposit(item.poolPid).then((res) => {
              const amount = new Decimal(res.amount);
              const num = new Decimal(1e18);
              const amountResult = amount.div(num);
              obj.upgradeDepositAmount = amountResult.toString();

              obj.upgradeDepositBlock = formatDuration(
                (res.endBlock - res.startBlock) * 2 * 1000
              );
            });

            const totalReward = new Decimal(item.poolTotalReward);
            const totalDeposit = new Decimal(item.poolTotalDeposit);
            const totalUpgradeDeposit = new Decimal(item.poolUpgradeDeposit);
            const y = new Decimal(1e18);
            const totalRewardResult = totalReward.div(y);
            const totalDepositResult = totalDeposit.div(y);
            const totalUpgradeDepositResult = totalUpgradeDeposit.div(y);
            item.poolTotalReward = totalRewardResult.toString(); // 总奖励
            item.poolTotalDeposit = totalDepositResult.toString(); // 常规总奖励
            item.poolUpgradeDeposit = totalUpgradeDepositResult.toString(); // 升级行为奖池

            arr.push(obj);
          });

          this.historyList = arr;

          hideLoading();
        }

        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        }
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
        hideLoading();
      },
    };
    request2(optHistory);
  },

  methods: {
    handleBack() {
      this.$router.push("/roadShow");
    },

    // 质押操作
    handlePledge(num) {
      this.showPledgeNormal = true;
      this.totalDeposit = num;

      showAllowance().then((res) => {
        console.log(res, "是否授权");
      });

      showBalance().then((res) => {
        console.log("账户余额:", res);
        const balanceNum = new Decimal(res);
        const y = new Decimal(1e18);
        const balanceNumResult = balanceNum.div(y);
        this.balance = balanceNumResult;
      });
    },

    // 存入
    handleSubmit() {
      let num = this.cpleNum * 1e18;

      approveAdr(num.toString()).then((res) => {
        if (res == true) {
          depositPledge(poolPid, num.toString()).then((res) => {
            console.log("质押cple", res);

            this.$toast({
              type: "success",
              message: "存入成功",
              overlay: true,
            });
          });
        }
      });
    },

    // 取消
    handleShowPledge() {
      this.showPledgeNormal = false;
      this.cpleNum = "";
    },

    // 领取奖励
    handleGetReward(poolPid) {
      rewardDeposit(poolPid, 0).then((res) => {
        console.log("领取奖励：", res);
        this.showGetReward = true;
        this.refresh();
      });
    },

    // 刷新
    refresh() {
      this.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../all.scss";
@import "./index.scss";
@import "../trading/index.scss";
</style>
