<template>
  <div class="road_show">
    <HeaderTabbar title="质押CPLE，获得更多优质项目资讯和代币"></HeaderTabbar>

    <div class="road_show_con">
      <div class="road_show_nav_img">
        <img
          src="@/assets/boxWallet/roadShow-logo.png"
          class="imgTitle"
          alt=""
        />
      </div>

      <div class="road_show_head">
        <div>
          <p class="road_show_head_text1">{{ totalCPLE }} CPLE</p>
          <p class="road_show_head_text2">当前正质押</p>
        </div>
        <div>
          <p class="road_show_head_text1">{{ poolLength }}</p>
          <p class="road_show_head_text2">路演项目数</p>
        </div>
      </div>
    </div>
    <van-sticky :offset-top="178">
      <div
        class="entrust_header"
        style="padding: 0 20px; background: #1e1e1e"
        @click="handleStatusDetail('underway')"
      >
        <p>正进行中</p>
        <van-icon name="arrow" />
      </div>
    </van-sticky>

    <div style="margin-top: 115px">
      <div
        class="road_show_entrust"
        v-for="item in underwayList.slice(0, 2)"
        @click="handleDetail('ready', item)"
        :key="item.id"
      >
        <div>
          <div class="road_show_ent_head">
            <img :src="item.prjAvatarUrl" alt="" />
            <p>{{ item.prjName }}</p>
          </div>
          <div class="road_show_ent_txt">
            <p>总奖励</p>
            <span>{{ item.totalReward }}</span>
          </div>
          <div class="road_show_ent_txt">
            <p>挖币周期</p>
            <span>{{ item.poolBlock }}</span>
          </div>
          <div class="road_show_ent_txt">
            <p>距离挖币结束还剩</p>
            <span>{{ item.endCountdown }}</span>
          </div>
          <div class="road_show_ent_reward">
            <div class="reward_routine">
              <div style="display: flex">
                <img src="@/assets/boxWallet/roadShow/green.png" alt="" />
                <div class="reward_routine_r">
                  <p class="reward_routine_r_txt1">
                    常规质押奖池 {{ item.totalReward }}
                  </p>
                  <p class="reward_routine_r_txt2">
                    存入总量：{{ item.totalDeposit }}
                  </p>
                </div>
              </div>
              <div v-if="item.totalDeposit < item.upgradeDepositAmount"></div>
              <van-button
                type="primary"
                v-else
                @click.stop="handlePledge(item.totalDeposit)"
                >质押</van-button
              >
            </div>
            <div class="reward_routine-vait" v-if="item.userInfoAmount > 0">
              <p>待领取奖励：{{ item.userInfoReward }}</p>
              <van-button
                type="primary"
                v-if="item.userInfoReward != 0"
                @click.stop="handleGetReward(item.poolPid)"
                >领取</van-button
              >
            </div>

            <!-- 领取奖励按钮 -->
            <van-popup
              v-model="showGetReward"
              round
              closeable
              position="bottom"
              :style="{ height: '48%' }"
            >
              <div class="detail_show_answer—correct">
                <img src="@/assets/boxWallet/roadShow/correct.png" alt="" />
                <p class="detail_show_answer—correct_num">
                  +{{ item.userInfoReward }}
                </p>
                <h2>奖励领取成功</h2>
                <div class="show_btn">
                  <van-button round @click.stop="showGetReward = false"
                    >知道了</van-button
                  >
                </div>
              </div>
            </van-popup>
          </div>

          <div class="road_show_ent_reward">
            <div class="reward_routine">
              <div style="display: flex">
                <img src="@/assets/boxWallet/roadShow/yellow.png" alt="" />
                <div class="reward_routine_r">
                  <p class="reward_routine_r_txt1">
                    升级行为奖池 {{ item.totalReward }}
                  </p>
                  <p
                    class="reward_routine_r_txt3"
                    style="margin-top: 5px !important; font-size: 12px"
                  >
                    需质押数量：{{ item.upgradeDepositAmount }}CPLE
                  </p>
                  <p class="reward_routine_r_txt3" style="font-size: 12px">
                    需质押时长：{{ item.upgradeDepositBlock }}
                  </p>
                </div>
              </div>
              <div v-if="isRoutineReward"></div>
              <van-button
                type="primary"
                v-else
                @click.stop="handlePledgeUpgrade"
                >质押</van-button
              >
            </div>
            <div v-if="item.userInfoAmount >= item.upgradeDepositAmount">
              <div class="reward_one">
                <div class="reward_one_l">
                  <img src="@/assets/boxWallet/roadShow/one.png" alt="" />
                  <div class="reward_one_show">
                    <p style="font-size: 14px">路演互动</p>
                    <p>
                      <span class="reward_one_show_txt1"
                        >+{{ item.lyReward }}</span
                      >
                      <span class="reward_one_show_txt2"
                        >剩{{ item.lyTotal - item.lyUsed }}位</span
                      >
                    </p>
                  </div>
                </div>
                <van-button
                  type="primary"
                  @click.stop="handleDetail('ready', item)"
                  >去完成</van-button
                >
              </div>
              <div class="reward_one">
                <div class="reward_one_l">
                  <img src="@/assets/boxWallet/roadShow/two.png" alt="" />
                  <div class="reward_one_show">
                    <p style="font-size: 14px">加入项目群</p>
                    <p>
                      <span class="reward_one_show_txt1"
                        >+{{ item.groupReward }}</span
                      >
                      <span class="reward_one_show_txt2"
                        >剩{{ item.groupTotal - item.groupUsed }}位</span
                      >
                    </p>
                  </div>
                </div>
                <van-button
                  type="primary"
                  @click.stop="showJoinGroupChat = true"
                  >加入</van-button
                >
              </div>
            </div>
            <div v-else-if="item.userInfoAmount < item.upgradeDepositAmount">
              <div class="reward_one">
                <div class="reward_one_l">
                  <img src="@/assets/boxWallet/roadShow/one.png" alt="" />
                  <div class="reward_one_show">
                    <p style="font-size: 14px">路演互动</p>
                  </div>
                </div>
                <p>
                  <span class="reward_one_show_txt1">+{{ item.lyReward }}</span>
                  <span class="reward_one_show_txt2"
                    >剩{{ item.lyTotal - item.lyUsed }}位</span
                  >
                </p>
              </div>
              <div class="reward_one" style="border: none">
                <div class="reward_one_l">
                  <img src="@/assets/boxWallet/roadShow/two.png" alt="" />
                  <div class="reward_one_show">
                    <p style="font-size: 14px">加入项目群</p>
                  </div>
                </div>
                <p>
                  <span class="reward_one_show_txt1"
                    >+{{ item.groupReward }}</span
                  >
                  <span class="reward_one_show_txt2"
                    >剩{{ item.groupTotal - item.groupUsed }}位</span
                  >
                </p>
              </div>
            </div>
          </div>

          <!-- 质押 -->
          <van-popup
            @click.stop
            v-model="showPledgeNormal"
            round
            position="bottom"
            :style="{ height: '65%' }"
          >
            <div class="detail_show_pledge">
              <div
                style="border-bottom: 1px #ffffff1a solid; padding-bottom: 20px"
              >
                <div class="detail_show_pledge_title">存入CPLE</div>
                <p>您的 CPLE 将从现货账户中扣除，并冻结在币安宝账户中。</p>
                <p>满足以下条件可解锁升级行为奖池,获取更多奖励.</p>

                <div class="reward_routine">
                  <div style="display: flex">
                    <img src="@/assets/boxWallet/roadShow/green.png" alt="" />
                    <div class="reward_routine_r">
                      <p class="reward_routine_r_txt1">
                        常规质押奖池 {{ item.totalReward }}
                      </p>
                      <p class="reward_routine_r_txt2">
                        存入总量：{{ totalDeposit }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="detail_show_pledge_user">
                <p>现货账户</p>
                <p>
                  <span>{{ balance }} </span>CPLE 可用
                </p>
              </div>

              <div class="detail_show_pledge_num">
                <van-field
                  v-model="cpleNum"
                  type="digit"
                  placeholder="请输入质押数量"
                />CPLE
              </div>
              <div class="show_btn" style="justify-content: space-between">
                <van-button round @click.stop="handleShowPledge"
                  >取消</van-button
                >
                <van-button
                  round
                  type="primary"
                  :disabled="cpleNum == ''"
                  @click.stop="handleSubmit(item.poolPid)"
                  >存入</van-button
                >
              </div>
            </div>
          </van-popup>

          <!-- 升级质押 -->
          <van-popup
            @click.stop
            v-model="showPledgeNormalUpgrade"
            round
            closeable
            position="bottom"
            :style="{ height: '65%' }"
          >
            <div class="detail_show_pledge">
              <div
                style="border-bottom: 1px #ffffff1a solid; padding-bottom: 20px"
              >
                <div class="detail_show_pledge_title">存入CPLE</div>
                <p>您的 CPLE 将从现货账户中扣除，并冻结在币安宝账户中。</p>
                <p>满足以下条件可解锁升级行为奖池,获取更多奖励.</p>

                <div class="reward_routine">
                  <div style="display: flex">
                    <img src="@/assets/boxWallet/roadShow/yellow.png" alt="" />
                    <div class="reward_routine_r">
                      <p class="reward_routine_r_txt1">
                        升级行为奖池 {{ item.poolUpgradeDeposit }}
                      </p>
                      <p class="reward_routine_r_txt2">
                        需质押数量 {{ item.upgradeDepositAmount }}
                      </p>
                      <p class="reward_routine_r_txt2">
                        需质押时长 {{ item.upgradeDepositBlock }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="detail_show_pledge_user">
                <p>现货账户</p>
                <p>
                  <span>{{ balance }} </span>CPLE 可用
                </p>
              </div>

              <div class="detail_show_pledge_num">
                <van-field
                  v-model="cpleNum2"
                  type="digit"
                  placeholder="请输入质押数量"
                  disabled
                />CPLE
              </div>
              <div class="show_btn" style="justify-content: space-between">
                <van-button round @click="showPledgeNormalUpgrade = false"
                  >取消</van-button
                >
                <van-button round type="primary" @click="handleSubmit2(item)"
                  >存入</van-button
                >
              </div>
            </div>
          </van-popup>
        </div>
      </div>

      <div class="entrust_header" @click="handleStatusDetail('start')">
        <p>即将开启</p>
        <van-icon name="arrow" />
      </div>

      <div
        class="road_show_entrust"
        v-for="item in startList.slice(0, 2)"
        :key="item.id"
        @click="handleDetail('start', item)"
      >
        <div class="road_show_ent_head">
          <img :src="item.prjAvatarUrl" alt="" />
          <p>{{ item.prjName }}</p>
        </div>
        <div class="road_show_ent_txt">
          <p>总奖励</p>
          <span>{{ item.totalReward }}</span>
        </div>
        <div class="road_show_ent_txt">
          <p>挖币周期</p>
          <span>{{ item.poolBlock }}</span>
        </div>
        <div class="road_show_ent_txt">
          <p>开始时间</p>
          <span>{{ item.startCountdown }}</span>
        </div>
        <div class="road_show_ent_reward">
          <div class="reward_routine">
            <div style="display: flex">
              <img src="@/assets/boxWallet/roadShow/green.png" alt="" />
              <div class="reward_routine_r">
                <p class="reward_routine_r_txt1">
                  常规质押奖池 {{ item.totalReward }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="road_show_ent_reward">
          <div class="reward_routine">
            <div style="display: flex">
              <img src="@/assets/boxWallet/roadShow/yellow.png" alt="" />
              <div class="reward_routine_r">
                <p class="reward_routine_r_txt1">
                  升级行为奖池 {{ item.totalReward }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="entrust_header" @click="handleStatusDetail('history')">
        <p>历史路演</p>
        <van-icon name="arrow" />
      </div>

      <div
        class="road_show_entrust"
        v-for="item in historyList.slice(0, 2)"
        :key="item.id"
        @click="handleDetail('history', item)"
      >
        <div class="road_show_ent_head">
          <img :src="item.prjAvatarUrl" alt="" />
          <p>{{ item.prjName }}</p>
        </div>
        <div class="road_show_ent_txt">
          <p>总奖励</p>
          <span>{{ item.totalReward }}</span>
        </div>
        <div class="road_show_ent_txt">
          <p>挖币周期</p>
          <span>{{ item.poolBlock }}</span>
        </div>

        <div class="road_show_ent_reward">
          <div class="reward_routine">
            <div style="display: flex">
              <img src="@/assets/boxWallet/roadShow/green.png" alt="" />
              <div class="reward_routine_r">
                <p class="reward_routine_r_txt1">
                  常规质押奖池 {{ item.totalReward }}
                </p>
                <p class="reward_routine_r_txt2">
                  存入总量：{{ item.totalDeposit }}
                </p>
              </div>
            </div>
          </div>
          <div class="reward_routine-vait">
            <p>待领取奖励：{{ item.userInfoReward }}</p>
            <van-button
              type="primary"
              @click.stop="showGetReward = true"
              v-if="item.userInfoReward != 0"
              >领取</van-button
            >
          </div>
        </div>

        <div class="road_show_ent_reward">
          <div class="reward_routine">
            <div style="display: flex">
              <img src="@/assets/boxWallet/roadShow/yellow.png" alt="" />
              <div class="reward_routine_r">
                <p class="reward_routine_r_txt1">
                  升级行为奖池 {{ item.poolUpgradeDeposit }}
                </p>
                <p
                  class="reward_routine_r_txt3"
                  style="margin-top: 5px !important; font-size: 12px"
                >
                  需质押数量：{{ item.upgradeDepositAmount }}CPLE
                </p>
                <p class="reward_routine_r_txt3" style="font-size: 12px">
                  需质押时长：{{ item.upgradeDepositBlock }}
                </p>
              </div>
            </div>
            <div v-if="isRoutineReward"></div>
            <van-button type="primary" v-else @click.stop="handlePledgeUpgrade"
              >质押</van-button
            >
          </div>
          <div v-if="isRoutineReward">
            <div class="reward_one">
              <div class="reward_one_l">
                <img src="@/assets/boxWallet/roadShow/one.png" alt="" />
                <div class="reward_one_show">
                  <p style="font-size: 14px">路演互动</p>
                  <p>
                    <span class="reward_one_show_txt1"
                      >+{{ item.lyReward }}</span
                    >
                    <span class="reward_one_show_txt2"
                      >剩{{ item.lyTotal - item.lyUsed }}位</span
                    >
                  </p>
                </div>
              </div>
              <van-button type="primary">去完成</van-button>
            </div>
            <div class="reward_one">
              <div class="reward_one_l">
                <img src="@/assets/boxWallet/roadShow/two.png" alt="" />
                <div class="reward_one_show">
                  <p style="font-size: 14px">加入项目群</p>
                  <p>
                    <span class="reward_one_show_txt1"
                      >+{{ item.groupReward }}</span
                    >
                    <span class="reward_one_show_txt2"
                      >剩{{ item.groupTotal - item.groupUsed }}位</span
                    >
                  </p>
                </div>
              </div>
              <van-button type="primary">加入</van-button>
            </div>
          </div>
          <div v-else-if="!isRoutineReward">
            <div class="reward_one">
              <div class="reward_one_l">
                <img src="@/assets/boxWallet/roadShow/one.png" alt="" />
                <div class="reward_one_show">
                  <p style="font-size: 14px">路演互动</p>
                </div>
              </div>
              <p>
                <span class="reward_one_show_txt1">+{{ item.lyReward }}</span>
                <span class="reward_one_show_txt2"
                  >剩{{ item.lyTotal - item.lyUsed }}位</span
                >
              </p>
            </div>
            <div class="reward_one" style="border: none">
              <div class="reward_one_l">
                <img src="@/assets/boxWallet/roadShow/two.png" alt="" />
                <div class="reward_one_show">
                  <p style="font-size: 14px">加入项目群</p>
                </div>
              </div>
              <p>
                <span class="reward_one_show_txt1"
                  >+{{ item.groupReward }}</span
                >
                <span class="reward_one_show_txt2"
                  >剩{{ item.groupTotal - item.groupUsed }}位</span
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <BottomTabbar></BottomTabbar>

    <!-- 加入群聊 -->
    <van-popup v-model="showJoinGroupChat" closeable round>
      <div class="join_group_chat">
        <h2>加入群聊</h2>
        <p>请下载趣出行App</p>
        <img src="@/assets/boxWallet/logo4.png" alt="" />
        <div class="pay_call" style="margin: 0 auto" @click="handleDownLoad">
          跳转下载链接
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import Web3 from "web3";
import Decimal from "decimal.js";
import {
  poolLength,
  userInfoAmount,
  userPendingReward,
  upgradeDeposit,
  totalDeposit,
  approveAdr,
  showAllowance,
  showBalance,
  depositPledge,
  rewardDeposit,
  poolInfo,
} from "@/service/road_show";

import HeaderTabbar from "../Header/index.vue";
import BottomTabbar from "../Bottomtabbar/index.vue";
import Card from "./Card.vue";
import { downLoadAppFn } from "@/util/downLoadApp";

import { reqProject } from "@/api/apiUrl";
import { request2 } from "@/api/request";
import { showLoading, hideLoading } from "@/util/loading";
import { formatDuration } from "@/util/funBusiness";

Decimal.set({
  precision: 20, // 设置精度
  rounding: Decimal.ROUND_DOWN, // 设置舍入模式
});

export default {
  inject: ["reload"],
  components: { HeaderTabbar, BottomTabbar, Card },
  data() {
    return {
      web3: null,

      isReward: false, // 常规质押奖池按钮
      isRoutineReward: false, // 升级行为奖池按钮

      underwayList: [],
      startList: [],
      historyList: [],

      poolLength: "", // 路演项目数量（池子数量）
      totalCPLE: "", // 路演项目中质押cp数量
      totalDeposit: "", // 存入总量

      showPledgeNormal: false, // 常规质押
      showPledgeNormalUpgrade: false, // 升级质押
      showGetReward: false, // 领取奖励
      showJoinGroupChat: false, // 加入群聊
      cpleNum: "",
      cpleNum2: "",

      poolPid: "",
      balance: "", // 用户余额

      // scrollPosition: 0, // 记录滚动的位置
      // threshold: 300, // 设置滚动阈值
      // isFixed: false, // 是否固定在顶部
      // isCollapsed: false, // 是否收缩
      // fixedTop: 0, // 卡片固定在顶部的top值
      // scrollTop: 0,
    };
  },

  mounted() {
    // window.addEventListener("scroll", this.handleScroll);
    poolLength().then((res) => {
      this.poolLength = res;
    });

    totalDeposit().then((res) => {
      const amountCPLE = new Decimal(res);
      const numCPLE = new Decimal(1e18);
      const amountResultCPLE = amountCPLE.div(numCPLE);
      this.totalCPLE = amountResultCPLE.toString();
    });

    showLoading();
    const optUnderway = {
      url: reqProject.getInfoList,
      method: "POST",
      params: JSON.stringify({
        status: 1,
      }),
      resFunc: (res) => {
        if (res.data.code == 0) {
          let arr = [];
          res.data.data.list.map((item) => {
            let obj = item || {};

            item.endCountdown = formatDuration(item.endCountdown * 1000);
            item.poolBlock = formatDuration(
              (item.poolEndBlock - item.poolStartBlock) * 2 * 1000
            );

            userInfoAmount(item.poolPid).then((res) => {
              const a = new Decimal(res.amount);
              const numa = new Decimal(1e18);
              const amountResulta = a.div(numa);
              obj.userInfoAmount = amountResulta.toString();
            });

            userPendingReward(item.poolPid).then((res) => {
              const useramount = new Decimal(res);
              const userNum = new Decimal(1e18);
              const useramountResult = useramount.div(userNum);

              obj.userInfoReward = useramountResult.toString();
            });

            upgradeDeposit(item.poolPid).then((res) => {
              const amount = new Decimal(res[0]);
              const num = new Decimal(1e18);
              const amountResult = amount.div(num);

              obj.upgradeDepositAmount = amountResult.toString();
              obj.upgradeDepositBlock = formatDuration(
                (res[2] - res[1]) * 2 * 1000
              );
            });

            poolInfo(item.poolPid).then((res) => {
              console.log(res, "池子信息");

              const y = new Decimal(1e18);
              // 常规奖池总奖励
              const totalReward = new Decimal(res.totalReward);
              const totalRewardResult = totalReward.div(y);
              obj.totalReward = totalRewardResult.toString();

              // 存入总量
              const totalDeposit = new Decimal(res.totalDeposit);
              const totalDepositResult = totalDeposit.div(y);
              obj.totalDeposit = totalDepositResult.toString();

              obj.poolCountDown = formatDuration(
                (res.endBlock - res.startBlock) * 2 * 1000
              );
            });

            setTimeout(() => {
              arr.push(obj);
            }, 500);
          });

          this.underwayList = arr;

          hideLoading();
        }

        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        }
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
        hideLoading();
      },
    };
    request2(optUnderway);

    const optStart = {
      url: reqProject.getInfoList,
      method: "POST",
      params: JSON.stringify({
        status: 0,
      }),
      resFunc: (res) => {
        if (res.data.code == 0) {
          let arr = [];
          res.data.data.list.map((item) => {
            let obj = item || {};
            item.startCountdown = formatDuration(item.startCountdown * 1000);
            item.poolBlock = formatDuration(
              (item.poolEndBlock - item.poolStartBlock) * 2 * 1000
            );

            poolInfo(item.poolPid).then((res) => {
              console.log(res, "池子信息");

              const y = new Decimal(1e18);
              // 常规奖池总奖励
              const totalReward = new Decimal(res.totalReward);
              const totalRewardResult = totalReward.div(y);
              obj.totalReward = totalRewardResult.toString();
            });

            upgradeDeposit(item.poolPid).then((res) => {
              const amount = new Decimal(res[0]);
              const num = new Decimal(1e18);
              const amountResult = amount.div(num);
              obj.upgradeDepositAmount = amountResult.toString();
            });

            setTimeout(() => {
              arr.push(obj);
            }, 500);
          });

          this.startList = arr;
          hideLoading();
        }

        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        }
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
        hideLoading();
      },
    };
    request2(optStart);

    const optHistory = {
      url: reqProject.getInfoList,
      method: "POST",
      params: JSON.stringify({
        status: 2,
      }),
      resFunc: (res) => {
        if (res.data.code == 0) {
          let arr = [];
          res.data.data.list.map((item) => {
            let obj = item || {};

            item.startCountdown = formatDuration(item.startCountdown);
            item.endCountdown = formatDuration(item.endCountdown);

            item.poolBlock = formatDuration(
              (item.poolEndBlock - item.poolStartBlock) * 2 * 1000
            );

            userInfoAmount(item.poolPid).then((res) => {
              const amount = new Decimal(res.amount);
              const num = new Decimal(1e18);
              const amountResult = amount.div(num);
              obj.userInfoAmount = amountResult.toString();
            });

            userPendingReward(item.poolPid).then((res) => {
              obj.userInfoReward = res;
            });

            upgradeDeposit(item.poolPid).then((res) => {
              const amount = new Decimal(res.amount);
              const num = new Decimal(1e18);
              const amountResult = amount.div(num);
              obj.upgradeDepositAmount = amountResult.toString();

              obj.upgradeDepositBlock = formatDuration(
                (res.endBlock - res.startBlock) * 2 * 1000
              );
            });

            const totalReward = new Decimal(item.poolTotalReward);
            const totalDeposit = new Decimal(item.poolTotalDeposit);
            const totalUpgradeDeposit = new Decimal(item.poolUpgradeDeposit);
            const y = new Decimal(1e18);
            const totalRewardResult = totalReward.div(y);
            const totalDepositResult = totalDeposit.div(y);
            const totalUpgradeDepositResult = totalUpgradeDeposit.div(y);
            item.poolTotalReward = totalRewardResult.toString(); // 总奖励
            item.poolTotalDeposit = totalDepositResult.toString(); // 常规总奖励
            item.poolUpgradeDeposit = totalUpgradeDepositResult.toString(); // 升级行为奖池

            arr.push(obj);
          });

          this.historyList = arr;

          hideLoading();
        }

        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        }
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
        hideLoading();
      },
    };
    request2(optHistory);
  },

  methods: {
    // handleScroll(event) {
    //   const scrollTop = event.target.scrollTop;
    //   if (scrollTop < 0) {
    //     scrollTop = 0;
    //   }
    //   this.scrollTop = scrollTop;

    //   // 检查滚动方向
    //   if (scrollTop > this.scrollPosition) {
    //     // 上滑
    //     if (scrollTop >= this.threshold && !this.isFixed) {
    //       this.isFixed = true;
    //       this.isCollapsed = true;
    //       this.fixedTop = 0; // 固定在顶部
    //     }
    //   } else if (scrollTop < this.scrollPosition) {
    //     // 下滑
    //     if (this.isFixed && scrollTop === 0) {
    //       this.isFixed = false;
    //       this.isCollapsed = false;
    //       this.fixedTop = 0; // 重置top值
    //     }
    //   }

    //   this.scrollPosition = scrollTop; // 更新滚动位置
    // },

    handleStatusDetail(status) {
      this.$router.push({
        path: "/roadShowDetail",
        query: {
          status: status,
        },
      });
    },

    // 路演详情页
    handleDetail(status, data) {
      if (status == "ready") {
        this.$router.push({
          path: "/roadShowReadyDetail",
          query: { prjId: data.id },
        });
        localStorage.setItem("readyDetail", JSON.stringify(data));
      } else if (status == "start") {
        this.$router.push({
          path: "/roadShowNoStartDetail",
          query: { prjId: data.id },
        });
        localStorage.setItem("noStartDetail", JSON.stringify(data));
      } else if (status == "history") {
        this.$router.push({
          path: "/roadShowHistoryDetail",
          query: { prjId: data.id },
        });
        localStorage.setItem("historyDetail", JSON.stringify(data));
      }
    },

    // 存入
    handleSubmit(poolPid) {
      let num = this.cpleNum * 1e18;

      approveAdr(num.toString()).then((res) => {
        if (res == true) {
          depositPledge(poolPid, num.toString()).then((res) => {
            console.log("质押cple", res);

            this.$toast({
              type: "success",
              message: "存入成功",
              overlay: true,
            });
          });
        }
      });
    },

    // 升级行为奖池质押
    handleSubmit2() {
      this.cpleNum2 = item.upgradeDepositAmount - item.userInfoAmount;
    },

    // 取消
    handleShowPledge() {
      this.showPledgeNormal = false;
      this.cpleNum = "";
    },

    handleDownLoad() {
      downLoadAppFn();
    },

    // 质押操作
    handlePledge(num) {
      this.showPledgeNormal = true;
      this.totalDeposit = num;

      showAllowance().then((res) => {
        console.log(res, "是否授权");
      });

      showBalance().then((res) => {
        console.log("账户余额:", res);
        const balanceNum = new Decimal(res);
        const y = new Decimal(1e18);
        const balanceNumResult = balanceNum.div(y);
        this.balance = balanceNumResult;
      });
    },

    // 升级行为奖池质押
    handlePledgeUpgrade() {
      this.showPledgeNormalUpgrade = true;

      showBalance().then((res) => {
        console.log("账户余额:", res);
        const balanceNum = new Decimal(res);
        const y = new Decimal(1e18);
        const balanceNumResult = balanceNum.div(y);
        this.balance = balanceNumResult;
      });
    },

    // 领取奖励
    handleGetReward(poolPid) {
      console.log(poolPid, "poolPidpoolPid");
      rewardDeposit(poolPid, 0).then((res) => {
        console.log("领取奖励：", res);
        this.showGetReward = true;
        this.refresh();
      });
    },

    // 刷新
    refresh() {
      this.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../all.scss";
@import "./index.scss";
</style>
