<template>
  <div class="trading">
    <HeaderTabbar title="代购代卖价值币，享CPLE升值潜力"></HeaderTabbar>

    <div class="road_show_nav_img">
      <img src="@/assets/boxWallet/trading.png" class="imgTitle" alt="" />
    </div>

    <div class="trading_money">
      <p>当前推荐购买：RAM:10Usdt</p>
      <p>最近7天涨幅：68%</p>
    </div>

    <div class="trading_tab">
      <van-tabs
        animated
        color="unset"
        class="tabsCapsule"
        background="#29292E"
        v-model="active"
      >
        <van-tab title="代购">
          <div class="trading_tab——buy">
            <p class="trading_tab——buy_usdt">你付钱</p>
            <div class="trading_tab——buy-fill">
              <div>
                <van-field v-model="pay" type="digit" placeholder="0" />
              </div>
              <div class="trading_tab——buy-fill-usdt">
                <img src="@/assets/boxWallet/roadShow/property1.png" alt="" />
                <p>USDT</p>
              </div>
            </div>
            <p class="trading_tab——buy_balance">余额：137.7691</p>
          </div>
          <div class="trading_tab——buy">
            <p class="trading_tab——buy_usdt">你收到（预估）</p>
            <div class="trading_tab——buy-fill">
              <div>
                <van-field v-model="collect" type="digit" placeholder="0" />
              </div>
              <div class="trading_tab——buy-fill-usdt">
                <img src="@/assets/boxWallet/roadShow/property2.png" alt="" />
                <p>RAM</p>
              </div>
            </div>
            <div class="trading_tab——buy-price">
              <div class="trading_tab——buy-price_popup" @click="showPopup">
                {{ radio }} <van-icon name="arrow-down" class="popup_icon" />
              </div>

              <van-checkbox v-model="checked" checked-color="#00BD4B"
                >同意市价</van-checkbox
              >
            </div>
          </div>
          <van-button type="primary" block v-if="pay !== ''"
            >确认代购</van-button
          >
          <van-button type="primary" block v-else disabled>确认代购</van-button>

          <div class="trading_tab——buy_cple">
            <p>预计可额外获得：</p>
            <p style="font-weight: 500">100 <span>CPLE</span></p>
          </div>
          <div class="trading_tab——buy_hr"></div>
          <div class="trading_tab——buy_entrust">
            <div class="entrust_empry" v-if="emptyOrder">
              <div class="entrust_header">
                <p>当前委托</p>
              </div>
              <img src="@/assets/boxWallet/roadShow/empry.png" alt="" />
            </div>

            <div v-else-if="!emptyOrder">
              <div class="entrust_header" @click="handleOrderList('buy')">
                <p>当前委托</p>
                <van-icon name="arrow" />
              </div>
              <div
                class="entrust_order_list"
                v-for="(item, i) in orderList"
                @click="handleOrderDetail('buy')"
                :key="i"
              >
                <div>
                  <p class="entrust_order_list-l-buy">代购RAM{{ item.ram }}</p>
                  <p class="entrust_order_list-l-num">
                    订单编号：#{{ item.orderId }}
                  </p>
                </div>
                <van-icon name="arrow" />
              </div>
            </div>
          </div>
        </van-tab>
        <van-tab title="代售">
          <div class="trading_tab——sell">
            <div class="trading_tab——buy">
              <p class="trading_tab——buy_usdt">代售数量</p>
              <div class="trading_tab——buy-fill">
                <div>
                  <van-field v-model="sellNum" type="digit" placeholder="0" />
                </div>
                <div class="trading_tab——buy-fill-usdt">
                  <img src="@/assets/boxWallet/roadShow/property2.png" alt="" />
                  <p>USDT</p>
                </div>
              </div>
              <p class="trading_tab——buy_balance">余额：137.7691</p>
            </div>
            <div class="trading_tab——buy">
              <p class="trading_tab——buy_usdt">代售价格</p>
              <div class="trading_tab——buy-fill">
                <div>
                  <van-field v-model="sellPrice" type="digit" placeholder="0" />
                </div>
                <div class="trading_tab——buy-fill-usdt">
                  <img src="@/assets/boxWallet/roadShow/property1.png" alt="" />
                  <p>RAM</p>
                </div>
              </div>
              <div class="trading_tab——buy-price">
                <div class="trading_tab——buy-price_popup" @click="showPopup">
                  {{ radio }} <van-icon name="arrow-down" class="popup_icon" />
                </div>

                <van-checkbox v-model="checked" checked-color="#00BD4B"
                  >同意市价</van-checkbox
                >
              </div>
            </div>
            <van-button type="danger" block v-if="sellNum !== ''"
              >确认代售</van-button
            >
            <van-button type="danger" block v-else disabled
              >确认代售</van-button
            >

            <div class="trading_tab——buy_cple">
              <p>预计可额外获得：</p>
              <p style="font-weight: 500">100 <span>CPLE</span></p>
            </div>
            <div class="trading_tab——buy_hr"></div>
            <div class="trading_tab——buy_entrust">
              <div class="entrust_empry" v-if="emptyOrder">
                <div class="entrust_header">
                  <p>当前委托</p>
                </div>
                <img src="@/assets/boxWallet/roadShow/empry.png" alt="" />
              </div>

              <div v-else-if="!emptyOrder">
                <div class="entrust_header" @click="handleOrderList('sell')">
                  <p>当前委托</p>
                  <van-icon name="arrow" />
                </div>
                <div
                  class="entrust_order_list"
                  v-for="(item, i) in orderList"
                  @click="handleOrderDetail('sell')"
                  :key="i"
                >
                  <div>
                    <p class="entrust_order_list-l-buy">
                      代售RAM{{ item.ram }}
                    </p>
                    <p class="entrust_order_list-l-num">
                      订单编号：#{{ item.orderId }}
                    </p>
                  </div>
                  <van-icon name="arrow" />
                </div>
              </div>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>

    <van-popup v-model="show" position="bottom" round>
      <van-radio-group v-model="radio">
        <van-cell-group>
          <van-cell title="最高价" clickable @click="handleRadio(1)">
            <template #right-icon>
              <van-radio name="最高价" />
            </template>
          </van-cell>
          <van-cell title="最低价" clickable @click="handleRadio(2)">
            <template #right-icon>
              <van-radio name="最低价" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
      <div class="popup_cancel" @click="show = false">取消</div>
    </van-popup>

    <BottomTabbar></BottomTabbar>
  </div>
</template>

<script>
import HeaderTabbar from "../Header/index.vue";
import BottomTabbar from "../Bottomtabbar/index.vue";
export default {
  components: { HeaderTabbar, BottomTabbar },

  data() {
    return {
      active: 2,

      pay: "", // 代购
      collect: "", // 代购
      sellNum: "", // 代售数量
      sellPrice: "", // 代售价格

      show: false,
      checked: true,
      radio: "最低价",

      emptyOrder: false,

      orderList: [
        { ram: "25.00", orderId: "0000000000" },
        { ram: "25.00", orderId: "0000000000" },
      ],
    };
  },

  mounted() {
    if (this.$route.query.model == "buy") {
      this.active = 2;
    } else if (this.$route.query.model == "sell") {
      this.active = 1;
    }
  },

  methods: {
    showPopup() {
      this.show = true;
    },

    // 选择价格选项
    handleRadio(type) {
      this.show = false;
      if (type == 1) {
        this.radio = "最高价";
      } else if (type == 2) {
        this.radio = "最低价";
      }
    },

    // 委托订单
    handleOrderList(model) {
      this.$router.push({
        path: "/tradingOrder",
        query: {
          model: model,
        },
      });
    },

    // 订单详情
    handleOrderDetail(model) {
      this.$router.push({
        path: "/tradingOrderDetail",
        query: {
          type: 1,
          model: model,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../all.scss";
@import "./index.scss";
</style>
