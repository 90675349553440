<template>
  <div>
    <div class="bottom">
      <div class="bottom_l">
        <img src="@/assets/boxWallet/logo.png" alt="" />
        <p>共享收益的拼车生活平台</p>
      </div>
      <div class="bottom_r">
        <img src="@/assets/boxWallet/logo2.png" alt="" />
        <img src="@/assets/boxWallet/logo3.png" alt="" />
      </div>
    </div>

    <div class="bottom_record">
      <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank"
        >京ICP备17038918号-1</a
      >
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.bottom {
  height: 100px;
  border-top: 1px #ffffff26 solid;
  border-bottom: 1px #ffffff26 solid;
  margin: 40px 0 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .bottom_l {
    img {
      width: 65px;
      height: 16px;
      margin-bottom: 5px;
    }

    p {
      font-size: 14px;
      font-weight: 600;
    }
  }

  .bottom_r {
    img {
      width: 64px;
      height: 64px;
      margin-left: 10px;
    }
  }
}

.bottom_record {
  margin-bottom: 20px;

  a {
    color: #8c8c92;
  }
}
</style>
