<template>
  <div class="pay_success">
    <Header></Header>

    <div class="order_true">
      <p>支付完成（5月发货）</p>
    </div>

    <div class="mall_info">
      <div class="mall_info_box">
        <div>
          <div class="order_img">
            <img src="@/assets/boxWallet/box.png" alt="" />
          </div>
          <p class="order_img_txt">趣宝盒趣宝盒</p>
        </div>
        <div class="mall_info_count">
          <p>$319.00</p>
          <p class="count_style">×1</p>
        </div>
      </div>

      <div>
        <p class="font_txt1">商品总价</p>
        <p>$319.00</p>
      </div>
      <div>
        <p class="font_txt1">CPLE积分抵扣 (5%)</p>
        <p>-$18</p>
      </div>
      <div style="margin-bottom: 10px">
        <p class="font_txt1">运费</p>
        <p>$0.00</p>
      </div>
      <div class="mall_info_all">
        <p>实付</p>
        <p>$301.00</p>
      </div>
    </div>

    <div class="mall_info">
      <div class="font_txt">
        <p class="font_txt1">订单编号</p>
        <p>221114-150266254862672</p>
        <i
          class="iconfont icon-fuzhi"
          v-clipboard:copy="message"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
        ></i>
      </div>
      <div class="font_txt">
        <p class="font_txt1">下单时间</p>
        <p>2022-11-14 19:53:36</p>
      </div>
      <div class="font_txt">
        <p class="font_txt1">付款时间</p>
        <p>2022-11-14 19:53:36</p>
      </div>
      <div class="font_txt">
        <p class="font_txt1">支付方式</p>
        <p>Solflare</p>
      </div>
      <div class="font_txt">
        <p class="font_txt1">邀请码</p>
        <p>123455</p>
      </div>
    </div>

    <div class="pay_success_b">订单详情</div>

    <Bottom></Bottom>
  </div>
</template>

<script>
import Header from "../header.vue";
import Bottom from "../bottom.vue";
export default {
  components: { Header, Bottom },
  data() {
    return {
      message: "221114-150266254862672",
    };
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";

.font_txt1 {
  color: #ffffffb2;
}

.font_txt {
  justify-content: flex-start !important;

  .font_txt1 {
    width: 72px;
  }

  p {
    font-size: 14px !important;
  }
}

.bottom {
  margin-top: 20px;
}
</style>

<style>
.el-notification {
  z-index: 11111 !important;
}
</style>
