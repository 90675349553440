<template>
  <div style="position: relative">
    <div v-if="vxShare">
      <div class="no_vx_cl_bg">
        <img src="@/assets/share.png" alt="" />
      </div>
    </div>

    <div v-if="!vxShare">
      <div class="register_bg">
        <img src="../assets/qcx-lgo.png" alt="" />
        <p>
          {{ $t("register.qcx") }}<br />{{ $t("register.title1") }}<br />{{
            $t("register.title2")
          }}
        </p>
      </div>
      <div class="register_user_info">
        <img :src="avatarUrl" alt="" />
        <div class="register_user_info_name">
          <p class="register_user_info_name_num">
            {{ $t("register.phonelast") }}{{ phone }}
          </p>
          <p class="register_user_info_name_font">
            {{ $t("register.title3") }}
          </p>
        </div>
      </div>

      <div class="register_msg">
        <Form ref="formValidate" :model="formValidate" :rules="ruleValidate">
          <Form-item prop="name">
            <el-select
              v-model="formValidate.valueArea"
              filterable
              placeholder="请选择"
              style="width: 120px"
            >
              <el-option
                v-for="item in options"
                :key="item.name"
                :value="item.dial_code"
              >
                <div style="display: flex; align-items: center">
                  <img
                    :src="item.url"
                    alt=""
                    style="width: 34px; height: 22px; margin-right: 20px"
                  />
                  {{ item.dial_code }} {{ item.name }}
                </div>
              </el-option>
            </el-select>

            <Input
              v-model="formValidate.name"
              :placeholder="$t('register.inputPhone')"
            ></Input>
          </Form-item>
          <FormItem v-if="flag" prop="pass">
            <Input
              type="password"
              :placeholder="$t('register.inputPass')"
              v-model="formValidate.pass"
              style="width: 90%"
            />
            <i class="iconfont icon-biyanjing" @click="handlePassword"></i>
          </FormItem>

          <FormItem v-else prop="pass">
            <Input
              type="text"
              :placeholder="$t('register.inputPass')"
              v-model="formValidate.pass"
              style="width: 90%"
            />
            <i class="iconfont icon-yanjing" @click="handlePassword"></i>
          </FormItem>

          <FormItem prop="code">
            <Input
              type="text"
              :placeholder="$t('register.inputSMSCode')"
              :maxlength="6"
              v-model="formValidate.code"
              style="width: 73%"
            />
            <Button style="border: none; box-shadow: none">
              <p v-show="timerTf" @click="handleSubmit('formValidate')">
                {{ $t("register.inputCode") }}
              </p>
              <p v-show="!timerTf" style="color: #ccc">{{ countDownNum }} s</p>
            </Button>
          </FormItem>
        </Form>
      </div>

      <div class="code">{{ $t("codeOpenApp.code") }}{{ shareInviteCode }}</div>

      <div class="cple_btn">
        <Button
          type="primary"
          shape="circle"
          @click="handleRegister('formValidate')"
          >{{ $t("register.regCPLE") }}</Button
        >
      </div>

      <Modal
        :title="$t('indexShare.phoneReg')"
        v-model="cplebtnModel"
        :cancelText="$t('pledgePopUp.cancel')"
        :okText="$t('register.download')"
        @on-ok="openApp"
      >
        <p>{{ $t("register.txt1") }}</p>
      </Modal>

      <Modal
        :title="$t('indexShare.regSuccess')"
        v-model="cplebtnModelSuss"
        :cancelText="$t('pledgePopUp.cancel')"
        :okText="$t('register.download')"
        @on-ok="openApp"
      >
        <p>{{ $t("register.txt2") }}</p>
      </Modal>
    </div>
  </div>
</template>

<script src="https://ssl.captcha.qq.com/TCaptcha.js"></script>
<script>
import { reqUrl } from "../api/apiUrl";
import { request } from "../api/request";
import { areaCodeData } from "@/util/areaCodeData";

import md5 from "js-md5";

export default {
  data() {
    const phoneCheck = async (rule, value, callback) => {
      let regPhone = new RegExp(/^[1][3,4,5,6,7,8,9][0-9]{9}$/);
      if (value.length == 0) {
        return callback(new Error(this.$t("register.txt3")));
      } else if (!regPhone.test(value)) {
        return callback(new Error(this.$t("register.txt4")));
      }
    };
    // 密码验证
    const pwdCheck = async (rule, value, callback) => {
      let reg = new RegExp(/^(?![^a-zA-Z]+$)(?!\D+$)/);
      if (value.length < 8) {
        return callback(new Error(this.$t("register.txt5")));
      } else if (value.length > 15) {
        return callback(new Error(this.$t("register.txt6")));
      } else if (!reg.test(value)) {
        return callback(new Error(this.$t("register.txt7")));
      } else {
        callback();
      }
    };

    return {
      vxShare: false,
      avatarUrl: "",
      phone: "",
      shareInviteCode: "",
      formValidate: {
        valueArea: "+86",
        name: "",
        pass: "",
        code: "",
      },
      options: [],
      cplebtnModel: false,
      cplebtnModelSuss: false,
      flag: true,
      ruleValidate: {
        name: [{ required: true, validator: phoneCheck, trigger: "blur" }],
        pass: [{ required: true, validator: pwdCheck, trigger: "blur" }],
      },

      timerTf: true,
      countDownNum: 60,

      dateNew: "",
    };
  },

  mounted() {
    this.options = areaCodeData;

    if (this.$route.query.language == "zh-CN") {
      this.$store.state.isLang = "zh-CN";
      localStorage.setItem("language", "zh-CN");
      this.$i18n.locale = localStorage.getItem("language");
    } else if (this.$route.query.language == "en") {
      this.$store.state.isLang = "en";
      localStorage.setItem("language", "en");
      this.$i18n.locale = localStorage.getItem("language");
    } else if (this.$route.query.language == "ja") {
      this.$store.state.isLang = "ja";
      localStorage.setItem("language", "ja");
      this.$i18n.locale = localStorage.getItem("language");
    } else if (this.$route.query.language == "ko") {
      this.$store.state.isLang = "ko";
      localStorage.setItem("language", "ko");
      this.$i18n.locale = localStorage.getItem("language");
    }

    this.avatarUrl = this.$route.query.avatarUrl
      ? this.$route.query.avatarUrl
      : require("../assets/headimg.png");
    this.phone = this.$route.query.phone.substr(-4);
    this.shareInviteCode = this.$route.query.shareInviteCode;

    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.indexOf("micromessenger") > -1) {
      this.vxShare = true; // '微信内部'
    } else {
      this.vxShare = false;
    }

    // if (ua.match(/MicroMessenger/i) == "micromessenger") {
    //   this.vxShare = true; // '微信内部'
    // }
  },

  methods: {
    handleSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.varify();
        } else {
          this.$Message.error(this.$t("register.txt8"));
        }
      });
    },

    handleRegister(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          var num = "";
          for (var i = 0; i < 32; i++) {
            num += Math.floor(Math.random() * 10);
          }

          let md5Code = `${this.formValidate.pass}${this.formValidate.name}${
            this.formValidate.valueArea
          }${this.$route.query.shareInviteCode}${
            this.formValidate.code
          }${this.getTimeFn()}${num}18A626260181FCF6B6B5929B5BCC3B0F7D4CF5F4`;
          console.log(md5Code);

          this.$Spin.show();
          const opt = {
            url: reqUrl.resgisterUrl,
            method: "POST",
            headers: {
              "Content-Type":
                "application/json; charset=utf-8;responseType=arraybufferimage.png",
              ts: this.dateNew,
              nonce: num,
              sign: md5(md5Code),
            },
            params: JSON.stringify({
              phone: this.formValidate.name,
              phoneArea: this.formValidate.valueArea,
              pid: this.$route.query.shareInviteCode,
              password: this.formValidate.pass,
              smsCode: this.formValidate.code,
            }),
            resFunc: (res) => {
              console.log(res.data);
              if (res.data.code == 1403) {
                this.cplebtnModel = true;
                this.$Spin.hide();
              } else if (res.data.code == 0) {
                this.cplebtnModelSuss = true;
                this.$Spin.hide();
              } else if (res.data.code != 1403 || res.data.code != 0) {
                this.$Message.error(res.data.message);
                this.$Spin.hide();
              }
              this.$Spin.hide();
            },
            errFunc: (err) => {
              console.log(err, "err1");
              this.$Spin.hide();
            },
          };
          request(opt);
        } else {
          this.$Message.error(this.$t("register.txt8"));
        }
      });
    },

    getTimeFn() {
      this.dateNew = new Date().getTime();
      return this.dateNew;
    },

    handlePassword() {
      this.flag = !this.flag;
    },

    clearTime() {
      this.timerTf = true;
      clearInterval(this.timer);
    },

    receiveCode() {
      this.$refs["formValidate"].validate((valid) => {
        if (!valid) {
          this.count = 60;
          this.show = false;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= 60) {
              this.count--;
            } else {
              this.show = true;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);
        }
      });
    },

    varify() {
      this.$Captcha.show();
      this.$bus.$on("getTicket", (res) => {
        if (res.ret === 0) {
          var num = "";
          for (var i = 0; i < 32; i++) {
            num += Math.floor(Math.random() * 10);
          }

          var num2 = "";
          for (var i = 0; i < 6; i++) {
            num2 += Math.floor(Math.random() * 10);
          }

          let md5Code = `${this.formValidate.name}${
            this.formValidate.valueArea
          }${num2}1${
            res.ticket
          }${this.getTimeFn()}${num}18A626260181FCF6B6B5929B5BCC3B0F7D4CF5F4`;
          console.log(md5Code);

          const opt = {
            url: reqUrl.sendCode,
            method: "POST",
            headers: {
              "Content-Type":
                "application/json; charset=utf-8;responseType=arraybufferimage.png",
              ts: this.dateNew,
              nonce: num,
              sign: md5(md5Code),
            },
            params: JSON.stringify({
              phone: this.formValidate.name,
              phoneArea: this.formValidate.valueArea,
              rand: num2,
              scene: 1,
              ticket: res.ticket,
            }),
            resFunc: (res) => {
              console.log(res.data);
              if (res.data.code == 0) {
                this.countDownNum = 60;
                this.timerTf = false;
                this.timer = setInterval(() => {
                  if (this.countDownNum > 0 && this.countDownNum <= 60) {
                    this.countDownNum--;
                  } else {
                    this.timerTf = true;
                    clearInterval(this.timer);
                    this.timer = null;
                  }
                }, 1000);
              }
              if (res.data.code != 0) {
                this.$notify.error(res.data.message);
              }
            },
            errFunc: (err) => {
              console.log(err, "err1");
            },
          };
          request(opt);
        } else {
          this.$notify.warning(this.$t("register.txt9"));
        }
        this.$bus.$off("getTicket");
      });
    },

    openApp() {
      var u = navigator.userAgent;
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
      if (isAndroid) {
        window.location = `qcx://com.quchuxing.qutaxi/openwith?formatPointList=${this.$route.query.formatPointList}&type=${this.$route.query.type}&pickUp=${this.$route.query.pickUp}&price=${this.$route.query.price}&travelId=${this.$route.query.travelId}&passengerNumber=${this.$route.query.passengerNumber}&startTime=${this.$route.query.startTime}&userName=${this.$route.query.userName}&phone=${this.$route.query.phone}&avatarUrl=${this.$route.query.avatarUrl}&shareUid=${this.$route.query.shareUid}&shareInviteCode=${this.$route.query.shareInviteCode}`;
        setTimeout(function () {
          window.location.href = "https://cdn.quchuxing.com/apk/quchuxing.apk"; // 超时跳转下载页
        }, 500);
      }
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isiOS) {
        window.location.href = `flks://line?formatPointList=${this.$route.query.formatPointList}&type=${this.$route.query.type}&pickUp=${this.$route.query.pickUp}&price=${this.$route.query.price}&travelId=${this.$route.query.travelId}&passengerNumber=${this.$route.query.passengerNumber}&startTime=${this.$route.query.startTime}&userName=${this.$route.query.userName}&phone=${this.$route.query.phone}&avatarUrl=${this.$route.query.avatarUrl}&shareUid=${this.$route.query.shareUid}&shareInviteCode=${this.$route.query.shareInviteCode}`;
        setTimeout(function () {
          window.location.href =
            "https://apps.apple.com/cn/app/%E8%B6%A3%E5%87%BA%E8%A1%8C-%E8%AE%A9%E9%A1%BA%E9%A3%8E%E8%BD%A6%E6%8B%BC%E8%BD%A6%E5%87%BA%E8%A1%8C%E6%9B%B4%E6%9C%89%E8%B6%A3/id1197745409"; // 超时跳转下载页
        }, 500);
      }
      return 0;
    },
  },

  destroyed() {
    localStorage.removeItem("driverInfoDTOPhone") ||
      localStorage.removeItem("userInfoDTOPhone");

    localStorage.removeItem("driverInfoDTOavatarUrl");
  },
};
</script>

<style lang="scss" scoped>
.no_vx_cl_bg {
  z-index: 11;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;

  img {
    width: 258px;
    height: 209px;
    position: absolute;
    top: 0;
    right: 0;
  }
}

p {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 500;
}
.register_bg {
  width: 100%;
  height: 250px;
  background: url(../assets/registerBG.png) no-repeat;
  background-size: 100% 250px;
  display: flex;
  // align-items: center;
  color: #202226;
  font-size: 22px;
  position: relative;

  img {
    width: 244px;
    height: 244px;
    position: absolute;
    top: 0;
    right: 0;
  }

  p {
    margin-left: 20px;
    margin-top: 32px;
    z-index: 111;
  }
}

.register_user_info {
  width: calc(100% - 40px);
  margin: -60px auto;
  margin-bottom: 10px;
  background-color: #fff;
  border: 1px solid #ebedf2;
  border-radius: 16px;
  display: flex;
  padding: 16px 0;

  img {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    margin: 0 16px;
  }

  .register_user_info_name {
    width: 70%;
    .register_user_info_name_num {
      font-size: 12px;
      color: #202226;
    }

    .register_user_info_name_font {
      font-weight: 400;
      font-size: 14px;
      color: #4e5259;
      text-align: justify;
    }
  }
}

.register_msg {
  width: calc(100% - 40px);
  margin: 0 auto;

  ::v-deep .ivu-input {
    height: 50px;
    border-radius: 10px;
    border: none;
    box-shadow: none;
    font-size: 14px;
  }
  ::v-deep .ivu-form-item-content {
    border: 1px #ebedf2 solid;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .el-input__inner {
      border: none;
    }

    i {
      margin-right: 10px;
    }

    p {
      color: #5778ff;
      font-weight: 500;
      font-size: 15px;
      margin-right: 10px;
    }
  }
}

.code {
  font-weight: 500;
  font-size: 16px;
  color: #aeb4bf;
  text-align: center;
}

.cple_btn {
  width: calc(100% - 32px);
  margin: 18px auto;
  position: relative;
  .ivu-btn {
    width: 100%;
    height: 54px;
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
  }
}

.vertical-center-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

::v-deep .ivu-modal {
  top: calc(100vh - 190px);

  .ivu-modal-content {
    border-radius: 23px;

    .ivu-modal-header {
      border-bottom: none;

      .ivu-modal-header-inner {
        font-weight: 500;
        font-size: 18px;
        color: #202226;
      }
    }

    .ivu-modal-body {
      padding-top: 0;
    }

    .ivu-modal-footer {
      border-top: none;
      display: flex;
      justify-content: space-between;

      p {
        font-weight: 400;
        font-size: 14px;
        color: #4e5259;
      }

      .ivu-btn-primary {
        width: 60%;
        height: 54px;
        border-radius: 36px;
        background: #5778ff;
        border: none;
        font-weight: 500;
        font-size: 16px;
        color: #fff;
      }

      .ivu-btn-text {
        width: 36%;
        height: 54px;
        border-radius: 36px;
        background: #f2f5fa;
        font-weight: 500;
        font-size: 16px;
        border: none;
        color: #202226;
      }
    }
  }
}

.demo-spin-icon-load {
  animation: ani-demo-spin 1s linear infinite;
}
@keyframes ani-demo-spin {
  from {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.demo-spin-col {
  height: 100px;
  position: relative;
  border: 1px solid #eee;
}
</style>
