module.exports = {
  pledgePopUp: {
    txt1: "旅行のコンプライアンスを確保するために、チェーン上の旅程に参加することを CPLE に誓約する方法は?",
    txtWorld1: "行の世界を解錠し、どのように質権CPLEですか?",
    txt2: "デジタル資産ウォレットをダウンロードしてください",
    txt3: "TokenPocket ウォレットを開き、Polygon (Matic) チェーンを選択します - アプリにバインドされたウォレット アドレスを選択します - CPLE を選択します - 転送をクリックします",
    txt4: "CPLE を以下のアドレスに転送し、自動同期を待ちます",
    copy: "コピー",
    copyLink: "ダウンロードリンクをコピーします",
    copySuccess: "コピー成功",
    copyFail: "コピーに失敗しました",
    cancel: "キャンセル",
    notice: "予防",
    notice1:
      "1.転送アドレスは、APPにバインドしたウォレットアドレスと同じでなければなりません。そうでなければ、プレッジは成功しません",
    notice2:
      "2.プレッジを増やすときは、まずプレッジされたものをアンステークし、それをアカウントにアンステークしてからプレッジする必要があります",
    notice3:
      "3. 1 つのプレッジは 600 CPLE を超える必要があります。そうでない場合、プレッジには計算能力がありません",
    notice4:
      "4. T + 1 営業日で到着することを約束します。1 営業日以上到着しない場合は、カスタマー サービスにお問い合わせください",
    call: "ご不明な点がございましたら、カスタマー サービスまでお問い合わせください:",
    group: "電報グループ",
    vxCode: "マイクロシグナルです",
    phone: "電話",
    copyA:
      "ダウンロード リンクがコピーされました。ブラウザに移動して貼り付けてダウンロードしてください",
  },

  joinGroup: {
    join: "Fun Travel公式電報グループに参加",
    btok: "Btokをダウンロード",
    androidUser: "Android ユーザー",
    iosUser: "IOS ユーザー",
    browser: "ブラウザでアドレスにアクセスしてください",
    applestore: "海外IDを使ってApple Storeからダウンロードしてください",
    btok2:
      "Btokをインストールした後、APPの上部にある検索バーでcpleを検索するか、下のアドレスリンクを検索して、グループチャットに参加してください",
    operation: "操作フロー図",
    download: "ダウンロード、インストールして開く",
    register: "登録",
    information: "完全な個人情報",
    findGroup: "グループを見つけて参加",
  },

  codeOpenApp: {
    join: "楽しい旅行に参加",
    daily: "毎日のキャッシュ リベート",
    code: "招待コード",
  },

  indexShare: {
    passenger: "乗客",
    owner: "オーナー",
    people: "人々",
    yuan: "元",
    newUser: "新規ユーザー登録",
    phoneReg: "携帯電話番号が登録されました",
    regSuccess: "登録完了",
    openapp: "Fun Travel アプリを開いて旅程を表示",
    fail: "間違い",
    today: "今日",
  },

  register: {
    join: "Fun Travel公式電報グループに",
    title1: "Web3.0 ブランドの新しい相乗り旅行エコロジー",
    title2:
      "今すぐ参加して、NFT アバターを開き、毎日現金の赤い封筒を受け取りましょう",
    title3:
      "現金牛を送り、NFT アバターを開き、プラットフォームの毎日の収入フィードバックを CPLE で楽しんでください。貢献すればするほど、より多くの収入が得られます",
    phonelast: "お尻記号です",
    inputPhone: "登録特典を受け取るには携帯電話番号を入力してください",
    inputPass:
      "8 ～ 15 桁の数字と文字の組み合わせでパスワードを入力してください",
    inputSMSCode: "SMS認証コードを入力してください",
    inputCode: "確認コードを取得",
    regCPLE: "登録してCPLEを受け取る",
    txt1: "あなたはすでに Fun Travel の登録ユーザーです。アプリを直接ダウンロードまたは開くことができます",
    txt2: "アプリを直接ダウンロードまたは開くことができます",
    txt3: "携帯電話番号を空にすることはできません",
    txt4: "携帯電話番号が正しく入力されていません。形式が正しいか確認してください!",
    txt5: "パスワードは 8 文字未満にすることはできません!",
    txt6: "最長のパスワードは 15 文字を超えることはできません!",
    txt7: "パスワードが正しく入力されていません。形式が正しいか確認してください!",
    txt8: "入力した情報が正しいか確認してください!",
    txt9: "検証に合格してください",
    download: "をダウンロード",
  },

  agent: {
    normalUser: "一般ユーザ",
    area: "区域エージェント",
    city: "市エージェント",
    province: "省エージェント",
    recommend: "推薦関係",
    notRecommend: "まだ関係ありません",
    standing: "現在のステータス",
    condition: "昇進のプロセスと条件です",
    team1: "チームメンバー≥60人 ",
    now: "(現在の",
    people: " )",
    areaAgent: "グレードは市エージェント",
    team2: "チームメンバー≥3地域エージェント",
    cityAgent: "グレードは区域エージェント",
    team3: "チームメンバー≥3市エージェント",
    earnings2: "累積収益を獲得しました:",
    reward: "詳しい奨励を見る",
    number: "番号",
    earnings: "収益",
    amount: "取引後金額",
    rewardTime: "奨励を得る時間",
    reason: "理由",
    grade: "グレード",
    temporary: "臨時-",
    rewardDetail: "詳しい奨励",
    more: "クリックでさらにロード",
    loading: "ロード中",
  },
};
