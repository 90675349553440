<template>
  <div>
    <div style="position: relative" v-if="vxShare">
      <div class="no_vx_cl_bg">
        <img src="../assets/share.png" alt="" />
      </div>
      <div class="register_bg">
        <p>{{ $t("codeOpenApp.join") }}<br />{{ $t("codeOpenApp.daily") }}</p>
      </div>

      <div class="code">{{ $t("codeOpenApp.code") }}{{ this.code }}</div>

      <div class="cple_btn">
        <Button type="primary" shape="circle" @click="openApp">{{
          $t("joinGroup.register")
        }}</Button>
      </div>
    </div>

    <div style="position: relative" v-if="!vxShare">
      <div class="register_bg">
        <p>{{ $t("codeOpenApp.join") }}<br />{{ $t("codeOpenApp.daily") }}</p>
      </div>

      <div class="code">{{ $t("codeOpenApp.code") }}{{ this.code }}</div>

      <div class="cple_btn">
        <Button type="primary" shape="circle" @click="goRegister">{{
          $t("joinGroup.register")
        }}</Button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      code: "",
      vxShare: true,
    };
  },

  mounted() {
    this.code = this.$route.query.shareInviteCode;

    if (this.$route.query.language == "zh-CN") {
      this.$store.state.isLang = "zh-CN";
      localStorage.setItem("language", "zh-CN");
      this.$i18n.locale = localStorage.getItem("language");
    } else if (this.$route.query.language == "en") {
      this.$store.state.isLang = "en";
      localStorage.setItem("language", "en");
      this.$i18n.locale = localStorage.getItem("language");
    } else if (this.$route.query.language == "ja") {
      this.$store.state.isLang = "ja";
      localStorage.setItem("language", "ja");
      this.$i18n.locale = localStorage.getItem("language");
    } else if (this.$route.query.language == "ko") {
      this.$store.state.isLang = "ko";
      localStorage.setItem("language", "ko");
      this.$i18n.locale = localStorage.getItem("language");
    }

    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      this.vxShare = true;
    } else {
      this.vxShare = false;
    }
  },

  methods: {
    openApp() {
      var u = navigator.userAgent;
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
      if (isAndroid) {
        window.location = `qcx://com.quchuxing.qutaxi/openwith?shareInviteCode=${this.$route.query.shareInviteCode}`;
        setTimeout(function () {
          window.location.href = "https://cdn.quchuxing.com/apk/quchuxing.apk"; // 超时跳转下载页
        }, 500);
      }
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isiOS) {
        window.location.href = `flks://invite?shareInviteCode=${this.$route.query.shareInviteCode}`;
        setTimeout(function () {
          window.location.href =
            "https://apps.apple.com/cn/app/%E8%B6%A3%E5%87%BA%E8%A1%8C-%E8%AE%A9%E9%A1%BA%E9%A3%8E%E8%BD%A6%E6%8B%BC%E8%BD%A6%E5%87%BA%E8%A1%8C%E6%9B%B4%E6%9C%89%E8%B6%A3/id1197745409"; // 超时跳转下载页
        }, 500);
      }
      return 0;
    },

    // 去注册
    goRegister() {
      this.$router.push("/register");
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 500;
}
.register_bg {
  width: 100%;
  height: 250px;
  background: url(../assets/registerBG.png) no-repeat;
  background-size: 100% 250px;
  display: flex;
  align-items: center;
  color: #202226;
  font-size: 22px;

  p {
    margin-left: 20px;
  }
}

.code {
  font-weight: 500;
  font-size: 16px;
  color: #aeb4bf;
  text-align: center;
}

.cple_btn {
  width: calc(100% - 32px);
  margin: 50px auto;
  position: relative;

  .ivu-btn {
    width: 100%;
    height: 54px;
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
    background: #5778ff;
  }
}

.no_vx_cl_bg {
  z-index: 11;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;

  img {
    width: 258px;
    height: 209px;
    position: absolute;
    top: 0;
    right: 0;
  }
}
</style>
