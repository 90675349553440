<template>
  <div class="container">
    <!-- <input type="text" v-model="message" />
    <button
      type="button"
      v-clipboard:copy="message"
      v-clipboard:success="onCopy"
      v-clipboard:error="onError"
    >
      Copy!!!
    </button> -->
    <div class="join_group_bg_img">
      <img src="@/assets/banner/joinGroup/head_logo.png" alt="" />
      <p class="page_big_title">{{ $t("joinGroup.join") }}</p>

      <div class="download_btok">
        <p class="download_btok_title">1. {{ $t("joinGroup.btok") }}</p>
      </div>
    </div>

    <div class="join_group_content">
      <div class="download_btok_one">
        <p class="btok_android_user">{{ $t("joinGroup.androidUser") }}</p>
        <div class="btop_android_link">
          <div style="display: flex; align-items: center">
            <img src="@/assets/banner/joinGroup/android.png" alt="" />
            <p style="color: #4e5259">
              {{ $t("joinGroup.browser") }}<br />https://s65535.com
            </p>
          </div>
          <p
            style="color: #5778ff"
            v-clipboard:copy="message"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
            {{ $t("pledgePopUp.copy") }}
          </p>
        </div>
      </div>

      <div class="download_btok_one" style="margin-top: 15px">
        <p class="btok_android_user">{{ $t("joinGroup.iosUser") }}</p>
        <div class="btop_android_link">
          <div style="display: flex; align-items: center">
            <img src="@/assets/banner/joinGroup/ios.png" alt="" />
            <p style="color: #4e5259">{{ $t("joinGroup.applestore") }}</p>
          </div>
        </div>
      </div>

      <div class="join_group_install">
        <p class="download_btok_title" style="margin-top: 13px">
          2.
          {{ $t("joinGroup.btok2") }}
        </p>
        <div class="btop_android_link">
          <div style="display: flex; align-items: center">
            <p style="color: #4e5259">https://t.me/CNCarpoolLifeEconomy</p>
          </div>
          <p
            style="color: #5778ff"
            v-clipboard:copy="message2"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
            {{ $t("pledgePopUp.copy") }}
          </p>
        </div>
      </div>

      <div class="join_group_operation">
        <p class="join_group_operation_title">
          {{ $t("joinGroup.operation") }}
        </p>
        <p class="join_group_operation_con">1.{{ $t("joinGroup.download") }}</p>
        <div class="join_group_operation_bg">
          <div v-for="(item, index) in srcList1" :key="index">
            <el-image
              style="width: 98px; height: 190px"
              :src="item"
              :preview-src-list="[item]"
            ></el-image>
          </div>
        </div>
      </div>

      <div class="join_group_operation">
        <p class="join_group_operation_con">2.{{ $t("joinGroup.register") }}</p>
        <div class="join_group_operation_bg">
          <div v-for="(item, index) in srcList2" :key="index">
            <el-image
              style="width: 98px; height: 190px"
              :src="item"
              :preview-src-list="[item]"
            ></el-image>
          </div>
        </div>
      </div>

      <div class="join_group_operation">
        <p class="join_group_operation_con">
          3.{{ $t("joinGroup.information") }}
        </p>
        <div class="join_group_operation_bg">
          <div v-for="(item, index) in srcList3" :key="index">
            <el-image
              style="width: 98px; height: 190px"
              :src="item"
              :preview-src-list="[item]"
            ></el-image>
          </div>
        </div>
      </div>

      <div class="join_group_operation">
        <p class="join_group_operation_con">
          4.{{ $t("joinGroup.findGroup") }}
        </p>
        <div class="join_group_operation_bg">
          <div v-for="(item, index) in srcList4" :key="index">
            <el-image
              style="width: 98px; height: 190px"
              :src="item"
              :preview-src-list="[item]"
            ></el-image>
          </div>
        </div>
      </div>
    </div>

    <div class="join_group_footer">
      <img src="../../assets/banner/joinGroup/logo.png" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      message: "https://s65535.com",
      message2: "https://t.me/CNCarpoolLifeEconomy",

      srcList1: [
        require("@/assets/banner/joinGroup/img1.png"),
        require("@/assets/banner/joinGroup/img2.png"),
        require("@/assets/banner/joinGroup/img3.png"),
      ],
      srcList2: [
        require("@/assets/banner/joinGroup/img4.png"),
        require("@/assets/banner/joinGroup/img5.png"),
        require("@/assets/banner/joinGroup/img6.png"),
      ],
      srcList3: [
        require("@/assets/banner/joinGroup/img7.png"),
        require("@/assets/banner/joinGroup/img8.png"),
      ],
      srcList4: [
        require("@/assets/banner/joinGroup/img9.png"),
        require("@/assets/banner/joinGroup/img10.png"),
        require("@/assets/banner/joinGroup/img11.png"),
      ],
    };
  },

  mounted() {
    if (this.$route.query.language == "zh-CN") {
      this.$store.state.isLang = "zh-CN";
      localStorage.setItem("language", "zh-CN");
      this.$i18n.locale = localStorage.getItem("language");
    } else if (this.$route.query.language == "en") {
      this.$store.state.isLang = "en";
      localStorage.setItem("language", "en");
      this.$i18n.locale = localStorage.getItem("language");
    } else if (this.$route.query.language == "ja") {
      this.$store.state.isLang = "ja";
      localStorage.setItem("language", "ja");
      this.$i18n.locale = localStorage.getItem("language");
    } else if (this.$route.query.language == "ko") {
      this.$store.state.isLang = "ko";
      localStorage.setItem("language", "ko");
      this.$i18n.locale = localStorage.getItem("language");
    }
  },

  methods: {
    onCopy() {
      this.$Message.success(this.$t("pledgePopup.copySuccess"));
    },
    onError() {
      this.$Message.error(this.$t("pledgePopup.copyFail"));
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 500;
  color: #202226;
}
.container {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;

  // 公共title
  .download_btok_title {
    font-size: 16px;
  }
  .btop_android_link {
    width: 100%;
    height: 64px;
    background: #e9eff6;
    border-radius: 16px;
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px;

    img {
      width: 48px;
      height: 48px;
      border-radius: 13px;
      margin-right: 12px;
    }

    p {
      font-size: 14px;
    }
  }

  //
  .join_group_bg_img {
    width: 100%;
    height: 200px;
    background: url(../../assets/banner/joinGroup/head_bg.png) no-repeat;
    background-size: 100% 200px;
    position: relative;

    img {
      width: 158px;
      height: 111px;
      position: absolute;
      top: 45px;
      right: 0;
    }

    .page_big_title {
      font-size: 22px;
      position: absolute;
      top: 100px;
      left: 20px;
    }

    .download_btok {
      position: absolute;
      top: 160px;
      left: 20px;
    }
  }

  .join_group_content {
    padding: 0 20px;

    .download_btok_one {
      .btok_android_user {
        color: #4e5259;
        font-size: 12px;
        font-weight: 400;
      }
    }

    .join_group_operation {
      margin-top: 20px;
      .join_group_operation_title {
        font-size: 14px;
      }

      .join_group_operation_con {
        color: #4e5259;
        font-size: 12px;
        margin-top: 12px;
      }

      .join_group_operation_bg {
        width: 100%;
        height: 210px;
        background: #fafafc;
        margin-top: 12px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        img {
          width: 98px;
          height: 190px;
        }
      }
    }
  }

  .join_group_footer {
    width: 100%;
    margin: 40px 0 80px 0;
    display: flex;
    justify-content: center;

    img {
      width: 142px;
      height: 62px;
    }
  }
}
</style>
