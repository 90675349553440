<template>
  <div class="trading_order">
    <div class="trading_head">
      <van-icon name="arrow-left" @click="handleBack" />
      <p>委托订单</p>
      <div></div>
    </div>

    <van-tabs
      animated
      color="unset"
      class="tabsCapsule"
      background="#29292E"
      v-model="active"
    >
      <van-tab title="代购">
        <div class="trading_search">
          <img src="../../../assets/boxWallet/roadShow/search.png" alt="" />
          <el-input v-model="orderId" placeholder="输入订单号搜索"></el-input>
        </div>

        <div
          class="entrust_order_list"
          v-for="(item, i) in orderList"
          :key="i"
          @click="handleOrderDetail('buy')"
        >
          <div>
            <p class="entrust_order_list-l-buy">代购RAM{{ item.ram }}</p>
            <p class="entrust_order_list-l-num">
              订单编号：#{{ item.orderId }}
            </p>
          </div>
          <van-icon name="arrow" />
        </div>
      </van-tab>
      <van-tab title="代售">
        <div class="trading_search">
          <img src="../../../assets/boxWallet/roadShow/search.png" alt="" />
          <el-input v-model="orderId" placeholder="输入订单号搜索"></el-input>
        </div>

        <div
          class="entrust_order_list"
          v-for="(item, i) in orderList"
          :key="i"
          @click="handleOrderDetail('sell')"
        >
          <div>
            <p class="entrust_order_list-l-buy">代售RAM{{ item.ram }}</p>
            <p class="entrust_order_list-l-num">
              订单编号：#{{ item.orderId }}
            </p>
          </div>
          <van-icon name="arrow" />
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 2,
      orderId: "",

      orderList: [
        { ram: "25.00", orderId: "0000000000" },
        { ram: "25.00", orderId: "0000000000" },
      ],
    };
  },

  mounted() {
    if (this.$route.query.model == "buy") {
      this.active = 2;
    } else if (this.$route.query.model == "sell") {
      this.active = 1;
    }
  },

  methods: {
    handleBack() {
      let model = this.$route.query.model;
      this.$router.push({
        path: "/trading",
        query: {
          model: model,
        },
      });
    },

    // 订单详情
    handleOrderDetail(model) {
      this.$router.push({
        path: "/tradingOrderDetail",
        query: {
          type: 2,
          model: model,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../all.scss";
@import "./index.scss";
</style>
