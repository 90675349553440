module.exports = {
  pledgePopUp: {
    txt1: "如何质押CPLE参与行程上链保障出行合规？",
    txtWorld1: "如何质押CPLE，解锁行世界?",
    txt2: "请下载数字资产钱包",
    txt3: "打开TokenPocket钱包，选择Polygon（Matic）链——选择绑定在APP内的钱包地址——选择CPLE——点击转账",
    txt4: "将您的CPLE转入下方地址，等待自动同步即可",
    copy: "复制",
    copySuccess: "复制成功",
    copyLink: "复制下载链接",
    copyFail: "复制失败",
    cancel: "取消",
    notice: "注意事项",
    notice1: "1.转账地址与您绑定在APP的钱包地址必须一致，否则无法质押成功",
    notice2: "2.增加质押时，需将已质押的先解押，解押到账再进行质押",
    notice3: "3.单次质押必须大于600个CPLE，否则质押无算力",
    notice4: "质押T+1个工作日到账，超过一个工作日未到账，请联系客服",
    call: "如有疑问请联系客服：",
    group: "电报群",
    vxCode: "微信号",
    phone: "电话",
    copyA: "下载链接已复制, 请前往浏览器粘贴下载",
  },

  joinGroup: {
    join: "加入趣出行官方电报群",
    btok: "下载Btok",
    androidUser: "安卓用户",
    iosUser: "IOS用户",
    browser: "请浏览器中访问地址",
    applestore: "请使用海外ID到苹果商店下载",
    btok2:
      "安装好Btok后，在APP内上方搜索栏中搜索cple或者搜索下方地址链接，加入群聊即可",
    operation: "操作流程示意",
    download: "下载安装并打开",
    register: "注册",
    information: "完善个人信息",
    findGroup: "查找并加入群",
  },

  codeOpenApp: {
    join: "加入趣出行",
    daily: "每日领现金回馈",
    code: "邀请码",
  },

  indexShare: {
    passenger: "乘客",
    owner: "车主",
    people: "人",
    yuan: "元",
    newUser: "新用户注册",
    phoneReg: "手机号已注册",
    regSuccess: "注册成功",
    openapp: "打开趣出行APP，查看行程",
    fail: "错误",
    today: "今天",
  },

  register: {
    qcx: "趣出行",
    title1: "Web3.0全新拼车出行生态",
    title2: "现在加入，开启NFT头像，每日可领现金红包",
    title3:
      "送你一颗摇钱树，开启NFT头像，凭CPLE可享平台每日收益回馈，贡献越多收益越多",
    phonelast: "尾号",
    inputPhone: "请输入手机号，领取注册奖励",
    inputPass: "请输入8-15位数字和字母组合密码",
    inputSMSCode: "请输入短信验证码",
    inputCode: "获取验证码",
    regCPLE: "注册并领取CPLE",
    txt1: "你已是趣出行注册用户，可直接下载或打开App",
    txt2: "可直接下载或打开App",
    txt3: "手机号不能为空",
    txt4: "手机号输入有误，请检查格式是否正确！",
    txt5: "密码不能少于8位！",
    txt6: "密码最长不能超过15位！",
    txt7: "密码输入有误，请检查格式是否正确！",
    txt8: "请检查填写的信息是否正确！",
    txt9: "请通过验证",
    download: "下载",
  },

  agent: {
    normalUser: "普通用户",
    area: "区域代理",
    city: "市级代理",
    province: "省级代理",
    recommend: "推荐关系",
    notRecommend: "暂无关系",
    standing: "当前身份",
    condition: "等级晋升流程及条件",
    team1: "团队成员≥60人 ",
    now: "（当前",
    people: " 人）",
    areaAgent: "等级为区域代理",
    team2: "团队成员≥3个区级代理",
    cityAgent: "等级为市级代理",
    team3: "团队成员≥3个市级代理",
    earnings: "已获得累计收益：",
    reward: "查看奖励明细",
    number: "序号",
    earnings2: "收益",
    amount: "交易后金额",
    rewardTime: "获取奖励时间",
    reason: "事由",
    grade: "级别",
    temporary: "临时-",
    rewardDetail: "奖励明细",
    more: "点击加载更多",
    loading: "加载中",
  },
};
