module.exports = {
  pledgePopUp: {
    txt1: "CPLE 이 여행 규정 준수를 보장하기 위해 여행 체인에 참여하는 것을 어떻게 서약합니까? 클릭합니다 ?",
    txtWorld1: "어떻게 cple을 질권하고 라인 세계를 풀어나가는가?",
    txt2: "디지털 자산 지갑을 다운로드해 주세요.",
    txt3: "TokenPocket 지갑을 열고 Polygon(Matic) 체인을 선택합니다. 앱에 묶인 지갑 주소를 선택합니다. CPLE 를 선택합니다. 이체를 클릭하십시오.",
    txt4: "CPLE 을 아래 주소로 옮겨서 자동 동기화를 기다리시면 됩니다.",
    copy: "복제",
    copyLink: "다운로드 링크 복사",
    copySuccess: "복제 성공",
    copyFail: "복사 실패",
    cancel: "취소",
    notice: "주의 사항",
    notice1:
      "1. 이체 주소는 APP 에 묶은 지갑 주소와 일치해야 합니다. 그렇지 않으면 성공할 수 없습니다.",
    notice2:
      "2. 담보를 늘릴 때, 이미 담보한 것을 먼저 해압하고, 장부에 유예하고, 담보를 해야 한다.",
    notice3:
      "3. 1 회 담보는 600 개 이상의 CPLE 보다 커야 합니다. 그렇지 않으면 담보가 무력합니다.",
    notice4:
      "4. 약속 T+1 일 (영업일 기준) 에 입금하고, 1 일 이상 (영업일 기준) 이 도착하지 않았습니다. 고객서비스에 연락해 주십시오.",
    call: "궁금한 게 있으시면 고객서비스에 연락해 주세요：",
    group: "전보군",
    vxCode: "마이크로 시그널",
    phone: "전화",
    copyA:
      "다운로드 링크가 복사되었습니다. 브라우저로 가서 다운로드를 붙여 주세요.",
  },

  joinGroup: {
    join: "재미있는 여행 공식 전보군 가입",
    btok: "Btok 다운로드",
    androidUser: "안드로이드 사용자",
    iosUser: "IOS 사용자",
    browser: "브라우저에서 주소를 방문해 주십시오",
    applestore: "해외 ID 를 사용하여 애플스토어에 다운로드해 주세요.",
    btok2:
      "Btok 를 설치한 후 APP 내부 위 검색 표시줄에서 cple 을 검색하거나 아래 주소 링크를 검색하여 그룹 채팅에 참여하면 됩니다.",
    operation: "작업 흐름 도식",
    download: "다운로드 설치 및 열기",
    register: "등록",
    information: "개인 정보 개선",
    findGroup: "그룹 찾기 및 가입",
  },

  codeOpenApp: {
    join: "재미있는 여행 추가",
    daily: "매일 현금 피드백 받기",
    code: "초대장",
  },

  indexShare: {
    passenger: "승객",
    owner: "차주",
    people: "사람",
    yuan: "위안",
    newUser: "새 사용자 등록",
    phoneReg: "핸드폰 번호가 등록되어 있습니다.",
    regSuccess: "등록 성공",
    openapp: "재미있는 여행 앱을 열고, 여정을 보세요.",
    fail: "오류",
    today: "오늘",
  },

  register: {
    qcx: "재미있는 여행",
    title1: "웹 3.0 새로운 카풀 여행 생태",
    title2:
      "지금 가입하고, NFT 아바타를 열고, 매일 현금봉투를 받을 수 있습니다.",
    title3:
      "현금 젖소를 보내고, NFT 아바타를 열고, CPLE 가 플랫폼의 일일 수익 피드백을 누리며, 공헌할수록 수익이 많아진다",
    phonelast: "번호",
    inputPhone: "핸드폰 번호를 입력하고 등록상을 받으세요.",
    inputPass: "8-15 자리 숫자와 알파벳 조합 암호를 입력하십시오.",
    inputSMSCode: "문자 확인 코드를 입력해 주세요.",
    inputCode: "인증 코드 얻기",
    regCPLE: "CPLE 등록 및 수령",
    txt1: "당신은 이미 재미있는 여행 등록 사용자이며, 앱을 직접 다운로드하거나 열 수 있습니다.",
    txt2: "앱을 직접 다운로드하거나 열 수 있다",
    txt3: "핸드폰 번호는 비워둘 수 없다",
    txt4: "휴대 전화 번호를 잘못 입력했습니다. 형식이 올바른지 확인하십시오! 클릭합니다 ",
    txt5: "암호는 8 자리 이하여야 합니다!",
    txt6: "암호는 최대 15 자리를 초과할 수 없습니다! 클릭합니다",
    txt7: "암호를 잘못 입력했습니다. 형식이 올바른지 확인하십시오! 클릭합니다 ",
    txt8: "기입 된 정보가 올바른지 확인하십시오! 클릭합니다 ",
    txt9: "검증을 통과하십시오",
    download: "다운로드",
  },

  agent: {
    normalUser: "일반 사용자",
    area: "지역 에이전트",
    city: "시 에이전트",
    province: "성급 대리",
    recommend: "추천 관계",
    notRecommend: "잠시 관계가 없다.",
    standing: "현재 정체성",
    condition: "등급 승진 절차 및 조건",
    team1: "팀 구성원 ≥60명 ",
    now: "(현재",
    people: " 사람)",
    areaAgent: "등급은 대리구역이다",
    team2: "팀원 ≥3개 지역 에이전트",
    cityAgent: "시 의 대리인이 된다",
    team3: "팀원 ≥3시 에이전트",
    earnings: "획득한 누적 수익:",
    reward: "포상 내역을 살펴보다",
    number: "번호",
    earnings2: "수익",
    amount: "거래후 금액",
    rewardTime: "보너스 시간 받기",
    reason: "사유",
    grade: "등급",
    temporary: "임시-",
    rewardDetail: "포상 내역",
    more: "더 많이 불러오려면 누르십시오",
    loading: "불러오는 중",
  },
};
