<template>
  <div class="order_index">
    <Header></Header>
    <div class="overlay" v-if="loading" @click="handleLoading">
      <i class="iconfont icon-shuaxin"></i>
      <p>点击重试</p>
    </div>

    <div class="order_true">
      <p>请完成支付</p>
      <div>剩余支付时间：{{ timeRemaining }}</div>
    </div>

    <div class="mall_info">
      <div class="mall_info_box">
        <div>
          <div class="order_img">
            <img :src="orderData.goodsInfo.primaryImage" alt="" />
          </div>
          <p class="order_img_txt">{{ orderData.goodsInfo.goodsName }}</p>
        </div>
        <div class="mall_info_count">
          <p>${{ orderData.goodsInfo.goodsPrice }}</p>
          <p class="count_style">×{{ orderData.mallOrderInfo.goodsNum }}</p>
        </div>
      </div>

      <!-- <div class="order_discount" v-if="orderData.payOrderInfo.payType == 11"> -->
      <div class="order_discount">
        <p class="order_discount_txt">CPLE积分抵扣 (5%)</p>
        <van-checkbox
          v-model="radio"
          @change="handleRadio"
          label-position="left"
        >
          <p>-$ {{ orderData.payOrderInfo.p05Amount }}</p>
        </van-checkbox>
      </div>

      <div>
        <p class="font_txt1">商品总价</p>
        <p>${{ orderData.goodsInfo.goodsPrice }}</p>
      </div>
      <div>
        <p class="font_txt1">CPLE积分抵扣 (5%)</p>
        <p>-${{ orderData.payOrderInfo.p05Amount }}</p>
      </div>
      <div style="margin-bottom: 10px">
        <p class="font_txt1">运费</p>
        <p>$0.00</p>
      </div>
      <div class="mall_info_all">
        <p>应付</p>
        <p>
          ${{ orderMoney == "" ? orderData.payOrderInfo.amount : orderMoney }}
        </p>
      </div>
    </div>

    <div class="pay_order" style="margin-top: 80px" @click="handlePay">
      <img src="@/assets/boxWallet/mall.png" alt="" />
    </div>

    <Bottom></Bottom>
  </div>
</template>

<script>
import Web3 from "web3";

import Header from "../header.vue";
import Bottom from "../bottom.vue";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";

import { format_time_date_time2 } from "@/util/funBusiness";
import { showLoading, hideLoading } from "@/util/loading";
import md5 from "js-md5";

export default {
  components: { Header, Bottom },
  data() {
    return {
      orderData: {},
      flag: false,
      timeRemaining: "",
      userName: "",

      radio: false,

      data: null,
      timerId: 0, // 用于存储定时器ID
      timer: null,
      timer2: null,
      timerId2: 0, // 用于存储定时器ID

      requestCount: 0,

      message: "",
      dateNew: "",
      dateNew2: "",
      web3: null,
      num: "",

      orderMoney: "",
      loading: false,
    };
  },

  mounted() {
    this.orderData = JSON.parse(localStorage.getItem("addPayOrderData"));

    let time = setInterval(() => {
      if (this.flag == true) {
        clearInterval(time);
      }
      this.timeDown();
    }, 500);
  },

  methods: {
    timeDown() {
      if (this.$route.query.type == 1) {
        // 设置倒计时结束时间
        var endTime = new Date(
          format_time_date_time2(Number(this.$route.query.payTimeOut))
        );
      } else {
        var endTime = new Date(
          format_time_date_time2(this.orderData.payOrderInfo.payTimeout)
        );
      }

      // 获取当前时间
      var currentTime = new Date();

      // 计算剩余时间
      var time = endTime - currentTime;

      // 将剩余时间转换为天、小时、分钟和秒
      var days = Math.floor(time / (1000 * 60 * 60 * 24));
      var hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((time % (1000 * 60)) / 1000);

      if (days < 0) {
        this.timeRemaining = "已超时";
        // this.$router.push({
        //   path: "/meOrder/detail",
        // });
      } else {
        this.timeRemaining =
          days + "天 " + hours + "小时 " + minutes + "分钟 " + seconds + "秒";
      }
    },

    async handlePay() {
      if (this.timeRemaining == "已超时") {
        this.$Message.error("订单支付已超时，请重新选择规格");
      } else {
        // 马蹄链测试USDT合约地址和ABI
        const contractAddress = "0xc2132D05D31c914a87C6611C10748AEb04B58e8F";
        const contractABI = [
          {
            inputs: [
              { internalType: "string", name: "name", type: "string" },
              { internalType: "string", name: "symbol", type: "string" },
              { internalType: "uint8", name: "decimals", type: "uint8" },
              { internalType: "uint256", name: "supply", type: "uint256" },
            ],
            stateMutability: "nonpayable",
            type: "constructor",
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: "address",
                name: "owner",
                type: "address",
              },
              {
                indexed: true,
                internalType: "address",
                name: "spender",
                type: "address",
              },
              {
                indexed: false,
                internalType: "uint256",
                name: "value",
                type: "uint256",
              },
            ],
            name: "Approval",
            type: "event",
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: "address",
                name: "from",
                type: "address",
              },
              {
                indexed: true,
                internalType: "address",
                name: "to",
                type: "address",
              },
              {
                indexed: false,
                internalType: "uint256",
                name: "value",
                type: "uint256",
              },
            ],
            name: "Transfer",
            type: "event",
          },
          {
            inputs: [
              { internalType: "address", name: "owner", type: "address" },
              { internalType: "address", name: "spender", type: "address" },
            ],
            name: "allowance",
            outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [
              { internalType: "address", name: "spender", type: "address" },
              { internalType: "uint256", name: "amount", type: "uint256" },
            ],
            name: "approve",
            outputs: [{ internalType: "bool", name: "", type: "bool" }],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [
              { internalType: "address", name: "account", type: "address" },
            ],
            name: "balanceOf",
            outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [],
            name: "decimals",
            outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [
              { internalType: "address", name: "spender", type: "address" },
              {
                internalType: "uint256",
                name: "subtractedValue",
                type: "uint256",
              },
            ],
            name: "decreaseAllowance",
            outputs: [{ internalType: "bool", name: "", type: "bool" }],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [
              { internalType: "address", name: "spender", type: "address" },
              { internalType: "uint256", name: "addedValue", type: "uint256" },
            ],
            name: "increaseAllowance",
            outputs: [{ internalType: "bool", name: "", type: "bool" }],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [],
            name: "name",
            outputs: [{ internalType: "string", name: "", type: "string" }],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [],
            name: "symbol",
            outputs: [{ internalType: "string", name: "", type: "string" }],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [],
            name: "totalSupply",
            outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [
              { internalType: "address", name: "recipient", type: "address" },
              { internalType: "uint256", name: "amount", type: "uint256" },
            ],
            name: "transfer",
            outputs: [{ internalType: "bool", name: "", type: "bool" }],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [
              { internalType: "address", name: "sender", type: "address" },
              { internalType: "address", name: "recipient", type: "address" },
              { internalType: "uint256", name: "amount", type: "uint256" },
            ],
            name: "transferFrom",
            outputs: [{ internalType: "bool", name: "", type: "bool" }],
            stateMutability: "nonpayable",
            type: "function",
          },
        ];

        web3 = new Web3(Web3.givenProvider, null, {});
        this.userName = localStorage.getItem("userName");

        // Request the user account and save it into input
        web3.eth.requestAccounts().then((accounts) => {
          this.userName = accounts[0];
        });

        // 支付订单
        const contract = new web3.eth.Contract(contractABI, contractAddress);
        // 1调用合约转账接口
        let amount =
          (this.orderData.payOrderInfo.payType == 11
            ? this.orderData.payOrderInfo.p95Amount
            : this.orderData.payOrderInfo.amount) * Math.pow(10, 6); //转账100个
        //小狐狸账户
        let fromAddress = [this.userName];
        // let fromAddress = ["0xEd511Dd9E5D4B13909289e26F684eDB19c4aA29e"];
        //接收地址
        let toAddress = "0x8180A7d6B4d129509d88ea496e2F5c0D8b3d7Ba8";

        contract.methods
          .transfer(toAddress, amount + "")
          .send({ from: fromAddress[0] }, (error, txHash) => {
            if (error) {
              console.log("Error:", error);
              if (error.code == 4001) {
                this.$Message.error("支付失败");
              }
            } else {
              // 2拿到转账交易hash
              console.log("交易Tx Hash:", txHash, " 调用后台接口带上hash值");
              // 3调用后台接口，带上HASH
              const param = {
                orderId: this.orderData.payOrderInfo.orderId,
                txid: txHash,
                withCplePartPay: this.radio,
              };

              if (txHash) {
                this.signMessage(param);
              }
            }
          });
      }
    },

    // 签名
    async signMessage(param) {
      this.web3 = new Web3(window.ethereum);
      const address = (await this.web3.eth.getAccounts())[0];

      var num = "";
      for (var i = 0; i < 32; i++) {
        num += Math.floor(Math.random() * 10);
      }
      this.num = num;

      this.dateNew = new Date().getTime();
      let nonce = `${param.orderId}${param.txid}${param.withCplePartPay}${this.dateNew}${num}qcxPresale`;
      this.message = nonce;

      const signature = await this.web3.eth.personal.sign(
        this.message,
        address
      );
      this.signature = signature;
      if (this.signature != "") {
        showLoading();
        const opt = {
          url: reqUrl.prependPayByWallet,
          method: "POST",
          headers: {
            nonce: num,
            sign: this.signature,
            ts: Math.floor(this.dateNew),
            wallet: localStorage.getItem("userName"),
          },
          params: param,
          resFunc: (res) => {
            if (res.data.code == 0) {
              this.timer = setInterval(() => {
                this.fetchData();
                hideLoading();
              }, 3000);
            }

            if (res.data.code != 0) {
              this.$notify.error({
                title: "错误",
                message: res.data.message,
              });
              hideLoading();
            }
          },
          errFunc: (err) => {
            this.$notify.error({
              title: "错误",
              message: err,
            });
            console.log(err, "err1");
          },
        };
        request(opt);
      }
    },

    handleLoading() {
      showLoading();
      this.timer2 = setInterval(() => {
        this.fetchData();
        hideLoading();
      }, 3000);
    },

    fetchData() {
      var num = "";
      for (var i = 0; i < 32; i++) {
        num += Math.floor(Math.random() * 10);
      }
      let md5Code = `${
        this.orderData.mallOrderInfo.orderId * 1
      }${this.getTimeFn()}${num}cde89da976c98e74f156e5cf40f9dbf3`;

      showLoading();
      const opt = {
        url: reqUrl.mallOrderDetail,
        method: "POST",
        headers: {
          nonce: num,
          ts: this.dateNew,
          sign: md5(md5Code),
          wallet: localStorage.getItem("userName"),
        },
        params: {
          orderId: this.orderData.mallOrderInfo.orderId * 1,
        },
        resFunc: (res) => {
          console.log(res.data.data.payOrderInfo.orderStatus, 999);
          this.timerId = ++this.timerId;
          if (this.timerId >= 10) {
            hideLoading();
            clearInterval(this.timer);
            this.loading = true;
            if (res.data.code == 0) {
              console.log(res.data.data);
              if (res.data.data.payOrderInfo.orderStatus == 3) {
                clearInterval(this.timer);
                this.$router.push({
                  path: "/meOrder/detail",
                  query: {
                    orderId: this.orderData.mallOrderInfo.orderId,
                  },
                });
                hideLoading();
              }
            }
          }

          this.timerId2 = ++this.timerId2;
          if (this.timerId2 >= 10) {
            hideLoading();
            clearInterval(this.timer2);
            this.loading = true;
            if (res.data.code == 0) {
              console.log(res.data.data);
              if (res.data.data.payOrderInfo.orderStatus == 3) {
                clearInterval(this.timer2);
                this.$router.push({
                  path: "/meOrder/detail",
                  query: {
                    orderId: this.orderData.mallOrderInfo.orderId,
                  },
                });
                hideLoading();
              }
            }
          }

          if (res.data.code == 0) {
            console.log(res.data.data);
            if (res.data.data.payOrderInfo.orderStatus == 3) {
              clearInterval(this.timer);
              this.$router.push({
                path: "/meOrder/detail",
                query: {
                  orderId: this.orderData.mallOrderInfo.orderId,
                },
              });
              hideLoading();
            }
          }

          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          }
        },

        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          console.log(err, "err1");
          hideLoading();
        },
      };
      request(opt);
    },

    getTimeFn() {
      this.dateNew = new Date().getTime();
      console.log(typeof this.dateNew, 9887);
      return this.dateNew;
    },

    handleRadio() {
      if (this.radio) {
        this.orderMoney = this.orderData.payOrderInfo.p95Amount;
      } else {
        this.orderMoney = this.orderData.payOrderInfo.amount;
      }
    },
  },

  beforeDestroy() {
    // 在组件销毁前清除定时器
    clearInterval(this.timerId);
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";

.order_true {
  div {
    font-size: 16px;
    font-weight: 400;
    color: #ff3745;
  }
}
</style>

<style>
.el-notification {
  z-index: 11111 !important;
}
</style>
