// const baseURL = "http://192.168.1.22:9203";
const baseURL = "https://webapi.quchuxing.com";
// const baseURL = "https://test-webapi.quchuxing.com";

// const baseAgentURL = "https://test-examine.quchuxing.com/examine";
const baseAgentURL = "https://examine.quchuxing.com/examine";

const baseURLProject = "http://test-qchatapi.quchuxing.com";

const reqUrl = {
  resgisterUrl: `${baseURL}/api/h5/account/register`, // 注册
  cancelUrl: "https://test-webapi.quchuxing.com/api/h5/account/cancel", // 注销
  sendCode: `${baseURL}/api/h5/account/sendSms`, // 短信验证码
  travalUrl: `${baseURL}/api/h5/travel/getPublicTravelDetailForShare`,
  travalMatchUrl: `${baseURL}/api/h5/travelMatch/getTravelDetailForShare`,

  // 预售趣宝盒
  mallAaddAddress: `${baseURL}/api/presale/mall/addAddress`, // 添加地址
  mallSetDefaultAddress: `${baseURL}/api/presale/mall/setDefaultAddress`, // 设置为默认地址
  mallDeleteAddress: `${baseURL}/api/presale/mall/deleteAddress`, // 删除地址
  mallListAddress: `${baseURL}/api/presale/mall/listAddress`, // 地址列表
  mallGetDefaultAddress: `${baseURL}/api/presale/mall/getDefaultAddress`, // 获取默认地址
  mallUpdateAddress: `${baseURL}/api/presale/mall/updateAddress`, // 修改地址

  mallOrderAdd: `${baseURL}/api/presale/mallOrder/add`, // 创建订单
  mallOrderListAll: `${baseURL}/api/presale/mallOrder/listAll`, // 全部订单
  mallOrderDetail: `${baseURL}/api/presale/mallOrder/detail`, // 订单详情
  mallOrderHasReceived: `${baseURL}/api/presale/mallOrder/hasReceived`, // 确认订单-签收
  mallOrderCancelOrder: `${baseURL}/api/presale/mallOrder/cancelOrder`, // 取消订单
  mallOrderGoodsDetail: `${baseURL}/api/presale/goods/detail`, // 商品详情
  mallOrderToPay: `${baseURL}/api/presale/mallOrder/toPay`, // 待支付订单
  prependPayByWallet: `${baseURL}/api/presale/prependPay/byWallet`, // 支付

  getPresaleProgress: `${baseURL}/api/presale/getPresaleProgress`, // 预售进度
  userBasicGet: `${baseURL}/api/presale/user/basic/get`, // 预售用户信息
};

const reqAgentURL = {
  getAgentLevelDetail: `${baseAgentURL}/getAgentLevelDetail`, // 用户代理级别查询
  getBoxAgentBalanceSum: `${baseAgentURL}/getBoxAgentBalanceSum`, // 行用户代理级别查询-详情-累计收益
  getBoxAgentBalanceDetail: `${baseAgentURL}/getBoxAgentBalanceDetail`, // 用户代理级别查询-详情-列表
  huoCaiHeGetBoxContributionDetail: `${baseAgentURL}/huoCaiHe/getBoxContributionDetail`, // 火财盒-列表数据-详情
};

const reqProject = {
  accountImportByMnemonic: `${baseURLProject}/account/importByMnemonic`, // 导入助记词
  getInfoList: `${baseURLProject}/prjInfo/list`, // 项目列表
  getInfoDetail: `${baseURLProject}/prjInfo/detail`, // 项目列表-详情
  getQuestionList: `${baseURLProject}/prjInfo/questionList`, // 项目问题列表
  answerPrjQuestion: `${baseURLProject}/prjInfo/answerPrjQuestion`, // 回答项目问答
  prjInfoQaList: `${baseURLProject}/prjInfo/qaList`, // 查询主持人问答列表
  checkQuestionAnswer: `${baseURLProject}/prjInfo/checkQuestionAnswer`, // 是否回答正确问题
};

export { reqUrl, reqAgentURL, reqProject };
