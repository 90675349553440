const downLoadAppFn = () => {
  var u = navigator.userAgent;
  var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
  var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
  if (isAndroid) {
    window.location.href = "https://cdn.quchuxing.com/apk/quchuxing.apk"; // 超时跳转下载页
  } else if (isiOS) {
    window.location.href =
      "https://apps.apple.com/cn/app/%E8%B6%A3%E5%87%BA%E8%A1%8C-%E8%AE%A9%E9%A1%BA%E9%A3%8E%E8%BD%A6%E6%8B%BC%E8%BD%A6%E5%87%BA%E8%A1%8C%E6%9B%B4%E6%9C%89%E8%B6%A3/id1197745409"; // 超时跳转下载页
  }
};

export { downLoadAppFn };
