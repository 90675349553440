<template>
  <div class="home">
    <van-tabbar v-model="active" route safe-area-inset-bottom>
      <van-tabbar-item
        v-for="(item, index) in tabBar"
        :key="index"
        :to="item.to"
      >
        <template #icon="props">
          <img
            :src="props.active ? item.icon_acitve : item.icon_inacitve"
            border="false"
          />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: "0",
      tabBar: [
        {
          to: "/boxWallet",
          icon_inacitve: require("@/assets/boxWallet/home/box1.png"), // 默认
          icon_acitve: require("@/assets/boxWallet/home/box2.png"), // 选中
        },
        {
          to: "/roadShow",
          icon_inacitve: require("@/assets/boxWallet/home/roadshow1.png"),
          icon_acitve: require("@/assets/boxWallet/home/roadshow2.png"),
        },
        {
          to: "/trading",
          icon_inacitve: require("@/assets/boxWallet/home/trading1.png"),
          icon_acitve: require("@/assets/boxWallet/home/trading2.png"),
        },
        {
          to: "/meRoadShow",
          icon_inacitve: require("@/assets/boxWallet/home/me1.png"),
          icon_acitve: require("@/assets/boxWallet/home/me2.png"),
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.home {
  .van-tabbar--fixed,
  .van-tabbar-item--active {
    background: #202226e5;
    border-radius: 18px;
  }

  .van-tabbar--fixed {
    width: 92%;
    left: calc(100vw - 96%);
    bottom: 20px;
  }

  .van-tabbar-item__icon {
    img {
      width: 28px;
      height: 28px;
    }
  }

  .van-hairline--top-bottom::after,
  .van-hairline-unset--top-bottom::after {
    border: none;
  }
}
</style>
