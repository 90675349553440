<template>
  <div style="position: relative; height: calc(100vh - 100px)">
    <RelationGraph
      style="height: 700px"
      ref="seeksRelationGraph"
      :options="graphOptions"
    />
  </div>
</template>

<script>
import { reqAgentURL } from "@/api/apiUrl";
import { request } from "@/api/request";

export default {
  data() {
    return {
      graphOptions: {
        backgrounImageNoRepeat: true,
        layouts: [
          // {
          //   label: "中心",
          //   layoutName: "tree",
          //   layoutClassName: "seeks-layout-center",
          //   defaultJunctionPoint: "border",
          //   // distance_coefficient: 0.6,
          //   min_per_width: "200",
          //   max_per_width: "300",
          //   min_per_height: "300",
          // },
        ],

        moveToCenterWhenRefresh: true,
        defaultNodeColor: "rgba(244, 244, 244, 1)",
        defaultNodeFontColor: "rgba(0, 0, 0, 1)",
        defaultNodeBorderColor: "#A0A0A0",
        defaultLineColor: "rgba(154, 154, 154, 1)",
        defaultNodeBorderWidth: 0,
        disableZoom: true,
      },
    };
  },

  mounted() {
    const opt = {
      url: reqAgentURL.huoCaiHeGetBoxContributionDetail,
      method: "POST",
      params: JSON.stringify({
        userId: this.$route.query.userId * 1,
      }),
      resFunc: (res) => {
        if (res.data.code == 0) {
          const __graph_json_data = {
            rootId: res.data.data.rootId,
            nodes: res.data.data.nodes,
            lines: res.data.data.lines || [],
          };

          this.$refs.seeksRelationGraph.setJsonData(
            __graph_json_data,
            (graphInstance) => {
              // 这些写上当图谱初始化完成后需要执行的代码
            }
          );
        }

        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
        }
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
      },
    };
    request(opt);
  },
};
</script>

<style lang="scss" scoped>
.fire_back_list {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
</style>
