<template>
  <div>
    <router-view v-if="isRefresh" />
  </div>
</template>

<script>
export default {
  provide() {
    return {
      reload: this.reload,
    };
  },

  data() {
    return {
      isRefresh: true,
    };
  },

  mounted() {
    var script = document.createElement("meta");
    script.name = "viewport";
    script.content = "width=device-width, user-scalable=no";
    document.getElementsByTagName("head")[0].appendChild(script);

    // 禁止双指放大
    document.documentElement.addEventListener(
      "touchstart",
      function (event) {
        if (event.touches.length > 1) {
          event.preventDefault();
        }
      },
      {
        passive: false,
      }
    );

    // 禁用双击放大
    var lastTouchEnd = 0;
    document.documentElement.addEventListener(
      "touchend",
      function (event) {
        var now = Date.now();
        if (now - lastTouchEnd <= 300) {
          event.preventDefault();
        }
        lastTouchEnd = now;
      },
      {
        passive: false,
      }
    );
  },

  methods: {
    reload() {
      this.isRefresh = false;
      this.$nextTick(() => {
        this.isRefresh = true;
      });
    },
  },
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
}

.pledge_toast {
  background: rgba(0, 0, 0, 0.65) !important;
  border-radius: 19px !important;
}

.pledge_toast1 {
  width: 300px !important;
  background: rgba(0, 0, 0, 0.65) !important;
  border-radius: 19px !important;
}
</style>
